.company-management-control-panel-row {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 30px;
}

.project-management-user-box {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: rgba(248, 249, 250, 1);

  padding: 10px;

  margin-top: 20px;
  margin-right: 20px;
  height: 240px;
  width: 674px;
  overflow-y: scroll;

  display: flex;
  flex-direction: column;
}

.project-management-control-box {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: rgba(248, 249, 250, 1);

  padding: 10px;

  margin-top: 20px;
  margin-right: 20px;
  height: 240px;
  width: 240px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-management-user-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
  margin-bottom: 5px;
}

.project-management-user-delete-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration1);
  color: var(--integrity);
  width: 25px;
  height: 25px;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;

  margin-right: 10px;
  font-size: 13px;
}

.project-management-user-delete-button:hover {
  background-color: var(--strength1);
  cursor: pointer;
}

.project-management-wide-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration1);
  color: var(--integrity);
  width: 100px;
  height: 30px;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;

  margin-left: 10px;

  font-size: 14px;
}

.project-management-wide-button:hover {
  background-color: var(--strength1);
  cursor: pointer;
}

.project-management-title-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 15px;
}

.project-management-user-row-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.project-management-user-label {
  margin-left: 10px;
  font-size: 13px;
  margin-bottom: 3px;
}

.project-management-control-panel-content {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.project-management-control-panel-row {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-management-control-panel-button-row {
  display: flex;
  flex-direction: column;
}

.project-management-control-panel-delete-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration1);
  color: var(--integrity);
  width: 130px;
  height: 30px;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
}

.project-management-control-panel-delete-button:hover {
  background-color: var(--strength1);
  cursor: pointer;
}

.project-management-project-filter {
  width: 200px;
  height: 25px;
}
