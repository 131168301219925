.alc-container {
  width: 300px;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.alc-row {
  display: flex;
  flex-direction: row;
  width: 100%;

  margin-bottom: 10px;
}

.alc-column {
  display: flex;
  flex-direction: column;
}

.alc-label {
  width: 130px;
}

.alc-input {
  width: 170px;
  height: 25px;
}

.alc-export-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration1);
  color: white;
  height: 45px;
  width: 45px;
  padding: 8px;
  border: none;
  flex-shrink: 0;

  border-radius: 100px;
}

.alc-export-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}
