.tpi-upload {
  padding: 15px;
  width: 500px;
  /* Keeps the close button at the bottom */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tpi-upload-textarea {
  height: 200px;
  width: 100%;
  resize: none;
}

.tpi-upload-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  height: 35px;
  width: 100px;
  background-color: var(--collaboration1);
  color: white;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 100px;
}

.tpi-upload-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.tpi-upload-control-button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tpi-upload-button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.tpi-upload-error {
  color: var(--strength1);
}

.tpi-upload-success {
  color: var(--natural1);
}

.tpi-upload-text-counter-container {
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
}

.tpi-upload-error-container {
  padding-top: 5px;
}

.tpi-upload-counter-error {
  color: var(--strength1);
  margin-right: 7px;
}

.tpi-upload-counter-success {
  color: var(--natural1);
  margin-right: 7px;
}

.tpi-upload-select {
  display: flex;
  width: 100%;
  border: 1px solid lightgrey;

  height: 40px;
}
