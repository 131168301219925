.migration-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
}

.migrate-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: white;
  background-color: var(--natural1);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-size: 15px;

  width: 200px;
  height: 30px;
  margin-bottom: 15px;

  user-select: none;
}

.migrate-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}
