.landing-background {
  height: 100vh;
  overflow-y: auto;
}

.landing-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 570px;

  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: relative;
  top: 55%;
  left: 50%;

  z-index: 1;
}

.landing-brand {
  margin: 0 auto;
  margin-bottom: 50px;
}

.landing-box {
  background: rgba(85, 87, 90, 0.8);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 3);
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  display: flex;
  flex-direction: column;
  color: white;

  width: 400px;

  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;

  border-radius: 20px;
  opacity: 1;

  margin: 0 auto;
  margin-bottom: 30px;
}

.landing-label {
  margin: 0 auto;
  margin-bottom: 30px;
}

.landing-button-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 110px;
}

.landing-button {
  background-color: rgb(115, 115, 115);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border: none;
  color: white;
  text-align: center;
  font-size: 16px;
  width: 280px;
  height: 50px;
  line-height: 50px; /* same as height! */
  margin: 0 auto;
}

.landing-button:hover {
  color: white;
  background-color: var(--heritage);
  text-decoration: none;
  cursor: pointer;
}

.landing-button:focus {
  box-shadow: none;
}

.landing-message {
  color: var(--heritage);
  text-align: center;
}
.landing-text {
  margin-top: 10px;
  margin-bottom: 10px;

  color: white;
  text-align: center;
}
