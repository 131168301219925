.cost-plan-revision-selector {
  margin-top: 5px;
  height: 25px;
  z-index: 200;
}

.cost-plan-revision-selector-bar {
  display: flex;
  flex-flow: wrap;

  align-items: center;
}

.cost-plan-revision-selector-box {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: rgb(235, 235, 235);
  height: 25px;
  width: 25px;
  margin-left: 15px;
  color: white;
  border-radius: 100px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;

  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.cost-plan-revision-selector-box:focus {
  outline: inherit;
}

.cost-plan-revision-selector-box {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration1);
  cursor: pointer;
  user-select: none;
}

.cost-plan-revision-selector-box:hover,
.cost-plan-revision-selector-box.current-revision {
  box-shadow: 0 4px 8px 0 var(--strength2), 0 6px 20px 0 var(--strength2);
  background-color: var(--heritage);
}

.cost-plan-revision-selector-box.inactive {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration3);
  color: var(--confident);
}

.cost-plan-revision-selector-box.DRAFT {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration3);
  color: var(--confident);
  border: 1px dashed var(--confident);
}

.cost-plan-revision-selector-box.DRAFT:hover,
.cost-plan-revision-selector-box.current-revision.DRAFT {
  box-shadow: 0 4px 8px 0 var(--built2), 0 6px 20px 0 var(--built2);
  background-color: var(--built1);
  border: 1px dashed var(--confident);
  color: var(--confident);
}

.cost-plan-revision-selector-label {
  font-size: 20px;
}

.cost-plan-revision-selector-box > .pcra-status-summary {
  position: absolute;
  margin-top: 110px;
}
