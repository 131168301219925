.area-schedule-reconciliation {
    margin-top: 20px;
}

.area-schedule-recon-section-title {
    margin-top: 0px;
}

.area-schedule-detailed-comparison {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    margin: 0 -10px;
}

.area-schedule-detailed-comparison>div {
    flex: 1;
    margin: 0 15px;
}