.tpi-admin-dashboard-container {
  width: 100%;
  margin-bottom: 20px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.tpi-admin-dashboard-select {
  width: 200px;
  height: 30px;
  margin-right: 30px;
}

.tpi-admin-dashboard-checkbox {
  margin-right: 20px;
  height: 20px;
  width: 20px;
}

.tpi-admin-dashboard-checkbox-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
}

.tpi-admin-dashboard-checkbox-list {
  margin-bottom: 30px;
}

/* Multi Slider */

.tpi-admin-dashboard-slider {
  -webkit-appearance: none;
  width: 150px;
  height: 13px;
  background: var(--collaboration1);
  outline: none;
  opacity: 1;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border-radius: 100px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.tpi-admin-dashboard-slider:hover {
  opacity: 1;
}

.tpi-admin-dashboard-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: var(--heritage);
  cursor: pointer;
  border-radius: 100px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.tpi-admin-dashboard-slider::-moz-range-thumb {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  width: 25px;
  height: 25px;
  background: var(--heritage);
  cursor: pointer;
  border-radius: 100px;
}

.tpi-admin-dashboard-controls {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.tpi-admin-dashboard-checkboxes {
  width: 310px;

  height: 400px;

  overflow-y: scroll;
  margin-bottom: 20px;
  padding: 15px;

  background-color: rgba(248, 249, 250, 1);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  margin-right: 20px;
}

.tpi-admin-dashboard-label {
  margin-bottom: 0px;
}

.tpi-admin-dashboard-slider-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.tpi-admin-dashboard-slider-label {
  margin-left: 15px;
  font-size: 18px;
  color: var(--heritage);
}

.tpi-admin-dashboard-slider-container {
  padding-top: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tpi-admin-dashboard-checkboxes .checkbox-label {
  width: 240px;
}
