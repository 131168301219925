.project-team-profile-card {
  background-color: var(--integrity);
  display: flex;
  flex-direction: row;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 100px;
  margin-bottom: 30px;
  width: 1070px;
  height: 70px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.project-team-name-title {
  width: 300px;
  color: var(--confident);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-team-name {
  margin-top: 7px;
  font-size: 20px;
}

.project-team-number-location {
  width: 140px;
  float: right;
  color: var(--confident);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.project-team-location {
  font-size: 20px;
}

.project-team-phone {
  font-size: 13px;
  color: var(--collaboration2);
}

.project-team-company {
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 20px;
  color: var(--collaboration2);
  text-align: center;
}

.project-team-email {
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: 15px;
  color: var(--heritage);
}

.project-team-image {
  width: 70px;
  border-radius: 150px;
}
