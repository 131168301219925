.localisation-toggle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;

  width: 100%;
}
.toggle-active-button {
  color: white;
  background-color: var(--heritage);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-size: 15px;

  width: 60px;
  height: 30px;

  user-select: none;
}

.toggle-active-button:hover {
  background-color: var(--collaboration1);
  cursor: pointer;
  color: white;
}

.toggle-inactive-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: black;
  background-color: white;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-size: 15px;

  width: 60px;
  height: 30px;

  user-select: none;
}

.toggle-inactive-button:hover {
  background-color: var(--collaboration1);
  cursor: pointer;
  color: white;
}
