.pdu-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  width: 500px;
  padding: 15px;
}

.pdu-controls-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 50px;

  height: 35px;
  width: 150px;
  background-color: var(--collaboration1);
  color: white;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pdu-controls-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.pdu-control-flexbox {
  display: flex;
  justify-content: space-evenly;
  width: 600px;
  margin-top: 30px;
}

.pdu-error-container {
  min-height: 35px;
  min-width: 200px;
  display: flex;
  justify-content: center;
}
