.budget-description-head-row {
  width: 1000px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  display: flex;
  flex-direction: row;

  background-color: var(--collaboration2);
  color: var(--integrity);

  min-height: 40px;
}

.budget-description-cell {
  width: 450px;
  padding: 10px;
  min-height: 34px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid grey;
  border-right: 1px solid grey;
}

.budget-row {
  display: flex;
  flex-direction: row;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  min-height: 40px;

  background-color: var(--integrity);
  color: var(--confident);

  width: 1000px;
}

.cost-cell {
  width: 150px;
  padding: 5px;
  display: flex;
  justify-content: right;
  align-items: center;
  border-bottom: 1px solid grey;
  border-right: 1px solid grey;
}

.budget-cell {
  width: 150px;
  padding: 5px;
  display: flex;
  justify-content: right;
  align-items: center;
  border-right: 1px solid grey;
  border-bottom: 1px solid grey;
}

.cost-cell-head {
  width: 150px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid grey;
  border-right: 1px solid grey;
}

.budget-cell-head {
  width: 150px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid grey;
  border-bottom: 1px solid grey;
}

.budget-approval-cell {
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid grey;
  border-bottom: 1px solid grey;
}

.estimates-budget-container-total {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 1000px;
  height: 40px;
  background-color: var(--collaboration1);
  color: var(--integrity);

  margin-top: 30px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.total-row-width-kpx {
  width: 665px;
}

.budget-total-value {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: right;
  width: 150px;
  border-right: 1px solid grey;
  padding: 5px;
  height: 40px;
  font-weight: bold;
}

.budget-total {
  padding-left: 25px;
}

.budget-total-approval-cell {
  width: 150px;
  display: flex;
  justify-content: right;
  align-items: center;
  border-right: 1px solid grey;
  height: 40px;
  font-weight: bold;
  padding-right: 5px;
}