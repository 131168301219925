
.pc-commentary-row {
    font-size: 11px;
  border-bottom: 1px lightgrey solid;
  max-height: 55px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.pc-commentary-display-area {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
  }
  
  .pc-commentary-table {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  
  .pc-commentary {
    width: 100%;
  
    padding-bottom: 10px;
    padding-top: 10px;
    padding-right: 10px;
  }
  
  .pc-commentary-bullet {
    box-shadow: 0 2px 4px 0 var(--fluidity1), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  
    height: 5px;
    width: 5px;
    margin-right: 15px;
    margin-left: 15px;
  
    background-color: var(--fluidity1);
  
    border-radius: 100px;
  }