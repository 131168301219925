.tpi-admin-chart-container {
  width: 1210px;
  height: 500px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: rgba(248, 249, 250, 1);

  display: flex;
  flex-direction: row;
  align-items: center;
}

.tpi-admin-chart {
  min-width: 1000px;
  height: 500px;
  padding: 20px;
}

.tpi-admin-chart-legend {
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tpi-admin-chart-legend-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
  border-bottom: 1px lightgrey solid;
}

.tpi-admin-chart-legend-item-bar {
  width: 50px;
  height: 7px;
  background-color: orange;
  border-radius: 50px;
}

.tpi-admin-chart-legend-item-text {
  width: 130px;
  font-size: 11px;
}
