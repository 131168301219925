/* The dashboard-drawer container */
.dashboard-drawer {
  position: relative;
  display: inline-block;
}

/* dashboard-drawer content (hidden by default) */
.dashboard-drawer-content {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 12px 40px 0 rgba(0, 0, 0, 0.19);
  position: fixed;
  top: 0;
  left: 0;

  background-color: var(--collaboration1);
  /* background-color: rgba(248, 249, 250, 1); */

  display: flex;
  flex-direction: column;

  -webkit-transform: scale(0.6);
  -moz-transform: scale(0.6);
  -ms-transform: scale(0.6);
  transform: scale(0.6);

  animation: fadein 0.5s;

  border-radius: 60px;
  z-index: 2000;
}

/* Links inside the dashboard-drawer */
.dashboard-drawer-content a {
  float: none;
  color: var(--collaboration1);
  text-decoration: none;
  display: block;
  text-align: left;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
