.dashboard-editor-title {
  margin-top: 20px;
  margin-bottom: 10px;
  color: var(--integrity);
  user-select: none;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 85%;

  height: 60px;
}

.dashboard-editor-title .display-4 {
  font-size: 40px;
  margin-bottom: 0px;
}

.dashboard-editor-close {
  width: 40px;
  height: 40px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.dashboard-editor-close .fas {
  transition: 0.2s ease-in-out;
  font-size: 35px;
  color: var(--strength1);
}

.dashboard-editor-close .fas:hover {
  font-size: 50px;
  cursor: pointer;
}

.dashboard-editor-content {
  background-color: var(--collaboration3);

  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  height: 800px;
  width: 550px;
  padding-top: 20px;
}

.dashboard-editor-panel {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 500px;
  background-color: var(--integrity);

  height: max-content;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: relative;

  margin-bottom: 30px;
}

.dashboard-editor-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  z-index: 2000;
}

.dashboard-editor-slot {
  width: 100%;
  height: 100px;

  /* background-color: rgba(248, 249, 250, 1); */
  background-color: var(--collaboration1);
  color: var(--collaboration2);

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 40px;

  user-select: none;

  border-radius: 0px 0px 50px 50px;

  transition: 0.2s ease-in-out;
}

.dashboard-editor-slot-highlight {
  width: 100%;
  height: 100px;

  background-color: var(--strength1);
  color: var(--integrity);

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 45px;

  user-select: none;

  border-radius: 0px 0px 50px 50px;

  transition: 0.2s ease-in-out;
}

.dashboard-editor-slot-icon {
  width: 65px;
}

.dashboard-editor-control {
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  width: 550px;
  height: 35px;
  margin-bottom: 20px;
}

.dashboard-editor-save-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--natural1);
  color: var(--integrity);

  padding: 10px;

  border-radius: 50px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 22px;
  width: 200px;
  height: 35px;

  user-select: none;
}

.dashboard-editor-save-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.dashboard-editor-save-button:active {
  background-color: var(--heritage);
  cursor: pointer;

  transform: scale(0.98);
}

.dashboard-editor-component {
  pointer-events: none;
  user-select: none;

  display: flex;
  flex-direction: column;
}

.dashboard-editor-error-container {
  width: 150px;
  height: 30px;

  font-size: 20px;
}

.dashboard-editor-error {
  color: var(--strength1);
}

.dashboard-editor-success {
  color: var(--natural1);
}
