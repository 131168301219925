.reconciliation-comment {
  width: 600px;
  padding: 15px;
}

.reconciliation-comment-container {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--integrity);
  width: 100%;
  height: 140px;
  margin-bottom: 15px;
  padding: 10px;
}

.reconciliation-internal-comment-container {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration4);
  width: 100%;
  height: 140px;
  margin-bottom: 15px;
  padding: 10px;
}

.reconciliation-comment-top-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.reconciliation-comment-input {
  width: 100%;
  height: 74px;
  margin-top: 10px;
  resize: none;
}

.reconciliation-comment-edit-button {
  cursor: pointer;
  user-select: none;
}

.note-open:hover {
  cursor: pointer;
}

.note-icon {
  color: var(--collaboration1);
  font-size: 20px;
}

.note-icon-highlight {
  color: var(--heritage);

  font-size: 20px;
}

.note-open:hover .note-icon {
  color: var(--heritage);
}

.note-icon-highlight:hover {
  color: var(--collaboration1);
}

.reconciliation-comment-icon {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--collaboration1);
  width: 35px;
  height: 35px;
  color: var(--integrity);
  border-radius: 100px;
}

.reconciliation-comment-icon:hover {
  background-color: var(--heritage);
}
