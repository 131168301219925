/* Page Layout */

.progress-claim {
  width: 1620px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.progress-claim-panel {
  width: 500px;

  font-size: 11px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
