.project-control-background {
  width: 1630px;
  margin: 0 auto;
}

.input-details {
  margin: 10px;
  width: 400px;
  height: 30px;
}

.textarea-details {
  margin: 10px;
  width: 400px;
  height: 190px;
  resize: none;
}

.flex-container-details {
  height: 450px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.submit-details {
  width: 150px;
  margin: 10px;
}

.edit-clients-button-container {
  display: flex;
  flex-flow: column;
}

.project-control-button-delete {
  display: inline-block;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration1);
  color: white;
  padding: 5px;
  width: 150px;
  height: 35px;
  border: none;
  text-align: center;
  border-radius: 0px;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 15px;
}

.project-control-button-delete:hover {
  background-color: var(--strength1);
  cursor: pointer;
}

.edit-project-filter {
  margin-bottom: 10px;
  padding: 5px;
  width: 500px;
}

.spinner-false {
  display: none;
}

.spinner-true {
  margin-left: 30px;
  margin-top: 12px;
  width: 50px;
}

.submitted-false {
  display: none;
}

.submitted-true {
  margin-left: 30px;
  margin-top: 12px;
  font-size: 24px;
  color: var(--natural1);
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

.save-control-flex {
  display: flex;
  flex-direction: row;
}

.edit-project-label {
  display: flex;
  flex-direction: row;
}

.edit-project-error {
  color: red;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
