.cashflow-toggle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 200px;
  user-select: none;

  font-size: 16px;
}

.cashflow-toggle-button-in-left,
.cashflow-toggle-button-in-right {
  display: flex;
  flex-direction: row;
  justify-content: center;

  width: 100px;
  height: 25px;
  background-color: var(--heritage);
  color: var(--integrity);
}

.cashflow-toggle-button-out-right,
.cashflow-toggle-button-out-left {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  display: flex;
  flex-direction: row;
  justify-content: center;

  width: 100px;
  height: 25px;
  background-color: white;
  color: var(--confident);
}

.cashflow-toggle-button-out-left:hover,
.cashflow-toggle-button-out-right:hover {
  cursor: pointer;
  background-color: var(--heritage);
  color: var(--integrity);
}

.cashflow-toggle-button-in-left,
.cashflow-toggle-button-out-left {
  border-radius: 100px 0px 0px 100px;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.cashflow-toggle-button-in-right,
.cashflow-toggle-button-out-right {
  border-radius: 0px 100px 100px 0px;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.cashflow-toggle-button-in-left:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none;
}
