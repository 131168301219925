.ebb {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: 10px;

  /* A sticky element toggles between relative and fixed, depending on the scroll position. */
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}

.ebb-row {
  display: flex;
  flex-direction: row;
  justify-content: center;

  width: 215px;
}

.ebb-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: white;
  background-color: var(--fluidity1);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-size: 15px;

  width: 150px;
  height: 25px;
  margin-top: 10px;

  border-radius: 50px;
  user-select: none;
  text-decoration: none;
}

.ebb-button:hover {
  background-color: var(--fluidity2);
  cursor: pointer;
  text-decoration: none;
  color: white;
}
