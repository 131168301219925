.project-control-features-save {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	background-color: var(--natural1);
	color: var(--integrity);

	width: 150px;
	height: 35px;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	margin-bottom: 30px;
	margin-top: 30px;
}

.project-control-features-save:hover {
	background-color: var(--heritage);
	cursor: pointer;
}

.subtotal-control-features {
	width: 350px;
	padding: 20px;
}

.subtotal-control-settings {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-bottom: 25px;
}

.project-control-settings-label {
	width: 150px;
}
.subtotal-control-settings
	> .checkboxes-vertical
	> .checkboxes-vertical-container
	> .checkbox-label {
	width: 270px;
	color: black;
}
.checkboxes-vertical-container {
	display: block;
	position: relative;
	padding-left: 35px;
	cursor: pointer;
	font-size: 16px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	margin: 0px;
}
