/* Filtering */
.pct-filter-cell-description {
  width: 100%;
  margin-bottom: 10px;
  height: 20px;
}

.pct-filter-cell-min-max {
  width: 50%;
  margin-bottom: 10px;
  height: 20px;
}

.pct-filter-cell-drop-down {
  width: 100%;
  margin-bottom: 10px;
  height: 20px;
}
.pct-filter-cell-number{
  width: 100%;
  margin-bottom: 10px;
  height: 20px;
}
