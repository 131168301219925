.path {
  display: flex;
  flex-basis: row;
}

.benchmark-path {
  display: flex;
  flex-basis: row;
}

.admin-path {
  display: flex;
  flex-basis: row;
  align-items: center;
  margin-bottom: 20px;
  height: 50px;
}

.path-link,
.path-divider {
  font-size: 20px;
  color: var(--collaboration1);
}

.path-divider {
  margin-left: 5px;
  margin-right: 5px;
}

.path-link:hover {
  text-decoration: underline;
  color: var(--collaboration1);
}

.path-link-current {
  font-size: 20px;
  color: var(--heritage);
}

.path-link-current:hover {
  text-decoration: underline;
  color: var(--heritage);
}

.insight-path {
  display: flex;
  flex-basis: row;
  align-items: center;
  height: 50px;
  margin-left: 10px;
}
