.options-analysis-container .charts-container .card {
  width: 100%;
  height: max-content;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: white;

  padding: 5px;

  margin-top: 0px;
  margin-bottom: 30px;

  border-radius: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.options-analysis-container .charts-container .card-label {
  font-size: 13px;
  text-align: center;
  color: #333; /*color of the label text */
}
