.benchmarking-disclaimer-trigger {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.benchmarking-disclaimer-trigger-button {
  outline: 0;
  border: none;
  cursor: pointer;
  color: var(--heritage);
  text-decoration: underline;
  background: none;
}
