.company-profile {
  width: 900px;

  padding: 15px;
}

.company-profile-title-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  width: 100%;
}

.company-profile-buttons-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  width: 100%;
  margin-top: 15px;
}

.company-profile-delete-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--strength1);
  color: var(--integrity);
  width: 130px;
  height: 30px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.company-profile-delete-button:hover {
  background-color: var(--collaboration1);
  cursor: pointer;
}

.company-profile-logo {
  height: 100px;
}

/* PROFILE DETAILS */

.company-profile-details {
  margin-right: 30px;
}

.company-profile-details-divider {
  margin-right: 30px;
  margin-left: 30px;
  height: 80px;
  border-right: 1px lightgrey solid;
}

.company-profile-details-label {
  width: 140px;
  margin-right: 20px;
}

.company-profile-details-row {
  display: flex;
  justify-content: flex-start;
  align-items: space-between;
}

/* USER CONTAINER */

.company-profile-user-box {
  margin-top: 20px;
  height: 400px;
  width: 430px;
  margin-right: 20px;

  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  border: 1px solid grey;

  background-color: rgb(250, 250, 250);
}

.company-profile-project-box {
  margin-top: 20px;
  height: 400px;
  width: 430px;

  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  border: 1px solid grey;

  background-color: rgb(250, 250, 250);
}

.company-profile-user-title-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-bottom: 15px;
}

.company-profile-user-filter {
  width: 270px;
  height: 25px;
}

.company-profile-wide-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration1);
  color: var(--integrity);
  width: 100px;
  height: 30px;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;

  margin-left: 10px;

  font-size: 14px;
}

.company-profile-wide-button:hover {
  background-color: var(--strength1);
  cursor: pointer;
}

.company-profile-user-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
  margin-bottom: 5px;
}

.company-profile-user-row-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.company-profile-user-avatar {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  border-radius: 100px;
  width: 20px;
  margin-top: -5px;
  margin-left: 4px;
}

.company-profile-user-label {
  margin-left: 10px;
  font-size: 13px;
  margin-bottom: 3px;
}

.company-profile-user-delete-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration1);
  color: var(--integrity);
  width: 25px;
  height: 25px;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;

  margin-right: 10px;
  font-size: 13px;
}

.company-profile-user-delete-button:hover {
  background-color: var(--strength1);
  cursor: pointer;
}

.company-profile-list-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.company-profile-user-table-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  border-bottom: solid lightgrey 1px;
  padding: 5px;
}

.company-profile-user-name {
  width: 170px;
  margin-left: 10px;
  padding-bottom: 5px;
}

.company-profile-user-title {
  width: 170px;
  margin-left: 10px;
  padding-bottom: 5px;
}

.company-details-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
