/* The project-control-drawer container */
.project-control-drawer {
  position: relative;
  display: inline-block;
  font-size: 13px;
}

.project-control-drawer .display-4 {
  font-size: 13px;
  margin: 0px;
}

/* project-control-drawer content (hidden by default) */
.project-control-drawer-content {
  position: absolute;
  width: 230px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 12px 40px 0 rgba(0, 0, 0, 0.19);

  background-color: rgba(248, 249, 250, 1);
  margin-left: 200px;
  margin-top: -290px;
  padding: 10px;

  display: flex;
  flex-direction: column;

  z-index: 10000;
  animation: fadein 0.5s;

  border-radius: 25px;
}

/* project-control-drawer button */
.project-control-drawer .drawer-button,
.drawer-button-clicked {
  background-color: var(--collaboration1);
  color: white;

  border: none;
  outline: none;

  margin: 0 auto;

  height: 35px;
  width: 150px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin-bottom: 15px;

  border-radius: 30px;

  user-select: none;
}

.project-control-drawer .drawer-button {
  background-color: var(--collaboration1);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.project-control-drawer .drawer-button-clicked {
  background-color: var(--collaboration3);
  border: none;
  outline: none;
}

/* Add a red background color to navbar links on hover */
.project-control-drawer .drawer-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.project-control-drawer .drawer-button-clicked:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

/* Links inside the project-control-drawer */
.project-control-drawer-content a {
  float: none;
  color: var(--integrity);
  text-decoration: none;
  display: block;
  text-align: left;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
