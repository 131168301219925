/* ELEMENTS */

.cbs-element-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: rgba(248, 249, 250, 1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  color: var(--confident);
  height: var(--cbs-element-row-height);
  font-size: 11px;

  width: 690px;
  transition: all 0s cubic-bezier(0.25, 0.8, 0.25, 1);
  margin-bottom: 5px;
}

.cbs-element-details-checked {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--collaboration2);
  color: var(--integrity);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  height: var(--cbs-element-row-height);
  font-size: 11px;

  width: 690px;
  transition: all 0s cubic-bezier(0.25, 0.8, 0.25, 1);
  margin-bottom: 5px;
}

.cbs-element-details:hover {
  -webkit-transform: scale(1.01);
  -ms-transform: scale(1.01);
  transform: scale(1.01);
}

.cbs-element-description {
  width: 470px;

  height: var(--cbs-element-row-height);

  display: flex;
  flex-direction: row;
  align-items: center;

  border: 1px solid #ffffff00;
}

.cbs-element-description:hover {
  background-color: #ffffff;
  border: 1px solid black;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  cursor: text;
}

.cbs-element-code {
  width: 100px;
  color: var(--integrity);
  height: var(--cbs-element-row-height);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  cursor: pointer;
}

.cbs-element-move-container {
  width: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cbs-element-move-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 15px;

  color: var(--collaboration3);
}

.cbs-element-move-button-disabled {
  display: none;
}

.cbs-element-move-button:hover {
  background-color: var(--collaboration1);
  cursor: pointer;
}

.cbs-element-delete-button {
  width: 25px;
}

.cbs-element-unit {
  width: 50px;
  height: var(--cbs-element-row-height);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  border: 1px solid #ffffff00;
}

.cbs-element-unit:hover {
  background-color: #ffffff;
  border: 1px solid black;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  cursor: text;
}

.cbs-element-colourcode-container {
  height: var(--cbs-element-row-height);
  width: 25px;
  align-items: center;
  display: flex;
}

.cbs-element-colourcode {
  height: var(--cbs-element-row-height);
  width: 15px;
}

.cbs-elements-button-contaier {
  margin-top: 20px;

  width: max-content;
  height: max-content;
}
