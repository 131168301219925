.project-control-image {
  display: flex;
  flex-direction: column;

  padding: 15px;
}

.project-control-image-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  margin-bottom: 15px;
}

.project-control-save-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--natural1);
  color: white;
  padding: 5px;
  width: 150px;
  height: 35px;
  border: none;
  text-align: center;
}

.project-control-save-button:hover {
  background-color: var(--heritage);
  text-decoration: none;
  color: white;
  cursor: pointer;
}
