.about {
  height: 100vh;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-background {
  background-color: rgba(85, 87, 90, 0.8);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 20px;

  width: 300px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 0 auto;
  margin-bottom: 30px;
}

.about-title {
  margin-top: 20px;
  margin-bottom: 20px;
  color: white;
}

.about-title .display-4 {
  font-size: 30px;
}

.about-version {
  color: white;
  margin-bottom: 30px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.about-version-label {
  width: 140px;
}

.about-version-row {
  display: flex;
  flex-direction: row;
}
