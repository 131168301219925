.benchmark-project-info-container {
    padding-top: 30px;
    z-index: 1;
}

.titled-table-heading {
    margin: 10px;
}

.create-benchmark-form-container {
    display: flex;
    flex-direction: row;
}

.create-project-panel {
    height: auto;
    width:100% !important;    
}

/* Benchmark Project View Related  Table Css */
.project-info-tab1-table-cell-text {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
    color: black;
    text-align: left;
    padding: 7px
}

.project-info-table-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.col {
    width: 10%;
    text-align: center;
}

.col2 {
    width: 45%;
}

.col3 {
    width: 45%;
}

.create-project-matrix-panel {
    scrollbar-width: thin;
}

.custom-margin {
    margin-right: 150px;
    margin-left: 150px;
}

.cost-plan-view .pct-headings-row,
.cost-plan-view .pct-pagination,
.cost-plan-view .pct-filter-row,
.cost-plan-view .pct-row-total {
    background: linear-gradient(135deg, rgb(50, 98, 149) 0%, rgb(101, 137, 176) 100%);
    height: 35px;
    color: white;
    padding-left: 10px;
    font-size: 15px;
    padding-top:15px;
}

.cost-plan-view .pct-pagination {
    height: 55px
}

.cost-plan-view .pct-container .pct-row,
.cost-plan-view .pct-container .pct-row-selected {
    font-size: 16px;
    height: 35px;
    padding-left: 10px;
}

.cost-plan-view .table-options-cog-menu {
    background-color: var(--fluidity1);
}

.cost-plan-view .pct-row:hover {
    background-color: rgba(248, 249, 250, 1);
    box-shadow: none;
}

.cost-plan-view {
    margin-left: 50px;
    margin-bottom:50px;
    scrollbar-width: thin;
}

.visuals-view{
    height:600px;
    overflow: scroll;
    overflow-x:hidden;
    width: 100%;
    padding-left:340px;
    scrollbar-width: thin;
}
.visuals-view .benchmarking-chart-box-costm2,
.visuals-view canvas{
    width:500px
}
