.recon-highlights-bar-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  user-select: none;
}

.recon-highlights-bar {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: white;

  height: 135px;
  width: 700px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  transition: transform 0.3s;
}

/* .recon-highlights-bar:hover {
  -webkit-transform: scale(1.035);
  -ms-transform: scale(1.035);
  transform: scale(1.035);
} */

.recon-highlights-bar-link {
  text-decoration: none;
  height: 135px;
  margin-bottom: 30px;
}

.recon-highlights-bar-link:hover {
  text-decoration: none;
}

.recon-highlights-benchmarking {
  width: 270px;
  height: 55px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.recon-highlights-benchmarking-number {
  color: var(--heritage);
  margin-top: 15px;
  font-size: 25px;
}

.recon-highlights-cost {
  width: 260px;
  height: 55px;
  margin-top: 50px;
  margin-left: 15px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}

.recon-highlights-cost-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
}

.recon-highlights-cost-text {
  color: var(--collaboration1);
  font-size: 12px;
}

.recon-highlights-cost-number {
  color: var(--heritage);
  font-size: 12px;
}

.budget-graph {
  width: 50px;
}

.stacked-budget-bar {
  height: 100px;
  width: 300px;

  padding-top: 1px;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
}

.budget-bar {
  position: absolute;
  height: 15px;
  margin-top: 60px;
}

.cost-bar {
  position: absolute;
  height: 15px;
  margin-top: 60px;
  max-width: 300px;
}

.budget-line {
  position: absolute;
  height: 45px;
  width: 3px;
  margin-top: 45px;

  background-color: black;
}

.budget-label {
  display: flex;
  flex-direction: row;
  margin-top: 90px;
}

.budget-percentage {
  font-size: 25px;
  color: rgba(50, 98, 149, 1);
}

.budget-text {
  margin-top: 9px;
  margin-left: 10px;
  font-size: 15px;
  color: var(--collaboration1);
}

.budget-value {
  margin-top: 9px;
  margin-left: 5px;
  font-size: 15px;
  color: var(--heritage);
}

.recon-highlights-cost-title {
  position: absolute;
  color: var(--collaboration1);
  width: 630px;
}

.recon-highlights-benchmark-title {
  position: absolute;
  color: var(--collaboration1);
  width: 630px;
}

.cost-bar-svg {
  height: 35px;
}
