.image-upload-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.image-upload-preview {
  margin-bottom: 20px;
}

.image-upload-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  height: 35px;
  width: 100px;
  background-color: var(--collaboration1);
  color: white;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 100px;
}

.image-upload-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

input[type="file"] {
  display: none;
}
