.advice-field-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: 15px;
}

/* Highlights */
/* 800 - 30 (delete button) - 15 (gap) = 765 */
.advice-highlight {
  width: 755px;
}

.advice-delete-spacer {
  width: 30px;
}

/* Risks */
.advice-urgency {
  width: 200px;
}

.advice-title {
  margin-bottom: 15px;
  width: 800px;
}

.advice-add-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--natural1);
  color: var(--integrity);

  width: 150px;
  height: 35px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin-top: 30px;
  user-select: none;
}

.advice-add-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.advice-delete-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--strength1);
  color: var(--integrity);

  width: 30px;
  height: 30px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  user-select: none;
}

.advice-delete-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.advice-save-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration1);
  color: var(--integrity);

  width: 150px;
  height: 35px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin-top: 30px;
  user-select: none;
}

.advice-save-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.project-control-advice {
  padding: 15px;
}
