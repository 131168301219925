.portfolio-buttons-item-button-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 150px;
  margin-bottom: 15px;
}

.portfolio-buttons-item-add-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: white;
  background-color: var(--collaboration1);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-size: 15px;

  border-radius: 30px;
  width: 30px;
  height: 30px;

  user-select: none;
}

.portfolio-buttons-item-add-button:hover {
  cursor: pointer;
  background-color: var(--heritage);
}

.portfolio-buttons-item-add-button-clicked {
  color: white;
  background-color: var(--heritage);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-size: 15px;

  border-radius: 30px;
  width: 30px;
  height: 30px;

  user-select: none;
}

.portfolio-buttons-item-add-button-clicked:hover {
  cursor: pointer;
}

.portfolio-buttons-item-delete-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: white;
  background-color: var(--collaboration1);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-size: 15px;

  border-radius: 30px;
  width: 30px;
  height: 30px;

  user-select: none;
}

.portfolio-buttons-item-delete-button:hover {
  cursor: pointer;
  background-color: var(--strength1);
}
