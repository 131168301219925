.cost-planning-subtotal-open {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  font-size: 13px;
  height: 35px;
  width: 150px;
  background-color: var(--collaboration1);
  color: white;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 100px;
  margin-bottom: 15px;
}

.cost-planning-subtotal-open:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.cost-planning-subtotal {
  padding: 15px;
}

.cost-planning-subtotal-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cost-planning-subtotal-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  height: 35px;
  width: 100px;
  background-color: var(--collaboration1);
  color: white;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 100px;
}

.cost-planning-subtotal-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.cost-planning-subtotal-estimate-display {
  display: flex;
  flex-direction: column;
}

.cost-planning-subtotal-estimate-example {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cost-planning-subtotal-estimate-bar {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  height: 30px;
  width: 420px;
  background-color: var(--fluidity1);
  color: var(--integrity);

  display: flex;
  flex-direction: row;
  align-items: center;

  white-space: nowrap;
  overflow: hidden;

  margin-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.cost-planning-subtotal-estimate-subtotal {
  height: 30px;
  width: 210px;
  background-color: var(--collaboration2);
  color: var(--integrity);

  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  padding-left: 10px;
}

.cost-planning-subtotal-estimate-add-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  height: 30px;
  width: 30px;

  background-color: var(--collaboration1);
  color: var(--integrity);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-left: 10px;
  padding-top: 1px;
  padding-left: 1px;
  font-size: 15px;
  border-radius: 100px;
}

.cost-planning-subtotal-estimate-add-button:hover {
  background-color: var(--heritage);
  color: var(--integrity);
  cursor: pointer;
}

.cost-planning-subtotal-estimate-subtotal::placeholder {
  color: var(--integrity);
}

.cost-planning-subtotal-estimate-bar-total::placeholder {
  color: var(--integrity);
}

.cost-planning-subtotal-estimate-bar-total {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  height: 30px;
  width: 210px;
  background-color: var(--collaboration1);
  color: var(--integrity);

  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  padding-left: 10px;
}
