.category-edit-display-block {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);

  display: table;
}

.category-edit-display-none {
  display: none;
}

.category-edit-main {
  width: 100%;
  height: 100%;
  transition: 1.1s ease-out;
  box-shadow: -2rem 2rem 2rem rgba(black, 0.2);
  filter: blur(0);
  transform: scale(1);
  border-radius: 0px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.category-edit-open {
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.category-edit-open:hover {
  color: var(--heritage);
  cursor: pointer;
}

.category-edit-icon {
  margin-right: 6px;
  font-size: 16px;
  color: var(--collaboration2);
}

.category-edit-open:hover .category-edit-icon {
  color: var(--heritage);
}

.category-edit {
  padding: 15px;

  /* Keeps the close button at the bottom */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.category-edit-select {
  height: 40px;
}

.category-edit-textarea {
  height: 200px;
  width: 100%;
  resize: none;
}

.category-edit-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  height: 35px;
  width: 100px;
  background-color: var(--collaboration1);
  color: white;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 100px;
}

.category-edit-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.category-edit-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.category-edit-error {
  color: var(--strength1);
}

.category-edit-success {
  color: var(--natural1);
}

.category-edit-text-counter-container {
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
}

.category-edit-error-container {
  padding-top: 5px;
}

.category-edit-counter-error {
  color: var(--strength1);
  margin-right: 7px;
}

.category-edit-counter-success {
  color: var(--natural1);
  margin-right: 7px;
}
.category-edit-field {
  width: 225px;
}
.category-edit-global {
  font-size: 16px;
  font-weight: bold;
  margin-right: 10px;
}

.category-title-input {
  width: 189px;
}
