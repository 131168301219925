.benchmark-control-table-container {
  width: 1560px;
  height: 500px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.benchmark-table-number-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.benchmark-table-number-symbol {
  width: 5px;
  margin-left: 15px;
}

.rt-td {
  font-size: 14px;
}

.rt-td input {
  margin-top: 3px;
}

.rt-resizable-header-content {
  font-size: 12px;
}

.rt-th {
  height: 30px;
}

.rt-th input {
  height: 20px;
  font-size: 11px;
}

.-btn {
  height: 10px;
}
