/* The post-contract-selector-drawer container */
.post-contract-selector-drawer {
  position: relative;
  display: inline-block;
  font-size: 13px;
}

.post-contract-selector-drawer .display-4 {
  font-size: 13px;
  margin: 0px;
}

/* post-contract-selector-drawer content (hidden by default) */
.post-contract-selector-drawer-content {
  position: absolute;
  width: 200px;
  height: 150px;
  max-width:200px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 12px 40px 0 rgba(0, 0, 0, 0.19);

  background-color: rgba(248, 249, 250, 1);
  margin-left: -100px;
  padding: 10px;

  display: flex;
  flex-direction: column;

  z-index: 10000;
  animation: fadein 0.5s;

  border-radius: 25px;
}

/* Links inside the post-contract-selector-drawer */
.post-contract-selector-drawer-content a {
  float: none;
  color: var(--collaboration1);
  text-decoration: none;
  display: block;
  text-align: left;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* BUTTON */

.post-contract-selector-drawer-button,
.post-contract-selector-drawer-button-clicked {
  background-color: var(--collaboration1);
  color: white;

  border: none;
  outline: none;

  height: 35px;
  width: 35px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border-radius: 30px;

  user-select: none;

  margin-bottom: 10px;
}

.post-contract-selector-drawer-button {
  background-color: var(--collaboration1);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.post-contract-selector-drawer-button-clicked {
  background-color: var(--collaboration3);
  border: none;
  outline: none;
}

/* Add a red background color to navbar links on hover */
.post-contract-selector-drawer-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.post-contract-selector-drawer-button-clicked:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.checkboxes-vertical {  
  margin-left: 0px; 
}