/* Label */
.company-edit-checkbox-text {
  width: 300px;
}

/* Customize the label (the container) */
.company-edit-checkbox {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 140px;

  margin: 0px;
  margin-right: 30px;
}

/* Hide the browser's default checkbox */
.company-edit-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.company-edit-checkbox-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 1px;
  height: 20px;
  width: 20px;
  margin-left: 200px;
  background-color: var(--collaboration2);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* On mouse-over, add a grey background color */
.company-edit-checkbox:hover input ~ .company-edit-checkbox-checkmark {
  background-color: var(--collaboration1);
}

/* When the checkbox is checked, add a blue background */
.company-edit-checkbox input:checked ~ .company-edit-checkbox-checkmark {
  background-color: var(--heritage);
}

/* When the checkbox is checked, add a blue background */
.company-edit-checkbox:hover input:checked ~ .company-edit-checkbox-checkmark {
  background-color: rgb(202, 82, 27);
}

/* Create the checkmark/indicator (hidden when not checked) */
.company-edit-checkbox-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.company-edit-checkbox input:checked ~ .company-edit-checkbox-checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.company-edit-checkbox .company-edit-checkbox-checkmark:after {
  left: 8px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
