.contacts-background {
  background-color: rgba(248, 249, 250, 1);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 20px;

  width: 1330px;

  padding-left: 130px;
  padding-right: 60px;
  padding-bottom: 45px;
  padding-top: 1px;

  margin: 0 auto;
}

.contacts-title {
  margin-top: 30px;
  margin-bottom: 30px;
}
