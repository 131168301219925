.feedback-container1 {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: rgba(248, 249, 250, 1);
  height: 240px;

  margin-top: 25px;
  overflow-y: scroll;
  padding: 10px;

  margin-right: 25px;
}

.feedback-display-container {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: rgba(248, 249, 250, 1);
  height: 240px;
  width: 900px;
  margin-top: 25px;
  overflow-y: scroll;
  padding: 10px;
  margin-right: 25px;
}

.feedback-note-container {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: rgba(248, 249, 250, 1);
  height: 240px;
  width: 420px;
  margin-top: 25px;
  overflow-y: scroll;
  padding: 10px;
  margin-right: 25px;
}

.feedback-control-container {
  display: flex;
  flex-direction: row;
}

.feedback-control-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration1);
  color: white;
  padding: 5px;
  text-align: center;
  border-radius: 100px;
}

.feedback-control-button:hover {
  background-color: var(--heritage);
  color: white;
  cursor: pointer;
}

.feedback-control-button-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 160px;
}

.feedback-control-note-textarea {
  width: 100%;
  height: 170px;
  resize: none;
}

.feedback-control-note-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.feedback-control-save {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration1);
  color: white;
  padding: 5px;
  text-align: center;
  border-radius: 100px;
  height: 35px;
  width: 100px;
}

.feedback-control-save:hover {
  background-color: var(--heritage);
  color: white;
  cursor: pointer;
}

.feedback-display {
  margin-bottom: 30px;
}

.feedback-display-label-container {
  display: flex;
  flex-direction: row;
}

.feedback-display-name {
  margin-right: 5px;
}
