.benchmark-selection-content-container {
  display: flex;
  justify-content: space-between;

  padding: 15px;
}

.benchmark-selection-content-container .display-4 {
  font-size: 20px;
  margin-bottom: 20px;
}

.benchmark-selection-table-filter-container {
  width: 300px;
  padding-right: 15px;
  margin-right: 8px;

  display: flex;
  flex-direction: column;

  border-right: 1px lightgrey solid;
}

.benchmark-selection-table-container {
  width: 1200px;

  display: flex;
  flex-direction: column;
  align-items: right;
}

/* BUTTONS */

.modalbenchmark-popup-button {
  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration1);
  color: white;
  width: 45px;
  height: 45px;
  font-size: 18px;

  border-radius: 100px;
  margin-left: 17px;

  cursor: pointer;
}

.modalbenchmark-popup-button:hover {
  background-color: var(--heritage);
}

.modalbenchmark-popup-button-container {
  height: max-content;
}

.modalbenchmark-popup-button.disabled,
.modalbenchmark-popup-button.disabled:hover {
  background-color: var(--collaboration3);
  color: var(--collaboration1);
}
