/* TABLE */

.comparison-table {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: var(--integrity);
    width: 700px;
    font-size: 13px;
    margin-bottom: 30px;
}

/* ROWS */

.comparison-table-head-row {
    display: flex;
    flex-direction: row;

    background-color: var(--collaboration1);
    color: var(--integrity);

    width: 700px;
    height: 30px;
}

.comparison-table-row {
    display: flex;
    flex-direction: row;

    background-color: var(--integrity);
    color: var(--confident);

    width: 700px;
    height: 30px;
}

.comparison-table-total-row {
    display: flex;
    flex-direction: row;

    background-color: var(--collaboration4);
    color: var(--confident);

    width: 700px;
    height: 30px;
}

/* CELLS */

.comparison-table-description-cell {
    width: 350px;
    padding: 5px;
}

.comparison-table-previous-cell {
    width: 100px;
    padding: 5px;
    display: flex;
    justify-content: space-between;
}

.comparison-table-current-cell {
    width: 100px;
    padding: 5px;
    display: flex;
    justify-content: space-between;
}

.comparison-table-subtotal-cell {
    width: 100px;
    display: flex;
    justify-content: space-between;
    padding: 5px;
}

/* HEADER CELLS */

.comparison-table-previous-cell-head {
    width: 100px;
    padding: 5px;
    display: flex;
    justify-content: flex-end;
}

.comparison-table-current-cell-head {
    width: 100px;
    padding: 5px;
    display: flex;
    justify-content: flex-end;
}

.comparison-table-subtotal-cell-head {
    width: 100px;
    display: flex;
    justify-content: flex-end;
    padding: 5px;
}

/* BORDERS */

.comparison-table-previous-cell-head,
.comparison-table-current-cell-head,
.comparison-table-subtotal-cell-head {
    border-bottom: 1px solid grey;
    border-right: 1px solid grey;
}

.comparison-table-description-cell,
.comparison-table-previous-cell,
.comparison-table-current-cell,
.comparison-table-subtotal-cell {
    border-bottom: 1px solid grey;
    border-right: 1px solid grey;
}

.comparison-table-value-positive {
    color: var(--confident);
}

.comparison-table-value-negative {
    color: var(--heritage);
}