.admin-localisation-table-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.admin-localisation-table-top {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 20px;
}

.admin-localisation-table-header {
  display: flex;
  flex-direction: row;

  width: 100%;
  margin-bottom: 5px;
}

.admin-localisation-table-key-search {
  width: 300px;
  height: 25px;
}
.admin-localisation-table-value-search {
  width: 300px;
  height: 25px;
}
