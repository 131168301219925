.idle-warning {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
}

.idle-warning-button-container {
  display: flex;
  flex-direction: row;
  width: 600px;

  justify-content: space-evenly;
}

.idle-warning-logout-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration1);
  color: var(--integrity);

  height: 30px;
  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;

  margin-top: 20px;
}

.idle-warning-logout-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.idle-warning-cancel-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--natural1);
  color: var(--integrity);

  height: 30px;
  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;

  margin-top: 20px;
}

.idle-warning-cancel-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}
