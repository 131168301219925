.upload-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.modal-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.article-title-text {
  margin-bottom: 15px;
}
.mi-toggle-active-button {
  width: 200px !important;
  color: white;
  background-color: var(--heritage);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-size: 15px;

  height: 40px;

  user-select: none;
}
.mi-toggle-active-button:hover {
  background-color: var(--collaboration1);
  cursor: pointer;
}
.mi-toggle-inactive-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: black;
  background-color: white;
  width: 200px !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-size: 15px;

  height: 40px;

  user-select: none;
}

.mi-toggle-inactive-button:hover {
  background-color: var(--collaboration1);
  cursor: pointer;
  color: white;
}
.post-contract-toggle {
  margin-top: 0px !important;
  margin-bottom: 30px !important;
}
.article-link {
  width: 350px;
}
.article-global-checkbox{
  margin-left: 10px;
}