.project-budget-reconciliation {
  margin-top: 20px;
}

.budget-recon-section-title {
  /* display: flex;
    flex-direction: row;
    align-items: center;
    height: 45px; */
  margin-top: 0px;
}
