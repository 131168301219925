.benchmark-control-upload-button {
  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration1);
  color: white;

  width: 45px;
  height: 45px;

  border-radius: 45px;

  margin-bottom: 15px;
}

.benchmark-control-upload-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.disclaimer-checkbox {
  margin-top: 5px;
  margin-right: 15px;
}

.disclaimer-container {
  margin-bottom: 0px !important;
}

.disclaimer-tooltip-container {
  margin-left: 1150px;
}