.benchmark-create-configuration-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px 0;
}

.benchmark-create-configuration-modal-content-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 0 20px;
}

.benchmark-create-configuration-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 1s;
  border-radius: 4px;
}

.benchmark-create-configuration-modal-content.headless {
  grid-column: -1 / 1;
  align-items: flex-start;
}

.benchmark-create-configuration-modal-content.boxed {
  min-width: 360px;
  width: 360px;
  max-width: 450px;
  padding: 20px 0;
  background-color: rgba(248, 249, 250, 1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.benchmark-create-configuration-modal-content.boxed:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.benchmark-create-configuration-modal-content > h1.display-4.form-title {
  font-size: 25px;
  margin-bottom: 20px;
}

.benchmark-create-configuration-modal-content > h1.display-4.form-title > i {
  margin: 0 10px;
}

.benchmark-create-configuration-modal-content > .text {
  display: flex;
  flex-direction: column;
}

.benchmark-create-configuration-modal-content .create-project-input-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
}

.benchmark-create-configuration-modal-content .fa-question-circle {
  margin-left: 5px;
  cursor: pointer;
}

.benchmark-create-configuration-modal-content .create-project-select,
.benchmark-create-configuration-modal-content .create-project-location-select,
.benchmark-create-configuration-modal-content .create-project-input {
  width: 205px;
  margin-left: auto;
  border: 1px solid var(--collaboration3);
}

.benchmark-create-configuration-modal-content .benchmark-map {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 205px;
  width: 100%;
  padding: 10px 20px;
}

.benchmark-create-configuration-modal-content
  .benchmark-map
  > .create-project-map {
  display: flex;
  flex-direction: row;
  width: 205px;
  margin: 0;
  border-radius: 4px;
  box-shadow: none;
}

.benchmark-create-configuration-modal-content
  .benchmark-map
  > .coordinate-inputs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.benchmark-create-configuration-modal-content
  .benchmark-map
  > .coordinate-inputs
  > label,
.benchmark-create-configuration-modal-content
  .benchmark-map
  > .coordinate-inputs
  > input {
  width: 105px;
  margin: 0;
}

.benchmark-create-configuration-modal .modal-save-button,
.benchmark-selection-content-container .modal-save-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: white;
  background-color: var(--natural1);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-size: 15px;

  width: 150px;
  height: 30px;

  border: none;

  margin-top: auto;

  cursor: pointer;
}

.benchmark-create-configuration-modal .modal-save-button:hover,
.benchmark-selection-content-container .modal-save-button:hover {
  background-color: var(--heritage);
}

.benchmark-create-configuration-modal .modal-save-button:disabled,
.benchmark-selection-content-container .modal-save-button:disabled {
  background-color: var(--collaboration2);
}

.benchmark-create-configuration-modal .modal-error,
.benchmark-create-selection-modal .modal-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 15px;
  font-weight: bold;
  color: red;
  margin: 20px 0;
  min-height: 25px;
}

.benchmark-create-analysis-gate .error-message {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.benchmark-create-configuration-modal .toggle {
  display: flex;
  flex-direction: row;
  width: 360px;
  align-items: center;
  justify-items: flex-start;
  font-size: medium;
}

.benchmark-create-configuration-modal .toggle > .icon {
  margin: 0 auto 0 10px;
  cursor: pointer;
}

.benchmark-create-configuration-modal .toggle > label.switch {
  margin: 0;
  cursor: pointer;
}
