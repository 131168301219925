.estimate-benchmarking {
  margin-top: 30px;
}

.benchmarking-flex-box {
  display: flex;
  flex-shrink: 0;
  width: 1430px;
}

.estimate-accordion-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: inline-block;
  background-image: linear-gradient(-90deg, var(--fluidity1), var(--fluidity1));
  color: white;
  padding: 7px;
  margin-bottom: 23px;
  width: 1430px;
  height: 45px;
  border: none;
  text-align: center;
  font-size: 20px;
  flex: 0.7;
  cursor: pointer;

  user-select: none;
}

.estimate-benchmarking-dashboard {
  display: flex;
  flex-direction: column;
  height: 390;
}

.estimate-benchmarking-dashboard-horizontal-charts {
  display: flex;
  flex-direction: row;
}

.estimate-benchmarking-dashboard-vertical-charts {
  margin-left: 30px;
}

.estimate-benchmarking-container {
  width: 1430px;
}

.metrics-chart {
  height: 250px;
}
