.project-control-estimate-metric-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  width: 855px;
  margin-bottom: 7px;
}

.project-control-estimate-metric-display {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  width: 610px;
  margin-right: 30px;
  height: 70px;
  background-color: rgba(248, 249, 250, 1);
  border: 1px var(--collaboration3) solid;
}

.project-control-estimate-metric-display-value {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 100%;

  font-size: 15px;
  color: var(--heritage);
  margin-left: 10px;
}

.project-control-estimate-metric-display-quantity {
  margin-right: 5px;
}

.project-control-estimate-metric-display-metric {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  font-size: 13px;
  color: var(--collaboration1);
  height: 100%;
  width: 300px;
}

/* Remove Button */

.project-control-estimate-metric-remove-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--strength1);
  color: var(--integrity);

  width: 30px;
  height: 30px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin-left: 20px;
  user-select: none;
}

.project-control-estimate-metric-remove-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.project-control-estimate-metric-display-input-code {
  height: 25px;
  width: 50px;
}

.project-control-estimate-metric-display-input-value {
  height: 25px;
  width: 150px;
}

.project-control-estimate-metric-display-input-unit {
  height: 25px;
  width: 100px;
}

.project-control-estimate-metric-display-input-metric {
  height: 25px;
  width: 250px;
}
