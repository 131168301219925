.options-analysis-control {
  /* External */
  z-index: 50;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 140px;
  height: 60px;
  margin-left: -70px;
  margin-top: -52.5px;
  border-radius: 50px;

  /* Internal */
  background-color: rgba(248, 249, 250, 1);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 80px;
}

.options-analysis-control-hidden {
  display: none;
}

.options-analysis-edit-button,
.options-analysis-edit-button-clicked {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration1);
  color: white;
  margin-left: 15px;
  height: 45px !important;
  width: 45px;
  padding: 8px;
  border: none;
  flex-shrink: 0;

  user-select: none;
  border-radius: 50%;

  z-index: 100;

  cursor: pointer;
}

.options-analysis-edit-button:hover,
.options-analysis-edit-button-clicked:hover {
  background-color: var(--heritage);
}

.options-analysis-edit-button-clicked {
  background-color: var(--collaboration2);
}
