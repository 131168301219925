.request-demo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.request-demo-title .display-4 {
  font-size: 30px;
  color: white;
  margin-top: 20px;
  margin-bottom: 15px;
}

.request-demo-para {
  margin-bottom: 20px;
}

.request-demo-para .display-4 {
  font-size: 15px;
  color: white;
}

.request-demo-input-row {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}

.request-demo-input-label {
  width: 200px;
  color: white;
}

.request-demo-input-label .display-4 {
  font-size: 15px;
}

.request-demo-input {
  width: 300px;
  height: 20px;
  font-size: 12px;
}

.request-demo-button {
  width: 150px;
  height: 30px;
  background-color: rgb(115, 115, 115);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--integrity);
  margin-top: 20px;
  margin-bottom: 20px;
}

.request-demo-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.request-demo-button-container {
  width: 800px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.request-demo-button-error {
  width: 250px;
  padding-left: 30px;
}

.request-demo-error {
  color: var(--integrity);
}

.request-demo-success {
  color: var(--integrity);
}
