.react-pdf__Page__canvas {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 900px;
}

.react-pdf__Page__annotations {
  display: none;
}

.pdf-viewer {
  display: flex;
  padding-left: 95px;
  margin-bottom: 95px;
}

.pdf-viewer-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 300px;
  padding-left: 0;
  margin-right: 30px;
}

.pdf-previous-button,
.pdf-download-button,
.pdf-next-button,
.pdf-delete-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: inline-block;
  background-color: var(--collaboration1);
  color: white;
  padding: 5px;
  width: 150px;
  height: 35px;
  border: none;
  text-align: center;
  border-radius: 0px;
}

.pdf-previous-button:hover,
.pdf-next-button:hover,
.pdf-download-button:hover,
.pdf-delete-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
  text-decoration: none;
  color: white;
}

.pdf-previous-button:active,
.pdf-next-button:active,
.pdf-download-button:active,
.pdf-delete-button:active {
  box-shadow: none;
}

.pdf-previous-button:disabled,
.pdf-next-button:disabled,
.pdf-download-button:disabled,
.pdf-delete-button:disabled {
  background-color: var(--collaboration3);
  text-decoration: none;
  color: var(--collaboration2);
}
