.tpi-calc-chart-container {
  width: 100%;
  height: 200px;
  background-color: white;

  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 30px;
}

.tpi-calc-chart {
  min-width: 100%;
  height: 200px;
}

.tpi-calc-chart-legend {
  height: 100px;
  display: flex;
  flex-direction: column;
}

.tpi-calc-chart-legend-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tpi-calc-chart-legend-item-bar {
  width: 50px;
  height: 7px;
  background-color: orange;
  border-radius: 50px;
}

.tpi-calc-chart-legend-item-text {
  width: 130px;
}
