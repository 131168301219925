.switch {
	position: relative;
	display: inline-block;
	width: 50px;
	height: 24px;
}

.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--collaboration2);
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.slider:before {
	position: absolute;
	content: "";
	height: 16px;
	width: 16px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

input:checked + .slider {
	background-color: var(--heritage);
}

input:focus + .slider {
	box-shadow: 0 0 1px var(--heritage);
}

input:checked + .slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}

/* Tooltip Text */

.switch .tooltiptext {
	visibility: hidden;
	width: 120px;
	background-color: var(--heritage);
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	color: #fff;
	text-align: center;
	padding: 5px 0;

	/* Position the tooltip */
	position: absolute;
	left: 70px;
	bottom: -5px;
	border-radius: 100px;
	z-index: 1;
}

.switch:hover .tooltiptext {
	visibility: visible;
}
.costplanning-subtotal-popup {
	display: flex;
}
.costplanning-subtotal-button:hover {
	background-color: var(--heritage);
	cursor: pointer;
}
.costplanning-subtotal-button {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	background-color: var(--collaboration1);
	width: 35px;
	height: 35px;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	color: white;
	transition: all 0s cubic-bezier(0.25, 0.8, 0.25, 1);

	border-radius: 30px;
	margin-right: 15px;
}
