:root {
  --pctm-metrics-number-cell-width: 15%;
  --pctm-metrics-tex-cell-width: 65%;
}

.pctm {
  margin: 10px;
  margin-bottom: 0px;
  margin-right: 0px;
}
.pctm-container-row {
  display: flex;
  flex-direction: row;
}
.pctm-container-horizontal {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  margin-right: 10px;
}

.pctm-container {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 10px;
  margin-right: 10px;
}

.pctm-row-head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  background: linear-gradient(
    135deg,
    rgb(85, 87, 90) 0%,
    rgb(145, 143, 146) 100%
  );

  color: var(--integrity);

  font-size: 11px;
}

.pctm-row-head-text {
  display: flex;
  flex-direction: row;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  background: linear-gradient(
    135deg,
    rgb(50, 98, 149) 0%,
    rgb(101, 137, 176) 100%
  );

  color: var(--integrity);

  font-size: 11px;
}

.pctm-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.pctm-value-cell-head {
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 150px;
  padding: 5px;

  height: 34px;
}

.pctm-value-cell {
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 150px;
  padding: 5px;
}

.pctm-value-head-text {
  display: flex;
  flex-direction: row;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
  width: 150px;
  min-height: 20px;
  padding: 5px;
  word-break: break-all;
}

.pctm-value-cell-text {
  display: flex;
  flex-direction: row;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 20px;
  padding: 5px;
  word-break: break-all;

  height: 34px;
}

.pctm-row .display-4 {
  font-size: 20px;
  margin-bottom: 15px;
}

.pctm-metrix-cell {
  display: flex;
  flex-direction: row;
  min-height: 20px;
  padding: 5px;
  border-right: 1px solid rgba(0, 0, 0, 0.09);
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}

.pctm-value-cell-tex {
  display: block;
  text-align: left !important;
  justify-content: flex-start;
  word-break: break-all;
  width: var(--pctm-metrics-tex-cell-width);
}

.center-aligned-text {
  display: block;
  text-align: center;
  width: var(--pctm-metrics-number-cell-width);
}

.pctm-value-cell-number {
  display: block;
  text-align: right;
  justify-content: flex-start;
  width: var(--pctm-metrics-number-cell-width);
}

.pctm-value-cell-decimal {
  text-align: right;
  align-items: right;
  justify-content: flex-start;
  width: 10%;
}

.pctm-matrix-row {
  width: 100%;
}

.create-project-matrix-panel {
  width: 100%;
  max-height: 650px;
  padding: 0px;
}

.pctm-container-matrix-horizontal {
  box-shadow: none;
  margin-bottom: 0px;
}

.pctm-matrix-subheader {
  background: linear-gradient(
    135deg,
    rgb(50, 98, 149) 0%,
    rgb(101, 137, 176) 100%
  );
  height: 35px;
  color: white;
  font-size: 20px;
  padding-left: 10px;
}
