.privacy-background {
  height: 100vh;
  overflow-y: auto;
}

.privacy-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 700px;

  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: relative;
  top: 50%;
  left: 50%;

  z-index: 1;
}

.privacy-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 410px;

  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: relative;
  top: 50%;
  left: 50%;

  z-index: 1;
}

.privacy-brand {
  margin: 0 auto;
  margin-top: 150px;
}

.privacy-container {
  display: flex;
  flex-direction: column;
  color: white;

  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;

  opacity: 1;

  margin: 0 auto;
}

.privacy-box {
  display: flex;
  flex-direction: column;
  align-items: center;

  background: rgba(85, 87, 90, 0.8);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 3);
  margin: 30px;
  padding: 30px;
  border-radius: 20px;
}

.privacy-text-container {
  margin-top: 10px;
  margin-bottom: 30px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.privacy-label {
  margin: 0 auto;
  margin-bottom: 30px;
}

.privacy-button {
  background-color: rgb(115, 115, 115);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border: none;
  color: white;
  text-align: center;
  font-size: 16px;
  width: 280px;
  height: 50px;
  line-height: 50px; /* same as height! */
  margin: 0 auto;
  margin-bottom: 10px;
}

.privacy-button:hover {
  color: white;
  background-color: var(--heritage);
  text-decoration: none;
}

.privacy-button:focus {
  box-shadow: none;
}

.privacy-video-container {
  margin-top: 35px;
}
