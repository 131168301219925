.benchmark-mini-table {
  display: grid;
  grid-template-columns: auto 2fr repeat(5, 1fr); /* Total 7 columns */
}

/*
See: How to Add Internal “Borders” Between Rows & Columns in a CSS Grid Layout
https://geary.co/internal-borders-css-grid/
*/

.benchmark-mini-table.grid {
  --gap: 15px;
  --line-offset: calc(var(--gap) / 2);
  --line-thickness: 1px;
  --line-color: var(--collaboration4);

  display: grid;
  overflow: hidden;
  gap: var(--gap);
}

.benchmark-mini-table.grid > .grid-item {
  position: relative;
}

.benchmark-mini-table.grid > .grid-item::before,
.benchmark-mini-table.grid > .grid-item::after {
  content: "";
  position: absolute;
  background-color: var(--line-color);
  z-index: 1;
}

/* Column Borders */
.benchmark-mini-table.grid > .grid-item::before {
  inline-size: var(--line-thickness);
  block-size: 100vh;
  inset-inline-start: calc(var(--line-offset) * -1);
}

/* Row Borders */
.benchmark-mini-table.grid > .grid-item::after {
  inline-size: 100vw;
  block-size: var(--line-thickness);
  inset-inline-start: 0;
  inset-block-start: calc(var(--line-offset) * -1);
}

.benchmark-mini-table.grid > .grid-item.full-width {
  grid-column: -1 / 1;
}
