.help-container {
  width: 100%;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  user-select: none;
}

.help-heading {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  margin-top: 5px;
}

.help-accordion {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  margin-top: 5px;
}

.help-accordion:hover {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.help-accordion:hover .help-icon {
  color: var(--heritage);
}

.help-text {
  width: 100%;
  margin-top: 5px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.help-section {
  font-size: 11px;
}

.help-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: white;
  background-color: rgb(115, 115, 115);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-size: 15px;

  width: 280px;
  height: 25px;
  margin: 0 auto;
  margin-top: 20px;
  user-select: none;
}

.help-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
  text-decoration: none;
  color: white;
}
