.area-schedules-container {
  margin-top: 30px;

}


.area-schedules-table-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-top: 30px;
}

.area-schedule-recon-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 10px;
}