.cost-planning-upload-container {
  display: flex;
  flex-direction: row;
}

.cost-planning-upload {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  width: 600px;
  padding: 15px;
}

/* FILES */

.cost-planning-upload-files {
  height: 150px;
  width: 520px;

  padding: 10px;

  overflow-y: scroll;
  overflow-x: scroll;
  border: 1px solid lightgrey;

  display: flex;
  flex-direction: column;

  font-size: 13px;
}

.cost-planning-files-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  height: 20px;
}

.cost-planning-files-part {
  min-width: max-content;
  height: 20px;

  margin-right: 5px;
}

.cost-planning-files-item-name {
  min-width: max-content;
  height: 20px;

  margin-right: 5px;
}

.cost-planning-message {
  min-width: max-content;

  height: 20px;
  margin-right: 5px;

  color: var(--natural1);
}

.benchmark-upload-message-error {
  min-width: max-content;

  height: 20px;
  margin-right: 5px;

  color: var(--strength1);
}

/* UPLOAD CONTROLS */

.cost-planning-upload-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration3);
  color: white;
  width: 100px;
  height: 100px;
  border: none;
  text-align: center;
  border-radius: 100px;

  margin: 0px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.cost-planning-upload-button-highlight {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--heritage);
  color: white;
  width: 100px;
  height: 100px;
  border: none;
  text-align: center;
  border-radius: 100px;

  margin: 0px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.cost-planning-upload-button:hover {
  background-color: var(--heritage);
  text-decoration: none;
  color: white;
  cursor: pointer;
}

.cost-planning-upload-control-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  margin-bottom: 30px;
  margin-top: 15px;
}

.cost-planning-upload-control {
  width: 500px;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.cost-planning-upload-control-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 390px;
  margin-bottom: 1px;
}

.cost-planning-upload-control-label {
  width: 170px;
  margin-right: 20px;
  font-size: 14px;
}

.cost-planning-upload-control-input {
  width: 200px;
  height: 25px;
}

.cost-planning-upload-document-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: white;
  background-color: var(--collaboration1);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-size: 15px;

  width: 200px;
  height: 25px;

  user-select: none;
}

.cost-planning-upload-document-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
  text-decoration: none;
  color: white;
}

/* CBS SECTION */

.cost-planning-upload-cbs-control-container {
  border-left: 1px solid lightgrey;
  padding: 20px;
  width: 400px;
}

.cost-planning-upload-cbs-control {
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.cost-planning-upload-cbs-control-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 1px;
}

.cost-planning-upload-cbs-control-label {
  width: 250px;
  margin-right: 20px;
  font-size: 14px;
}

.cost-planning-upload-cbs-note {
  font-size: 12px;
}
