.project-control-panel {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 200px;
}

.project-control-panel-controls {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  min-height: 250px;
}

.project-control-panel-controls .display-4 {
  font-size: 20px;
  margin-bottom: 10px;
}

.project-control-panel-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 150px;
  margin-bottom: 15px;
}

.project-control-panel-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration1);
  width: 35px;
  height: 35px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  transition: all 0s cubic-bezier(0.25, 0.8, 0.25, 1);

  border-radius: 30px;
}

.project-control-panel-button-disabled {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration3);
  width: 35px;
  height: 35px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  transition: all 0s cubic-bezier(0.25, 0.8, 0.25, 1);

  border-radius: 30px;
}

.project-control-panel-button-selected {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--heritage);
  width: 35px;
  height: 35px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  transition: all 0s cubic-bezier(0.25, 0.8, 0.25, 1);

  border-radius: 30px;
}

.project-control-panel-button:hover {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);

  cursor: pointer;
  background-color: var(--heritage);
}

/* Button Tooltips */

.project-control-toggle-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 35px;
}

.project-control-toggle-button-container .tooltiptext {
  visibility: hidden;
  background-color: var(--heritage);
  color: #fff;
  text-align: center;
  padding: 6px 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  /* Position the tooltip */
  border-radius: 100px;
  margin-left: 55px;
  margin-top: -1px;
  position: absolute;
  z-index: 3000;

  user-select: none;
}

.project-control-toggle-button-container .tooltiptext:hover {
  display: none;
}

.project-control-toggle-button-container:hover .tooltiptext {
  visibility: visible;
}

/* Panel Buttons */

.project-control-panel-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 35px;
  border-radius: 100px;
  margin-bottom: 5px;
  margin-top: 5px;

  width: 200px;
}

.project-control-panel-button-container .tooltiptext {
  visibility: hidden;
  background-color: var(--heritage);
  color: #fff;
  text-align: center;
  padding: 6px 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  /* Position the tooltip */
  border-radius: 100px;
  margin-left: 55px;
  margin-top: -1px;
  position: absolute;
  z-index: 3000;

  user-select: none;
}

.project-control-panel-button-container .tooltiptext:hover {
  display: none;
}

.project-control-panel-button-container:hover .tooltiptext {
  visibility: visible;
}
