.tpi-location-select-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.tpi-location-select {
  display: flex;
  width: 180px;
  height: 25px;
  border: 1px solid lightgrey;

  font-size: 14px;
}

.tpi-location-label {
  height: 30px;
  margin-bottom: 5px;
  width: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
