/* LAYOUT */

.benchmark-selection-filter-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: 450px; */
}

.benchmark-selection-filter-input-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: 9.5px;
}

.benchmark-selection-filter-input-col {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 11px;
}

.benchmark-selection-filter-input-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 130px;
}

.benchmark-selection-filter-toggle-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 130px;
}

.benchmark-selection-filter-toggle-row .switch {
  margin-bottom: 0px;
}

/* LABEL */

.benchmark-selection-filter-input-heading {
  width: 130px;
  display: flex;

  align-items: center;
}

/* ICONS */

.benchmark-selection-filter-input-icon {
  color: var(--collaboration2);
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  font-size: 15px;
  width: 40px;
}

.benchmark-selection-filter-input-icon-focus {
  color: var(--heritage);
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  font-size: 15px;
  width: 40px;
}

/* INPUTS */

.benchmark-selection-filter-input,
.benchmark-selection-filter-input-pair,
.benchmark-selection-filter-input-pair-second {
  color: var(--collaboration1);
  height: 20px;
  border: 1px var(--collaboration1) solid;
}

.benchmark-selection-filter-input:focus,
.benchmark-selection-filter-input-pair:focus,
.benchmark-selection-filter-input-pair-second:focus {
  box-shadow: none;
  outline: none;
  border: 1px var(--heritage) solid;
}

.benchmark-selection-filter-input-pair,
.benchmark-selection-filter-input-pair-second {
  width: 60px;
}

.benchmark-selection-filter-input {
  width: 130px;
}
