.portfolio-control-drop-zone {
  width: 110px;
  height: 90px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 15px;

  align-items: center;
  font-size: 30px;

  color: grey;
  background-color: var(--integrity);
  -moz-box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.24);
}

.portfolio-control-drop-zone-highlight {
  width: 110px;
  height: 90px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 15px;

  align-items: center;
  font-size: 30px;

  color: grey;

  -moz-box-shadow: inset 0 3px 8px var(--heritage);
  -webkit-box-shadow: inset 0 3px 8px var(--heritage);
  box-shadow: inset 0 3px 8px var(--heritage);
  transition: all 1s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.portfolio-control-drop-zone-plus {
  width: 110px;
  height: 90px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 15px;

  align-items: center;
  font-size: 30px;

  color: white;

  background-color: var(--natural4);
  -moz-box-shadow: inset 0 3px 8px var(--natural1);
  -webkit-box-shadow: inset 0 3px 8px var(--natural1);
  box-shadow: inset 0 3px 8px var(--natural1);
  transition: all 1s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.portfolio-control-drop-zone-minus {
  width: 110px;
  height: 90px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 15px;

  align-items: center;
  font-size: 30px;

  color: white;

  background-color: var(--strength4);
  -moz-box-shadow: inset 0 3px 8px var(--heritage);
  -webkit-box-shadow: inset 0 3px 8px var(--heritage);
  box-shadow: inset 0 3px 8px var(--heritage);
  transition: all 1s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.portfolio-control-drop-zone-arrow {
  font-size: 15px;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.portfolio-control-drop-zone-arrow-highlight {
  font-size: 30px;
  color: var(--heritage);
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
}
