.submit-locations {
  width: 150px;
  margin: 10px;
}

.location-field-titles {
  display: flex;
  flex-direction: row;
}

.location-field-domain {
  margin-left: 110px;
}

.input-location-name {
  height: 20px;
  width: 200px;

  margin-right: 3px;
}

.input-locations-row {
  display: flex;
  flex-direction: row;

  height: 20px;

  margin-bottom: 5px;
}

.locations-insert {
  background-color: var(--natural1);
  color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 100px;
  height: 20px;
  padding: 3px;
  border: none;
  margin-left: 20px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.locations-insert:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.locations-delete {
  background-color: var(--collaboration1);
  color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 100px;
  height: 20px;
  padding: 3px;
  border: none;
  margin-left: 20px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.locations-add {
  background-color: var(--natural1);
  color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 150px;
  height: 35px;
  margin-top: 10px;
  padding: 3px;
  border: none;
}

.locations-add:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.locations-delete:hover {
  background-color: var(--strength1);
  cursor: pointer;
}

.flex-container-locations {
  display: flex;
  flex-direction: column;
}

/* locations Container */

.edit-locations-container {
  width: 1330px;
  min-height: 770px;
  border-radius: 20px;

  margin: 0 auto;
}

.locations-save-control-flex {
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 50px;
}

.locations-save-control-flex-button {
  display: flex;
  flex-direction: column;
}

/* Submitted */

.submitted-true-locations {
  margin-left: 30px;
  font-size: 24px;
  color: var(--natural1);
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

.submitted-false-locations {
  display: none;
}

.spinner-false-locations {
  display: none;
}

.spinner-true-locations {
  margin-left: 30px;
  width: 50px;
}

.error-false-locations {
  display: none;
}

.error-true-locations {
  margin-left: 30px;
  font-size: 15px;
  color: var(--strength1);
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}
