.location-factor {
  width: 200px;
  margin-right: 20px;
}

.location-factor-field-container {
  max-height: 220px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}

.location-field {
  word-wrap: break-word;
  margin-left: 20px;
  width: 200px;
}

.location-container {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 600px;
}

/* TABLE */
.location-factors-table {
  width: 400px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 15px;
  margin-right: 30px;
}

/* ROWS */
.location-factors-table-head-row {
  display: flex;
  flex-direction: row;
  background-color: var(--collaboration2);
  color: var(--integrity);
  font-weight: bold;

  width: 400px;
  height: 40px;
}

.location-factors-table-row {
  display: flex;
  flex-direction: row;

  background-color: var(--integrity);
  color: var(--confident);

  width: 400px;
  height: 40px;

  user-select: none;
}

.location-factors-table-quantity-cell {
  border-bottom: 1px solid grey;
  border-right: 1px solid grey;
  width: 150px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

}

.location-factors-table-description-cell {
  border-bottom: 1px solid grey;
  border-right: 1px solid grey;
  width: 250px;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.location-author-info {
  text-align: center;
  font-size: 12px;
}

.location-factor-set-button {
  background-color: var(--natural1);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  color: white;
  padding: 5px;
  width: 170px;
  height: 35px;
  border: none;
  text-align: center;
  font-size: 13px;
  z-index: 200;

  border-radius: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin-top: 25px;
}

.location-factor-set-button:hover {
  background-color: var(--heritage);
  cursor: pointer;

}

.tpi-calc.location-contianer {
  width: 100%
}

.location-factors-table-body {
  height: 750px;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
  scrollbar-width: thin;
}