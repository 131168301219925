.estimate-total {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration1);
  color: var(--integrity);

  width: 1430px;
  height: 45px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;

  font-size: 16px;
  user-select: none;

  margin-right: 30px;
  margin-bottom: 30px;
}

.estimates-total-label {
  display: flex;
  flex-direction: start;
  align-items: center;
  padding-left: 15px;
  width: 100%;
  height: 45px;

  border-right: 1px solid white;
}

.estimates-total-costs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.estimates-total-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.estimates-total-gfa {
  width: 100px;
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-right: 1px solid white;
}

.estimates-total-unit {
  width: 65px;
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-right: 1px solid white;
}

.estimates-total-rate {
  width: 140px;
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-right: 1px solid white;
}

.estimates-total-total {
  width: 125px;
  height: 45px;

  padding-right: 15px;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
