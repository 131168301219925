/* CATEGORIES */

.cbs-category {
  height: max-content;
}

.cbs-code {
  width: 75px;
}

.cbs-categoryitem {
  margin-bottom: 20px;

  width: 690px;
  background-color: rgba(248, 249, 250, 1);
  padding: 10px;
  padding-top: 0px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  transition: all 0s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.cbs-categoryitem:hover {
  -webkit-transform: scale(1.01);
  -ms-transform: scale(1.01);
  transform: scale(1.01);
}

.cbs-categoryitem-codes {
  display: flex;
  flex-flow: wrap;

  align-items: center;

  color: grey;
  -moz-box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.24);
  padding: 10px;
  min-height: 60px;
}

.cbs-categoryitem-code {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: center;

  background-color: rgba(248, 249, 250, 1);
  padding: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  width: 100px;
  height: 30px;
  margin: 5px;
  font-size: 11px;

  color: var(--integrity);
  transition: all 0s cubic-bezier(0.25, 0.8, 0.25, 1);
  user-select: none;
}

.cbs-categoryitem-code:hover {
  cursor: pointer;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.cbs-section:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.cbs-category-control {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 700px;
  margin: 10px;
}

.cbs-categoryitem-description {
  background-color: rgba(248, 249, 250, 1);
  border: 1px solid #ffffff00;
  width: 100%;
}

.cbs-categoryitem-description:hover {
  background-color: #ffffff;
  border: 1px solid black;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  cursor: text;

  width: 100%;
}

.cbs-categoryitem-details {
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;
  background-color: var(--integrity);
  cursor: pointer;
}
