/* LAYOUT */

.recon-estimate-row {
  width: 1430px;
  display: flex;
  flex-direction: row;
}

/* MULTIPLE ESTIMATE DASHBOARD */

.recon-dash-chart-container {
  display: inline-flex;
}

.recon-dash-chart-box-1 {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background: var(--integrity);
  width: 700px;
  height: 300px;
  margin-bottom: 30px;
}

.recon-dash-chart-box3 {
  display: inline-block;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background: var(--integrity);
  width: 900px;
  margin-bottom: 20px;
}

/* ACCORDION */

.recon-accordion-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: inline-block;
  background-image: linear-gradient(-90deg, var(--fluidity1), var(--fluidity1));
  color: white;
  padding: 7px;
  margin-bottom: 23px;
  width: 1430px;
  height: 45px;
  border: none;
  text-align: center;
  font-size: 20px;
  flex: 0.7;
  cursor: pointer;

  user-select: none;
}

/* INDIVIDUAL ESTIMATE DASHBOARD */

.recon-estimate-dashboard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 1430px;

  margin-bottom: 30px;
}

.recon-estimate-chart-container-1 {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background: var(--integrity);
  width: 700px;

  padding: 10px;
}

/* INDIVIDUAL ESTIMATE */

.recon-estimate-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 1430px;
}

.recon-estimate-section-graph {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background: var(--integrity);
  margin-bottom: 30px;
  padding: 15px;
  width: 100%;
}

/* OTHER */
.graph-box {
  width: 600px;
}

.reconciliation-label {
  font-size: 30px;
  margin-bottom: 20px;
}

.graph-label {
  font-size: 25px;
  margin-bottom: 20px;
}

.reconciliation-select-box {
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.reconciliation-box {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px;
  background: var(--integrity);
  display: inline-block;
  width: 700px;
  flex-shrink: 0;
  margin-bottom: 30px;
}

.reconciliation-box-hidden {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px;
  background: var(--collaboration4);
  display: inline-block;
  width: 700px;
  flex-shrink: 0;
  margin-bottom: 30px;
}

.reconciliation-box-title {
  width: 100%;
  font-size: 20px;
  color: var(--heritage);
}

.reconciliation-flex-box {
  display: flex;
  flex-shrink: 0;
  width: 990px;
}

.reconciliation-text {
  display: block;
  margin-top: 30px;
}

.reconciliation-flex-box-client {
  display: flex;
  margin-bottom: 20px;
  flex-shrink: 0;
  width: 700px;
}

.reconciliation-box-hidden-client {
  display: none;
}

.reconciliation-box-client {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px;
  margin-bottom: 10px;
  width: 700px !important;
  background: var(--integrity);
  display: inline-block;
  flex: 2;
  flex-shrink: 0;
}

.recon-cost-comparison-graph {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--integrity);
  margin-bottom: 30px;
  padding: 15px;
  width: 700px;
}

.chart-box1 {
  display: inline-block;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background: var(--integrity);
  margin-right: 30px;
  width: 435px;
  margin-top: 15px;
  margin-bottom: 30px;
}

.recon-manager-comment {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background: var(--integrity);
  padding: 10px;
  width: 700px;
}

/* GRAPHS */

.reconciliation-graph-select-box {
  padding: 1px;
  display: inline-block;
  margin: 10px;
  font-size: 20px;
  border: 1px;
  border-style: solid;
  border-color: var(--collaboration3);
  background: var(--collaboration4);
}

.reconciliation-graph {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: white;

  width: 700px;
  margin-bottom: 30px;
}

/* TABLES */

.recon-table-box {
  width: 700px;
}

.recon-estimate-dashboard-highlights-chart-container {
  display: flex;
  flex-direction: column;
}

.reconciliation-parts-delete-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration1);
  color: white;

  width: 45px;
  height: 45px;

  border: none;
  text-align: center;

  flex-shrink: 0;

  margin-left: 17px;
  border-radius: 100px;
}

.reconciliation-parts-delete-button:hover {
  background-color: var(--strength1);
  cursor: pointer;
}

.reconciliation-cost-plan-comparison-chart {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background: var(--integrity);
  width: 700px;
  margin-right: 30px;
  height: 279px;
}
