.value-engineering-head-row {
  width: 1430px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  display: flex;
  flex-direction: row;

  background-color: var(--collaboration2);
  color: var(--integrity);

  min-height: 40px;
}

.value-engineering-row {
  display: flex;
  flex-direction: row;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  min-height: 40px;

  background-color: var(--integrity);
  color: var(--confident);

  width: 1430px;
}

.value-engineering-row.group-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  background: var(--collaboration4);
}

.ve-table:not(.edit-active):not(.no-hover)
  .value-engineering-row:not(.totalrow):hover,
.ve-table:not(.edit-active):not(.no-hover)
  .value-engineering-row:not(.totalrow):active {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: rgb(223, 223, 223);
  color: var(--confident);
  cursor: pointer;
  text-decoration: none;
  transform: scale(1.01);
}

.value-engineering-container-total {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 600px;
  height: 40px;
  background-color: var(--collaboration1);
  color: var(--integrity);

  margin-top: 30px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.status-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid grey;
}

.vm-heading-status {
  width: 100px;
}

.vm-total-data {
  width: 150px;
  padding-left: 15px;
}

i.approved,
.budget-cell-head.approved,
.budget-cell.approved {
  background-color: var(--natural1);
}

i.forreview,
.budget-cell-head.forreview,
.budget-cell.forreview {
  background-color: var(--heritage2);
}

i.rejected,
.budget-cell-head.rejected,
.budget-cell.rejected {
  background-color: var(--strength1);
}

.totalrow {
  background-color: var(--collaboration1);
  color: var(--integrity);
  font-weight: bold;
  margin-bottom: 25px;
}

.ve-ref-cell-head,
.ve-ref-cell {
  width: 60px;
}

.ve-description-cell {
  width: 420px;
}

.ve-package-location-cell {
  justify-content: left;
  width: 120px;
}

.ve-package-location-head {
  width: 120px;
}

.ve-status-cell-head,
.ve-status-cell {
  width: 180px;
}

.ve-head-row-container {
  display: flex;
  flex-direction: row;
}

.ve-table-missing-info {
  margin-top: 15px;
  margin-bottom: 45px;
}

.ve-table-controls-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 220px;
  height: 40px;
}

.ve-table-control {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: inline-block;
  background-color: var(--collaboration1);
  color: white;
  height: 30px !important;
  width: 30px;
  border: none;
  text-align: center;
  flex-shrink: 0;
  margin-left: 17px;
  border-radius: 50%;
  cursor: pointer;
}

.ve-table-control:disabled {
  box-shadow: none;
  background-color: var(--collaboration3);
  color: var(--collaboration2);
  cursor: not-allowed;
}

.ve-table-control:not(:disabled):hover,
.ve-table-control:not(:disabled):active {
  background-color: var(--heritage);
}

.ve-disclaimer {
  width: 1430px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.project-control-advice .advice-text {
  font-size: 20px;
}
