.project-item-highlights {
  height: 120px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.project-item-highlights .display-4 {
  font-size: 16px;
  margin: 0px;
}

.project-item-row {
  height: 23px;
  width: 100%;

  display: flex;
  flex-flow: wrap;
  align-items: center;
}

.projectitem-stage-box,
.projectitem-current-stage-box,
.projectitem-future-stage-box {
  height: 23px;
  width: 23px;
  font-size: 9px;
  border-radius: 100px;
  margin-right: 10px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
}

.projectitem-stage-box {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration1);
  color: white;
}

.projectitem-current-stage-box {
  box-shadow: 0 2px 4px 0 var(--strength2), 0 3px 10px 0 var(--strength2);
  background-color: var(--heritage);
  color: white;
}
.projectitem-future-stage-box {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration3);
  color: black;
}

.project-item-text {
  margin-right: 10px;
}
