/* Colours */
:root {
  --heritage: rgb(228, 97, 15);
  --heritage2: rgba(228, 97, 15, 0.7);
  --confident: rgb(29, 29, 29);
  --know-how: rgb(179, 179, 179);
  --integrity: rgb(255, 255, 255);
  --natural1: rgb(13, 166, 66);
  --natural2: rgb(113, 202, 142);
  --natural3: rgb(184, 228, 198);
  --natural4: rgb(231, 246, 236);
  --collaboration1: rgb(85, 87, 90);
  --collaboration2: rgb(145, 143, 146);
  --collaboration3: rgb(196, 194, 196);
  --collaboration4: rgb(235, 234, 235);
  --built1: rgb(248, 218, 64);
  --built2: rgb(252, 232, 154);
  --built3: rgb(254, 244, 208);
  --built4: rgb(255, 251, 240);
  --growth1: rgb(195, 210, 0);
  --growth2: rgb(221, 227, 131);
  --growth3: rgb(238, 241, 198);
  --growth4: rgb(250, 251, 237);
  --fluidity1: rgb(50, 98, 149);
  --fluidity2: rgb(101, 137, 176);
  --fluidity3: rgb(153, 171, 202);
  --fluidity4: rgb(204, 216, 229);
  --strength1: rgb(228, 31, 19);
  --strength2: rgb(241, 135, 100);
  --strength3: rgb(249, 198, 176);
  --strength4: rgb(254, 237, 229);
}

#root {
  height: 100vh;
}

.App {
  height: 100vh;
}
html {
  height: 100%;
  overflow-y: scroll;
}

.footer {
  box-shadow: 0 4px 8px 10px rgba(0, 0, 0, 0.2),
    0 6px 20px 10px rgba(0, 0, 0, 0.19);
  position: absolute;
  width: 100%;
}

/* Disables Scroll Arrows on Number Fields */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.general-upload-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: white;
  background-color: var(--natural1);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-size: 15px;

  width: 150px;
  height: 30px;

  user-select: none;
  border:0px;
}

.general-upload-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.general-modal-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: white;
  background-color: var(--collaboration1);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-size: 15px;

  width: 150px;
  height: 30px;

  user-select: none;
}

.general-modal-button-disabled {
  color: white;
  background-color: var(--collaboration3);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-size: 15px;

  width: 150px;
  height: 30px;

  user-select: none;
}

.general-modal-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.general-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;

  height: 30px;
}

.general-row-container {
  display: flex;
  flex-direction: row;
  justify-content: center;

  min-height: 30px;

  margin-bottom: 15px;
}

.general-row-container-double {
  display: flex;
  flex-direction: row;
  justify-content: center;

  height: 30px;

  margin-top: 15px;
  margin-bottom: 15px;
}

.general-delete-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: white;
  background-color: var(--strength1);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-size: 15px;

  width: 150px;
  height: 30px;

  user-select: none;
}

.general-delete-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.general-delete-button-flat {
  color: white;
  background-color: var(--strength1);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-size: 15px;

  width: 150px;
  height: 30px;

  user-select: none;
}

.general-side-panel-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: white;
  background-color: var(--collaboration1);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-size: 15px;

  border-radius: 30px;
  width: 150px;
  height: 30px;

  user-select: none;
}

.general-side-panel-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}
