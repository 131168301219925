.benchmarking-justification-button {
  margin-left: 10px;
  margin-right: -30px;

  width: 16px;
  height: 16px;

  background: none;
  border: none;
  padding: 0;

  cursor: pointer;
}

.pct-percent .fa-caret-down {
  color: red;
  font-size: 20px;
  margin-right: 5px;
  text-align: center;
  vertical-align: middle;
}

.pct-percent .fa-caret-up {
  color: green;
  font-size: 20px;
  margin-right: 5px;
  text-align: center;
  vertical-align: middle;
}
