.profile-display-block {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);

  display: table;
}

.profile-display-none {
  display: none;
}

.profile-modal-main {
  width: 100%;
  height: 100%;
  transition: 1.1s ease-out;
  box-shadow: -2rem 2rem 2rem rgba(black, 0.2);
  filter: blur(0);
  transform: scale(1);
  border-radius: 0px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

/* Overlay Experiment */

.overlay {
  position: fixed; /* Positioning and size */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(128, 128, 128, 0.5); /* color */
  display: none; /* making it hidden by default */
}

.profile.modal-button-flexbox {
  margin-top: 50px;
  display: flex;
}

/* BUTTONS */

.estimate-upload-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-left: -160px;
  margin-top: 250px;
  position: absolute;
  background-color: var(--collaboration1);
  color: white;
  padding: 5px;
  width: 130px;
  height: 35px;
  border: none;
  text-align: center;
  border-radius: 0px;
  font-size: 15px;
  z-index: 200;
}

.estimate-upload-button:hover {
  background-color: var(--heritage);
  text-decoration: none;
  cursor: pointer;
  color: white;
}

.modalpdfuploader-popup-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: inline-block;
  background-color: var(--collaboration1);
  color: white;
  padding: 5px;
  width: 150px;
  height: 35px;
  border: none;
  text-align: center;
  border-radius: 0px;
  z-index: 200;
  margin-top: 5px;
  margin-left: 25px;
}

.modalpdfuploader-cancel-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: inline-block;
  background-color: var(--collaboration1);
  color: white;
  padding: 5px;
  width: 150px;
  height: 35px;
  border: none;
  text-align: center;
  border-radius: 0px;
}

.modalpdfuploader-popup-button:hover,
.modalpdfuploader-cancel-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}
