#commentary-text-viewer-area {
  border: none;
  margin-left: -12px;
}

.ql-container.ql-snow {
  border: none;
}

.ql-toolbar.ql-snow {
  border: none;
}
