/* TABLE HEAD */

.benchmarking-table-head {
  background: var(--collaboration1);
  color: rgb(255, 255, 255);

  display: flex;
  flex-direction: row;
  font-size: 12px;

  height: 67px;
}

.bdt-project-name {
  width: 250px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.bdt-base-date {
  width: 130px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  height: 100%;
}

.bdt-gfa {
  width: 130px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  height: 100%;
}

.bdt-procurement {
  width: 200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  height: 100%;
}

.bdt-quality {
  width: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  height: 100%;
}

.bdt-type {
  width: 130px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  height: 100%;
}

.bdt-sector {
  width: 190px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  word-wrap: anywhere;

  height: 100%;
}

.bdt-lra-unit {
  width: 140px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  align-items: center;

  height: 100%;
}

.bdt-gia {
  width: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  height: 100%;
}
.bdt-cost-off {
  font-size: 12px;
  padding: 5px;
  text-align: center;
  background-color: var(--collaboration3);

  width: 150px;

  height: 100%;
  color: inherit;

  border: none;
  outline: inherit;
}

.bdt-cost-on {
  font-size: 12px;
  padding: 5px;
  text-align: center;
  background-color: var(--heritage);

  width: 150px;

  height: 100%;
  color: inherit;

  border: none;
  outline: inherit;
}

.bdt-cost-on:hover {
  cursor: pointer;
}

.bdt-cost-off:hover {
  cursor: pointer;
}

/* BENCHMARK PROJECT ROW */

.bdt-project-row {
  background-color: var(--collaboration2);
  color: var(--integrity);
  width: 1430px;
  display: flex;
  flex-direction: row;

  font-size: 12px;
  height: 29px;

  cursor: pointer;

  border: none;
  outline: inherit;
  padding: 0;
}

.bdt-row-name-cell {
  width: 250px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 5px;

  height: 29px;
}

.benchmark-display-table-project-col {
  padding: 5px;

  height: 29px;
  overflow: hidden;
}

/* BENCHMARK TABLE ROW */

.bdt-row {
  background-color: var(--integrity);
  color: var(--collboration1);
  display: flex;
  flex-direction: row;
  font-size: 12px;

  width: 1430px;
  height: 29px;

  border: none;
  outline: inherit;
  padding: 0;
}

.bdt-row:focus {
  outline: inherit;
}

.benchmark-display-table-row {
  padding: 5px;
  background-color: white;
}

.benchmarking-table-box {
  width: 1430px;
  margin-bottom: 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.benchmarking-table-heading {
  font-size: 12px;
  padding: 5px;
  text-align: center;
  height: 30px;
}

/* TOTAL ROW */

.bdt-total-row {
  background-color: var(--collaboration1);
  color: white;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  height: 29px;
}

.bdt-cost {
  width: 150px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  height: 100%;
}

/* BORDERS */

.bdt-project-name,
.bdt-row-name-cell,
.bdt-base-date,
.bdt-gfa,
.bdt-procurement,
.bdt-quality,
.bdt-type,
.bdt-sector,
.bdt-cost-off,
.bdt-cost-on,
.bdt-lra-unit,
.bdt-gia,
.bdt-cost {
  border-right: 1px solid var(--collaboration3);
  border-bottom: 1px solid var(--collaboration3);
}

.estimate-table,
th,
td,
tr {
  border: 1px solid var(--collaboration3);
}

.location-warning-icon {
  margin-left: 10px;
  color: rgb(233, 213, 2);
  position: relative;
  cursor: pointer;

  border: none;
  outline: inherit;
  background: none;
}

.project-warning-icon {
  margin-left: 5px;
  color: rgb(233, 213, 2);
  position: relative;
  cursor: pointer;

  border: none;
  outline: inherit;
  background: none;
}

.tooltip {
  visibility: hidden;
  width: 200px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.location-warning-icon.show-tooltip .tooltip {
  visibility: visible;
  opacity: 1;
}

.project-warning-icon.show-tooltip .tooltip {
  visibility: visible;
  opacity: 1;
}

.bm_display_table {
  height: fit-content;
}

.escalated-switch {
  display: flex;
  flex-direction: row-reverse;
}
