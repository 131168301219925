.estimate-control {
  /* External */
  z-index: 50;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  height: 60px;
  margin-left: -435px;
  margin-top: -8px;
  border-radius: 50px;
  width: 450px;

  /* Internal */
  background-color: rgba(248, 249, 250, 1);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 80px;
}
.set-margin-left{
  margin-left: -130px !important;
  width: 145px !important;
}

.estimate-control-hidden {
  display: none;
}

.estimate-edit-button-clicked {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--collaboration2);
  color: white;
  margin-left: 17px;
  height: 45px !important;
  width: 45px;
  padding: 8px;
  border: none;
  flex-shrink: 0;

  user-select: none;
  border-radius: 100px;

  z-index: 100;
}
