.commentary-title-none {
  display: none;
}

.commentary-label-no-summary {
  margin-bottom: 580px;
}

/* BUTTONS */
.commentary-title-flexbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  height: 45px;

  margin-left: 15px;
  margin-right: 15px;
}

.commentary-control-flexbox {
  display: flex;
  justify-content: space-evenly;
  width: 650px;
  padding: 10px;
}

.input-custom-title {
  width: 100%;
  height: 30px;
}

.commentary-select-box {
  width: 100%;
  height: 30px;
}

.label-title-toggle {
  margin-top: 10px;
  width: 100px;
}

.label-title-selectbox {
  margin-top: 10px;
  width: 100px;
}

.label-title-input {
  margin-top: 10px;
  width: 100px;
}

.commentary-cancel-button,
.commentary-done-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: inline-block;
  background-color: var(--collaboration1);
  color: var(--integrity);
  padding: 5px;
  width: 160px;
  height: 35px;
  border: none;
  text-align: center;
  border-radius: 0px;
  z-index: 200;
}

.commentary-cancel-button:hover,
.commentary-done-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.commentary-add-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-top: 30px;
  background-color: var(--collaboration1);
  color: white;
  padding: 5px;
  width: 150px;
  height: 35px;
  border: none;
  text-align: center;
  font-size: 15px;
  z-index: 200;

  border-radius: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.commentary-add-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.ect-cell {
  width: 160px;
}

.ect-controls {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.ect-controls-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  height: 35px;
  width: 150px;
  background-color: var(--collaboration1);
  color: white;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ect-controls-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}
