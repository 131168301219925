.language-select .dropdown .dropdown-content {
  margin-left: 15px;
}

.language-select-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.dropbtn .fas {
  color: var(--collaboration2);
  margin-right: 5px;
  margin-left: -10px;
}

.language-select-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}

.language-select-button:hover {
  color: var(--heritage);
  cursor: pointer;
}

.dropdown:hover .fas {
  color: var(--heritage);
}

.language-select-heading {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  color: var(--integrity);
  background-color: var(--collaboration1);
  height: 20px;
  padding-left: 15px;
  line-height: 50px;
}

.language-select-heading:hover {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}
