.modal-changes-uploader-display-block {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.modal-changes-uploader-display-none {
  display: none;
}

.modal-changes-uploader-main {
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px;
  border-radius: 20px;
  margin: 0 auto;
}

.modalchangesuploader-popup-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration1);
  color: white;

  width: 45px;
  height: 45px;

  border: none;
  text-align: center;

  margin-left: 17px;
  border-radius: 100px;
}

.modalchangesuploader-popup-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.add-reconciliation-container {
  height: 35px;
}
