.locality-factor-container {
    padding: 15px;
    width: 550px;
    height: 200px;
    position: relative;
}

.locality-factor-flex-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.locality-factor-label {
    width: 150px;
    font-size: 16px;
    font-weight: bold;
    margin-right: -20px;
}

.locality-factor-input {
    width: 100px;
    height: 25px;
    margin-right: 25px;
}

.checkboxes-vertical {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 40px;
    margin-top: 20px;
}

.checkbox-label {
    font-size: 16px;
    font-weight: bold;
}

.checkboxes-vertical-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 1px;
    height: 20px;
    width: 20px;
    background-color: var(--collaboration2);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.save-locality-button {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    color: white;
    background-color: var(--natural1);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    width: 150px;
    height: 30px;
    user-select: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    max-width: 100%;
    padding: 0 10px;
}

.save-locality-button:hover {
    background-color: var(--heritage);
    cursor: pointer;
}

.project-stages-error-container {
    margin-top: 10px;
}

.location-factor-error.success {
    color: green;
    font-weight: bold;
}

.location-factor-error.error {
    color: red;
    font-weight: bold;
}