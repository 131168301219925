.company-employees-save-button {
  background-color: var(--natural1);
  color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 150px;
  height: 30px;
  border: none;
}

.company-employees-save-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.company-employees-save-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 30px;
}

.company-employees-submitted-false {
  display: none;
}

.company-employees-submitted-true {
  align-items: center;
  margin-left: 30px;
  color: var(--natural1);
}

.company-employees-submitted-error {
  align-items: center;
  margin-left: 30px;
  color: var(--strength1);
}

.company-employees-spinner-false {
  display: none;
}

.company-employees-spinner-true {
  margin-left: 30px;
}
