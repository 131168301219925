.portfolio-navigation-tab-group {
  display: flex;
  flex-direction: column;
  user-select: none;
  background-color: rgba(248, 249, 250, 1);
  margin-bottom: 30px;
}

/* Widths */
.portfolio-navigation-section,
.portfolio-navigation-section-selected {
  width: 190px;
  font-size: 14px;
}

.portfolio-navigation-section {
  color: var(--confident);
}

.portfolio-navigation-section-selected,
.portfolio-navigation-section-selected .icon-container {
  color: var(--heritage);
}

.portfolio-navigation-tab-link:hover {
  text-decoration: none;
}

.portfolio-navigation-label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.portfolio-navigation-label .front-icon {
  display: flex;
  flex-direction: row;
}

.icon-container {
  width: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  color: var(--collaboration2);
}

.portfolio-navigation-beta {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);

  padding: 5px;
  height: 15px;
  background-color: var(--heritage);
  color: var(--integrity);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border-radius: 5px;
  font-size: 11px;
  margin-left: 10px;
  margin-top: 3px;
}
