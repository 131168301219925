/* Descriptions */

.pct-heading-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 5px;

  color: var(--integrity);
}

.pct-description {
  padding: 5px;

  border: none;
  background: none;

  display: block;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  border-right: 1px solid rgb(228, 228, 228);
}

.pct-text {
  padding: 5px;

  border: none;
  background: none;

  display: block;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  border-right: 1px solid rgb(228, 228, 228);
}

/* Icons */

.pct-heading-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 5px;

  color: var(--integrity);
}

.pct-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 5px;

  border: none;
  background: none;

  border-right: 1px solid rgb(228, 228, 228);
}

.pct-icon-blue {
  color: var(--fluidity1);

  border: none;
  background: none;
}

.pct-icon-red {
  color: var(--strength1);

  border: none;
  background: none;
}

.pct-icon-yellow {
  color: var(--built1);

  border: none;
  background: none;
}

.pct-icon-orange {
  color: var(--heritage);

  border: none;
  background: none;
}

/* Statuses */
.pct-status {
  padding: 2px;

  color: var(--confident);

  border: none;
  background: none;

  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  border-right: 1px solid rgb(228, 228, 228);
}

.pct-heading-number {
  color: var(--integrity);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;

  padding: 5px;
}

.pct-heading,
.pct-heading-percent {
  color: var(--integrity);
  padding: 5px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;

  border-right: 1px solid rgb(228, 228, 228);
}

.pct-value {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  padding: 5px;

  border: none;
  background: none;

  border-right: 1px solid rgb(228, 228, 228);
}

.pct-percent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;

  padding: 2px;

  border: none;
  background: none;

  border-right: 1px solid rgb(228, 228, 228);
}

.pct-number {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 5px;

  border: none;
  background: none;

  border-right: 1px solid rgb(228, 228, 228);
}

.pct-quantity {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 5px;

  border: none;
  background: none;

  border-right: 1px solid rgb(228, 228, 228);
}

/* CHECKMARKS */

/* Customize the label (the pct-checkmark) */
.pct-checkmark {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border-right: 1px solid rgb(228, 228, 228);

  padding: 5px;

  border: none;
  background: none;

  accent-color: var(--heritage);
}

.pct-headings-container * {
  border: none;
}
