.cost-plan-bar {
  width: 500px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  transition: transform 0.3s;
}

.cost-plan-bar-link {
  text-decoration: none;
  margin-bottom: 30px;
}

.cost-plan-bar-link:hover {
  text-decoration: none;
}

.cost-plan-benchmarking-temp {
  width: 100%;
  height: 55px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.cost-plan-cost {
  width: 250px;
  height: 55px;
  margin-top: 20px;
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}

.cost-plan-cost-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
}

.cost-plan-cost-text {
  color: var(--collaboration1);
  font-size: 10.5px;
}

.cost-plan-cost-number {
  color: var(--heritage);
  font-size: 10.5px;
}

.cost-plan-stacked-bar {
  height: 70px;
  width: 210px;

  padding-top: 1px;
  display: flex;
  flex-direction: column;
}

.cost-plan-cost-bar-fill {
  fill: url(#MyGradient);
}

.cost-plan-horizontal-bar {
  position: absolute;
  height: 15px;
  margin-top: 40px;
  background-color: var(--collaboration2);

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.cost-plan-cost-bar {
  position: absolute;
  height: 15px;
  margin-top: 40px;
  max-width: 200px;
}

.cost-plan-cost-bar-svg {
  height: 35px;
}

/* COMPARISON */

.cost-plan-comparison-bar {
  position: absolute;
  height: 45px;
  width: 3px;
  margin-top: 25px;

  background-color: black;
}

.cost-plan-comparison-bar-label {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 70px;
}

.cost-plan-comparison-bar-percentage {
  font-size: 20px;
  margin-right: 5px;
  color: rgba(50, 98, 149, 1);
}

.cost-plan-comparison-bar-text {
  font-size: 13px;
  margin-right: 5px;
  color: var(--collaboration1);
}

.cost-plan-comparison-bar-value {
  font-size: 13px;
  color: var(--heritage);
}

#header-shape-gradient {
  --color-stop: #f12c06;
  --color-bot: #faed34;
}
