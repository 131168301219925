.project {
  background-color: rgba(50, 98, 149, 1);
  width: 100px;
  height: 13px;

  margin-top: 5px;
  margin-right: 10px;
  margin-left: 27px;
}

.benchmarks {
  background-color: rgba(50, 98, 149, 0.5);

  width: 100px;
  height: 13px;

  margin-top: 5px;
  margin-right: 10px;
  margin-left: 10px;
}
