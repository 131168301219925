.commentary-text-area {
  height: 250px;
  width: 650px;
  background: var(--integrity);
}

.commentary-text-editor {
  margin: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 650px;
}
