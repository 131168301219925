/* TABLE */

.scope-design-changes-table {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--integrity);
  width: 700px;
  font-size: 13px;
  margin-bottom: 30px;
}

/* ROWS */

.scope-design-changes-table-head-row {
  display: flex;
  flex-direction: row;

  background-color: var(--collaboration1);
  color: var(--integrity);

  width: 700px;
  height: 30px;
}

.scope-design-changes-table-subhead-row {
  display: flex;
  flex-direction: row;

  background-color: var(--collaboration2);
  color: var(--integrity);

  width: 700px;
  height: 30px;
}

.scope-design-changes-table-row {
  display: flex;
  flex-direction: row;

  background-color: var(--integrity);
  color: var(--confident);

  width: 700px;
}

.scope-design-changes-table-total-row {
  display: flex;
  flex-direction: row;

  background-color: var(--collaboration4);
  color: var(--confident);

  width: 700px;
}

.scope-design-changes-table-grandtotal-row {
  display: flex;
  flex-direction: row;

  background-color: var(--collaboration1);
  color: var(--integrity);

  width: 700px;
}

/* CELLS */

.scope-design-changes-table-description-cell {
  width: 400px;
  padding: 5px;
}

.scope-design-changes-table-previous-cell {
  width: 180px;
  padding: 5px;
  display: flex;
  justify-content: center;
}

.scope-design-changes-table-subtotal-cell {
  width: 120px;
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

/* HEAD CELLS */

.scope-design-changes-table-subtotal-cell-head {
  width: 120px;
  display: flex;
  justify-content: flex-end;
  padding: 5px;
}

/* BORDERS */

.scope-design-changes-table-description-cell,
.scope-design-changes-table-previous-cell,
.scope-design-changes-table-current-cell {
  border-bottom: 1px solid grey;
  border-right: 1px solid grey;
}

.scope-design-changes-table-subtotal-cell,
.scope-design-changes-table-subtotal-cell-head {
  border-bottom: 1px solid grey;
}

.scope-design-changes-table-total-row
  .scope-design-changes-table-description-cell,
.scope-design-changes-table-total-row .scope-design-changes-table-previous-cell,
.scope-design-changes-table-total-row .scope-design-changes-table-current-cell,
.scope-design-changes-table-total-row
  .scope-design-changes-table-subtotal-cell {
  border-bottom: none;
}

/* CELL STYLE */

/* .detailed-comparison-table-value-positive {
  color: var(--confident);
}

.detailed-comparison-table-value-negative {
  color: var(--natural1);
} */
