/* Buttons */

.category-delete-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration1);
  color: var(--integrity);

  border-radius: 100px;
  width: 35px;
  height: 35px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: all 0s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.category-delete-button:hover {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);

  cursor: pointer;
  background-color: var(--heritage);
}

/* Button Tooltips */

.insights-control-panel-button-container {
  display: flex;
  flex-direction: row;
  width: 35px;
  height: 35px;
  border-radius: 100px;
}

.insights-control-panel-button-container .tooltiptext {
  visibility: hidden;
  background-color: var(--heritage);
  color: #fff;
  padding: 6px 15px;
  text-align: center;
  width: max-content;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  /* Position the tooltip */
  border-radius: 100px;
  margin-left: 55px;
  margin-top: -1px;
  position: absolute;
  z-index: 0;
}

.insights-control-panel-button-container .tooltiptext:hover {
  visibility: hidden;
}

.insights-control-panel-button-container:hover .tooltiptext {
  visibility: visible;
}

.insights-control-panel {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.insights-control-panel-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 190px;
}
