.benchmark-element-table-box {
  width: 1430px;
  margin-bottom: 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--integrity);
}

.benchmark-element-table-container {
  overflow-x: scroll;
}

.benchmark-element-table {
  width: 1430px;
}

.benchmark-element-head {
  font-size: 12px;
  background: var(--collaboration1);
  color: var(--integrity);
  padding: 10px;
}

.benchmark-project-heading {
  background: var(--collaboration1);
  color: rgb(255, 255, 255);
  font-size: 12px;
}

.benchmark-element-uom-heading {
  background: var(--collaboration1);
  color: rgb(255, 255, 255);
  font-size: 12px;
  padding: 5px;
  text-align: center;

  height: 25px;
  width: 50px;
}

.benchmark-element-project-heading {
  background: var(--heritage);
  color: rgb(255, 255, 255);
  font-size: 12px;
  padding: 5px;
  text-align: center;

  height: 25px;
  width: 100px;
}

.benchmark-element-heading {
  background: var(--collaboration1);
  color: rgb(255, 255, 255);
  font-size: 12px;
  padding: 3px;
  text-align: center;

  height: 30px;
  width: 100px;
}

.benchmark-cell-description-label {
  width: 270px;
}

.benchmark-cell-unit-label {
  width: 100%;
}

.benchmark-element-cell-description,
.benchmark-element-cell-white,
.benchmark-element-cell-coloured {
  padding: 3px;
}

.benchmark-element-cell-coloured {
  color: white;
}

.benchmark-body {
  font-size: 12px;
}

.benchmark-element-cell-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.benchmark-element-cell-unit {
  text-align: center;
}
.benchmark-element-cell-average {
  color: black;
}
.benchmark-element-legend {
  padding: 10px;
  background-color: var(--collaboration1);
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  font-size: 12px;
}

.benchmark-element-legend-bar {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  flex-direction: row;

  border-radius: 30px;
}

.benchmark-element-legend-label {
  padding: 5px;
}

.benchmark-element-legend-low {
  width: 150px;
  text-align: center;
  background-color: var(--fluidity1);
  /* background-image: linear-gradient(
    to Right,
    var(--fluidity1),
    var(--fluidity2)
  ); */
  color: white;
  padding: 5px;

  border-radius: 30px 0 0 30px;
}

.benchmark-element-legend-range {
  width: 100px;
  text-align: center;
  background-color: var(--natural1);
  /* background-image: linear-gradient(
    to right,
    rgb(63, 212, 115),
    rgb(10, 136, 54)
  ); */
  color: white;
  padding: 5px;
}
.benchmark-element-legend-range-lightblue {
  width: 100px;
  text-align: center;
  background-color: var(--fluidity2);
  /* background-image: linear-gradient(
    to right,
    var(--fluidity2),
    rgb(153,171,202)
  ); */
  color: white;
  padding: 5px;
}
.benchmark-element-legend-range-lightgreen {
  width: 100px;
  text-align: center;
  background-color: var(--natural2);
  /* background-image: linear-gradient(
    to right,
    var(--natural2),
    rgb(113, 202, 142)
  ); */
  color: white;
  padding: 5px;
}
.benchmark-element-legend-range-lightred {
  width: 100px;
  text-align: center;
  background-color: rgb(255, 129, 129);
  /* background-image: linear-gradient(
    to right,
    var(--strength2),
    rgb(241, 135, 100)
  ); */
  color: white;
  padding: 5px;
}
.benchmark-element-legend-range-lightgrey {
  width: 100px;
  text-align: center;
  background-color: var(--collaboration3);
  /* background-image: linear-gradient(
    to right,
    var(--collaboration4),
    var(--collaboration3),
  ); */
  color: white;
  padding: 5px;
}
.benchmark-element-legend-high {
  width: 150px;
  text-align: center;
  background-color: var(--strength1);
  /* background-image: linear-gradient(
    to left,
    var(--strength1),
    rgb(241, 135, 100)
  ); */
  color: white;
  padding: 5px;

  border-radius: 0 30px 30px 0;
}
.benchmark-element-legend-alone-element {
  width: 150px;
  text-align: center;
  background-color: var(--collaboration3);
  /* background-image: linear-gradient(
    to left,
    var(--strength1),
    rgb(241, 135, 100)
  ); */
  color: white;
  padding: 5px;

  border-radius: 30px;
}
.benchmark-element-flex {
  display: flex;
  flex-direction: row;
}
