.pdcp-donut-chart-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.pdcp-donut-chart-legend {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.pdcp-donut-chart-total {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 236px;

  margin-top: 120px;
  margin-left: 24px;
}

.pdcp-donut-chart-total-label,
.pdcp-donut-chart-total-value {
  font-size: 12px;
  letter-spacing: 0.5px;
}

.legend-circle{
  r:6
}
