.estimates-container {
  margin-top: 30px;
}

.estimate-table-and-chart-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.estimate-table-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-top: 30px;
}

.estimate-table-and-total-container {
  margin-right: 20px;
}

.estimate-table-and-TPI-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 30px;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-top: 20px;
  border-radius: 35px;
}

.estimate-edit-button,
.estimate-delete-button,
.estimate-export-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration1);
  color: white;
  margin-left: 17px;
  height: 45px !important;
  width: 45px;
  padding: 8px;
  border: none;
  flex-shrink: 0;

  user-select: none;
  border-radius: 100px;

  z-index: 100;
}

.estimate-delete-button:hover {
  background-color: var(--strength1);
  cursor: pointer;
}

.estimate-edit-button-clicked:hover,
.estimate-edit-button:hover,
.estimate-export-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.estimate-flex-box {
  display: flex;
  flex-shrink: 0;
}

.total {
  font-size: 20px;
  margin-right: 40px;
}

.recon-export-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: inline-block;
  background-color: var(--collaboration1);
  color: white;

  margin-right: 5px;
  margin-top: 5px;

  height: 30px !important;
  width: 30px;
  padding: 5px;
  border: none;
  text-align: center;
  flex-shrink: 0;

  font-size: 12px;
  border-radius: 100px;
}

.recon-export-button:hover {
  cursor: pointer;
  background-color: var(--heritage);
}

.recon-chart-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.estimate-wrapper {
  display: flex;
  flex-direction: column;
}
