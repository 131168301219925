.portfolio-control-selection {
  width: 150px;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 15px;
  background-color: var(--integrity);

  -moz-box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.24);

  font-size: 13px;
  padding: 5px;
}

.portfolio-control-item-button {
  text-align: left;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.portfolio-control-item {
  padding-left: 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--collaboration1);
  user-select: none;
  width: 140px;

  margin-bottom: 1px;
  border-bottom: solid whitesmoke 1px;
}

.portfolio-control-item-empty {
  padding-left: 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--collaboration3);
  user-select: none;
  width: 140px;
  border-bottom: solid whitesmoke 1px;
  margin-bottom: 1px;
}

.portfolio-control-item-empty:hover {
  color: var(--heritage);
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  width: 140px;
  border-bottom: solid whitesmoke 1px;
}

.portfolio-control-item-selected {
  padding-left: 3px;
  width: 140px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  color: var(--heritage);
  user-select: none;
  border-bottom: solid whitesmoke 1px;

  margin-bottom: 1px;
}

.portfolio-control-item:hover {
  cursor: pointer;
  color: var(--heritage);
  user-select: none;
  text-decoration: none;
  width: 140px;

  text-overflow: ellipsis;
}

.portfolio-control-item-selected:hover {
  padding-left: 3px;
  width: 140px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  color: var(--heritage);
  user-select: none;
  text-decoration: none;

  margin-bottom: 1px;
}
