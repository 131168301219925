.pcra-log-container-root {
  --unit: 40px;

  --box-count: 4;
  --gap-count: calc(var(--box-count) - 1);

  --log-box-width: calc(7 * var(--unit));
  --log-box-height: calc(8 * var(--unit));

  --gap-width: var(--unit);
  --gap-width-half: calc(var(--gap-width) / 2);

  --scroll-bar-width: 17px;

  --container-padding: var(--gap-width);
  --container-padding-half: calc(var(--container-padding) / 2);
  --container-padding-quarter: calc(var(--container-padding) / 4);

  --container-width: calc(
    var(--box-count) * var(--log-box-width) + var(--gap-count) *
      var(--gap-width)
  );
  --container-outer-width: calc(
    var(--container-width) + var(--container-padding) + 2 *
      var(--scroll-bar-width)
  );
  --container-outer-width-max: 1300px;

  --label-width: calc(3 * var(--unit));
  --label-height: var(--unit);

  --internal-padding: calc(var(--gap-width) / 4);
  --internal-padding-half: calc(var(--internal-padding) / 2);

  --border-thin: 1px;
  --border-thick: 3px;
  --border-radius: 8px;
  --border-radius-TL-TR: 8px 8px 0 0;
  --border-radius-BL-BR: 0 0 8px 8px;
  --border-radius-BL: 0 0 0 8px;
  --border-radius-BR: 0 0 8px 0;

  --passed: var(--natural1);
  --failed: var(--strength1);
  --current: var(--built1);
  --neutral: var(--collaboration4);
  --muted: var(--collaboration3);
  --pending: var(--collaboration2);
  --label-PRODUCE: var(--collaboration3);
  --label-CHECK: var(--collaboration2);
  --label-REVIEW: var(--collaboration1);
  --label-APPROVE: var(--heritage);
  --banner-back: var(--built3);
  --banner-icon: var(--collaboration2);
  --banner-text: var(--collaboration1);
}

.pcra-log-container-root.PCRA {
  --box-count: 4;
}

.pcra-log-container-root.PCA {
  --box-count: 3;
}

.pcra-log-container {
  display: grid;
  min-width: 400px;
  min-height: 400px;
  align-items: center;
  justify-content: center;
}

.pcra-banner {
  display: grid;
  width: var(--container-outer-width);
  max-width: var(--container-outer-width-max);
  padding: var(--container-padding-quarter) var(--container-padding-half);
  align-items: center;
  justify-items: center;
  border-bottom: var(--border-thin) dashed lightgray;
  background-color: var(--banner-back);
}

.pcra-banner-content {
  display: grid;
  grid-template-columns: 24px auto;
  grid-column-gap: 10px;
  align-items: center;
  justify-items: center;
  text-align: center;
}

.pcra-banner i {
  color: var(--banner-icon);
}

.pcra-banner .text {
  color: var(--banner-text);
}

.pcra-log-header {
  display: grid;
  width: var(--container-width);
  grid-template-columns: repeat(var(--box-count), 1fr);
  grid-column-gap: var(--gap-width);
  padding: var(--container-padding-half) 0;
  margin: 0 var(--container-padding-half);
  align-items: center;
  justify-items: center;
  border-bottom: var(--border-thin) dashed lightgray;
}

.pcra-stage-label {
  display: flex;
  width: var(--label-width);
  height: var(--label-height);
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
}

.pcra-stage-label.PRODUCE {
  background-color: var(--label-PRODUCE);
}

.pcra-stage-label.CHECK {
  background-color: var(--label-CHECK);
}

.pcra-stage-label.REVIEW {
  background-color: var(--label-REVIEW);
}

.pcra-stage-label.APPROVE {
  background-color: var(--label-APPROVE);
}

.pcra-journeys {
  display: grid;
  padding: var(--container-padding-half) 0;
  max-height: 760px;
  overflow-y: scroll;
}

.pcra-journey {
  display: grid;
  grid-template-columns: repeat(var(--box-count), 1fr);
  justify-items: center;
}

.pcra-journey-entry {
  display: grid;
}

.pcra-log-entry {
  display: grid;
  grid-template-rows: auto var(--label-height);
  grid-template-columns: repeat(2, 1fr);
  margin: 0 var(--container-padding-half);
}

.pcra-log-entry.PASSED,
.pcra-log-entry.FAILED,
.pcra-log-entry.PENDING {
  grid-template-areas: "log-box log-box" "log-status log-status";
}

.pcra-log-entry.CURRENT {
  grid-template-areas: "log-box log-box" "log-action-left log-action-right";
}

.pcra-log-box {
  grid-area: log-box;
  display: grid;
  grid-template-areas: "comments comments" "username timestamp" "email email";
  grid-template-rows: 1fr repeat(2, var(--gap-width));
  width: var(--log-box-width);
  height: var(--log-box-height);
  border: var(--border-thick) solid var(--neutral);
  border-radius: var(--border-radius-TL-TR);
}

.pcra-log-entry.PASSED > .pcra-log-box {
  border-color: var(--passed);
}

.pcra-log-entry.FAILED > .pcra-log-box {
  border-color: var(--failed);
}

.pcra-log-entry.PENDING > .pcra-log-box {
  border-style: dashed;
  border-bottom-color: transparent;
}

.pcra-log-box > textarea.comments {
  resize: none;
  border: none;
  background-color: transparent;
  outline: none;
}

.pcra-log-box > textarea.comments::placeholder {
  font-style: italic;
  font-size: small;
  color: var(--collaboration2);
}

.pcra-log-box > .comments {
  grid-area: comments;
  overflow: hidden;
  padding: var(--internal-padding-half) var(--internal-padding);
  padding-right: calc(var(--internal-padding) + var(--scroll-bar-width));
  border: var(--border-thin) solid transparent;
  border-radius: var(--border-radius-TL-TR);
  font-size: small;
}

.pcra-log-box > .comments:hover {
  padding-right: var(--internal-padding);
  overflow-x: hidden;
  overflow-y: scroll;
}

.pcra-log-entry.PENDING > .pcra-log-box > .comments:hover {
  overflow: hidden;
}

.pcra-log-box > .username {
  grid-area: username;
  display: grid;
  padding: var(--internal-padding);
  align-content: center;
  font-size: small;
  font-weight: bold;
  border-top: var(--border-thin) solid var(--neutral);
}

.pcra-log-entry.PASSED > .pcra-log-box > .username {
  border-color: var(--passed);
}

.pcra-log-entry.FAILED > .pcra-log-box > .username {
  border-color: var(--failed);
}

.pcra-log-entry.PENDING > .pcra-log-box > .username {
  border-color: transparent;
}

.pcra-log-box > .timestamp {
  grid-area: timestamp;
  display: grid;
  height: var(--gap-width);
  padding: var(--internal-padding);
  align-content: center;
  font-size: x-small;
  text-align: right;
  border-top: var(--border-thin) solid var(--neutral);
}

.pcra-log-entry.PASSED > .pcra-log-box > .timestamp {
  border-color: var(--passed);
}

.pcra-log-entry.FAILED > .pcra-log-box > .timestamp {
  border-color: var(--failed);
}

.pcra-log-entry.PENDING > .pcra-log-box > .timestamp {
  border-color: transparent;
}

.pcra-log-box > .email {
  grid-area: email;
  display: grid;
  padding: var(--internal-padding);
  align-content: center;
  font-size: small;
  text-align: left;
  border-top: 1px dashed transparent;
}

.pcra-log-entry.PASSED > .pcra-log-box > .email {
  border-top-color: var(--passed);
}

.pcra-log-entry.FAILED > .pcra-log-box > .email {
  border-top-color: var(--failed);
}

.pcra-log-entry.CURRENT > .pcra-log-box > .email {
  border-top-color: var(--neutral);
}

.pcra-log-entry.PENDING > .pcra-log-box > .email {
  border-top-color: none;
}

.pcra-log-entry > i {
  display: grid;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: var(--neutral);
  border: var(--border-thick) solid var(--neutral);
  border-top: transparent;
}

.pcra-log-entry.PASSED > i,
.pcra-log-entry.FAILED > i,
.pcra-log-entry.PENDING > i {
  grid-area: log-status;
  border-radius: var(--border-radius-BL-BR);
}

.pcra-log-entry.PASSED > i {
  background-color: var(--passed);
  border-color: var(--passed);
}

.pcra-log-entry.FAILED > i {
  background-color: var(--failed);
  border-color: var(--failed);
}

.pcra-log-entry.PENDING > i {
  color: var(--neutral);
  background-color: transparent;
  border-style: dashed;
}

.pcra-log-entry.CURRENT > i {
  cursor: pointer;
}

.pcra-log-entry.CURRENT > i:first-of-type {
  grid-area: log-action-left;
  border-radius: var(--border-radius-BL);
}

.pcra-log-entry.CURRENT > i:last-of-type {
  grid-area: log-action-right;
  border-radius: var(--border-radius-BR);
}

.pcra-log-entry.CURRENT > i.fa-times {
  color: var(--failed);
}

.pcra-log-entry.CURRENT > i.fa-times:hover,
.pcra-log-entry.CURRENT > i.fa-times:active {
  color: white;
  background-color: var(--failed);
  border-color: var(--failed);
}

.pcra-log-entry.CURRENT > i.fa-times:hover ~ .pcra-log-box,
.pcra-log-entry.CURRENT > i.fa-times:active ~ .pcra-log-box,
.pcra-log-entry.CURRENT > i.fa-times:hover ~ .pcra-log-box > .username,
.pcra-log-entry.CURRENT > i.fa-times:active ~ .pcra-log-box > .username,
.pcra-log-entry.CURRENT > i.fa-times:hover ~ .pcra-log-box > .timestamp,
.pcra-log-entry.CURRENT > i.fa-times:active ~ .pcra-log-box > .timestamp {
  border-color: var(--failed);
}

.pcra-log-entry.CURRENT > i.fa-check {
  color: var(--passed);
}

.pcra-log-entry.CURRENT > i.fa-check:hover,
.pcra-log-entry.CURRENT > i.fa-check:active {
  color: white;
  background-color: var(--passed);
  border-color: var(--passed);
}

.pcra-log-entry.CURRENT > i.fa-check:hover ~ .pcra-log-box,
.pcra-log-entry.CURRENT > i.fa-check:active ~ .pcra-log-box,
.pcra-log-entry.CURRENT > i.fa-check:hover ~ .pcra-log-box > .username,
.pcra-log-entry.CURRENT > i.fa-check:active ~ .pcra-log-box > .username,
.pcra-log-entry.CURRENT > i.fa-check:hover ~ .pcra-log-box > .timestamp,
.pcra-log-entry.CURRENT > i.fa-check:active ~ .pcra-log-box > .timestamp {
  border-color: var(--passed);
}

.pcra-log-status-bar {
  display: grid;
  grid-template-columns: 1fr 10px 1fr;
  grid-column-gap: 15px;
  width: 100%;
  height: var(--label-height);
  align-items: center;
}

.status-line {
  display: grid;
  border-bottom: 2px solid transparent;
  border-radius: 50%;
}

.status-line.NONE {
  border-color: transparent;
}

.status-line.PENDING {
  border-color: var(--neutral);
}

.status-line.PASSED {
  border-color: var(--passed);
}

.status-line.FAILED {
  border-color: var(--failed);
}

.status-dot {
  display: grid;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.status-dot.PENDING {
  background-color: var(--neutral);
}

.status-dot.CURRENT {
  background-color: var(--current);
}

.status-dot.PASSED {
  background-color: var(--passed);
}

.status-dot.FAILED {
  background-color: var(--failed);
}

.pcra-journey-continuity {
  display: grid;
  grid-template-columns: repeat(var(--box-count), 1fr);
  height: var(--label-height);
  align-items: center;
}

.status-connector {
  display: grid;
  height: 0;
  border: 2px solid var(--failed);
}

.status-connector.begin {
  align-self: flex-start;
  justify-self: right;
  width: calc(50% + 1px);
  height: 21px;
  border-top: none;
  border-right: none;
  border-bottom-left-radius: 8px;
}

.status-connector.continue {
  border-top: none;
  border-left: none;
  border-right: none;
}

.status-connector.end {
  align-self: flex-end;
  justify-self: left;
  width: calc(50% + 1px);
  height: 21px;
  border-bottom: none;
  border-left: none;
  border-top-right-radius: 8px;
}

.status-connector.short {
  align-self: flex-start;
  justify-self: left;
  width: calc(50% + 1px);
  height: 40px;
  border-bottom: none;
  border-left: none;
  border-top: none;
}

.pcra-show-past-journeys-toggle {
  display: grid;
  grid-template-columns: 40px 32px 1fr 72px;
  width: var(--container-width);
  padding: var(--container-padding-half) 0;
  margin: 0 var(--container-padding-half);
  margin-bottom: 10px;
  align-items: center;
  justify-items: center;
  border: 1px solid var(--neutral);
  color: var(--collaboration2);
  background-color: rgba(245, 245, 245, 0.5);
  border-radius: var(--border-radius);
  cursor: pointer;
}

.pcra-show-past-journeys-toggle:hover,
.pcra-show-past-journeys-toggle:active {
  background-color: var(--collaboration4);
}

.pcra-show-past-journeys-toggle > .icon-text {
  font-size: small;
  text-transform: uppercase;
}

.pcra-show-past-journeys-toggle > .display-text {
  color: var(--collaboration1);
  font-size: small;
  font-weight: bold;
  text-transform: uppercase;
}
