.report-style-class {
  height: 400px;
  width: 800px;
}

.export-embedded-report-select {
  width: 435px;
  height: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.project-stages-phase-button {
  width: 435px;
  height: 30px;
  margin: 10px;
}

.report-container {
  display: flex;
  flex-direction: row;
  justify-content: center;

  margin-top: 15px;
}

.export-embedded-report-input-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 5px;
}

.export-report-date-container {
  display: flex;
  flex-direction: row;
  width: max-content;
  border: 1px black solid;
}

.export-report-date-container-row1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--collaboration4);
  border: 1px black solid;
  font-weight: bold;
  width: 150px;
}

.export-report-date-container-row2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px black solid;
  padding-left: 10px;
  padding-right: 10px;
}

.export-report-export-text {
  color: var(--heritage);
  font-weight: bold;
  display: inline;
}

.export-report-view-text {
  color: var(--fluidity1);
  font-weight: bold;
  display: inline;
}

.export-report-date-text {
  color: var(--collaboration1);
  font-weight: bold;
  display: inline;
}

.report-view-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: white;
  background-color: var(--fluidity1);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-size: 15px;

  width: 150px;
  height: 30px;

  user-select: none;
}

.report-view-button:hover {
  background-color: var(--fluidity2);
  cursor: pointer;
}

.report-export-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: white;
  background-color: var(--heritage);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-size: 15px;

  width: 150px;
  height: 30px;

  user-select: none;
}

.report-export-button:hover {
  background-color: var(--heritage2);
  cursor: pointer;
}

.report-export-exporting {
  display: flex;
  flex-direction: row;
}

.report-export-exporting-text {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  height: 30px;
}

.report-export-introduction {
  margin-bottom: 10px;
}
