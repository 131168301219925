/* Key Decisions */
/* 800 - 30 (delete button) - 15 - 15 (gap) = 740 */
.advice-decision {
  width: 600px;
}

.advice-required-by {
  width: 200px;
}

.advice-glossary {
  width: 370px;
}

.advice-title {
  margin-bottom: 15px;
  width: 900px;
}

.advice-decisions-field-container {
  max-height: 225px;
  overflow-y: scroll;
}
