/* .benchmark-view-table-container {
  width: 100%;
  max-height: 500px;
  overflow-y: auto;
}

.benchmark-view-table {
  width: 100%;
  border-collapse: collapse;
}

.benchmark-view-table-header {
  background-color: #f2f2f2;
  padding: 10px;
  text-align: left;
}

.benchmark-view-table td,
.benchmark-view-table th {
  padding: 10px;
  border: 1px solid #ddd;
  vertical-align: top;
}

.benchmark-view-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.benchmark-view-table tr:hover {
  background-color: #f5f5f5;
}

.pctm-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.pctm-row-head {
  display: flex;
  flex-direction: row;
}

.pctm-value-cell-head {
  flex: 1;
  font-weight: bold;
  padding: 10px;
  border: 1px solid #ddd;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pctm-row {
  display: flex;
  flex-direction: row;
}

.pctm-value-cell {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pctm-container-horizontal {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.pctm-row-head-text {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pctm-value-head-text {
  font-weight: bold;
}

.pctm-row .pctm-value-cell-text {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
 */

.tabPanel {
  padding: 1rem;
}

.bm-full-width-box-container {
  width: calc(1200px);
}

.bm-tab-box-container {
  border-bottom: 1px solid #ddd;
}

.pctm-row .pctm-value-cell-text {
  height:100%
}

.basic-benchmark-container{
  height: auto;
}
.basic-benchmark-container .create-project-location-wrapper{
  display:block; 
}
.basic-benchmark-container .create-project-panel{
  height:auto;
  width:225px !important;
}
.basic-benchmark-container .bm-full-width-box-container{
  width:auto;
}

.basic-benchmark-footer{
  margin-top: 10px;
  margin-left:15%;
  position:relative;  
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}