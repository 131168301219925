.information-background {
  height: 100vh;
  overflow-y: auto;
}

.information-content {
  display: flex;
  flex-direction: column;
  padding-top: 70px;
  padding-bottom: 70px;

  width: 100%;
  justify-content: space-between;

  z-index: 1;
}

.information-brand {
  height: 130px;
}

.information-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: white;

  opacity: 1;

  margin: 0 auto;
}

.information-box {
  background: rgb(64, 65, 67, 0.8);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 3);
  height: 338px;
  width: 600px;
  margin: 20px;
  padding: 10px;
}

.information-box2 {
  background: rgb(64, 65, 67, 0.8);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 3);
  height: 338px;
  width: 600px;
  margin: 20px;
}

.information-box3 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 338px;
  width: 600px;
  margin: 20px;
}

.information-image {
  border-radius: 20px;
  height: 307px;
  width: 657px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 3);
}

.information-label {
  margin: 0 auto;
  margin-bottom: 30px;
}

.information-button {
  background-color: var(--collaboration2);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border: none;
  color: white;
  text-align: center;
  font-size: 16px;
  width: 280px;
  height: 50px;
  line-height: 50px; /* same as height! */
  margin: 0 auto;
  margin-bottom: 10px;
}

.information-button:hover {
  color: white;
  background-color: var(--heritage);
  text-decoration: none;
}

.information-text-container {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 13px;
  margin-top: 30px;
}

.information-text-dot-points {
  font-size: 13px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.information-button:focus {
  box-shadow: none;
}

.information-text-dot-points ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.information-text-dot-points li {
  padding-left: 1em;
  text-indent: -0.7em;
}

.information-text-dot-points li::before {
  content: "• ";
  color: var(--heritage);
  font-size: 19px;
  margin-right: 10px;
  margin-left: 5px;
}

.blurb-text-container {
  display: flex;
  flex-direction: row;
  padding-top: 12px;
}

.left-quote {
  color: var(--heritage);
  font-size: 30px;
  margin-left: 6px;
  margin-top: -15px;
}

.right-quote {
  color: var(--heritage);
  font-size: 30px;
  margin-top: -40px;
  margin-left: 110px;
}

.quote-text {
  margin-left: 10px;
}
