/* TableContextMenu */
.table-options-menu-container {
  font-family: sans-serif;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  gap: 20px;
  z-index: 10;
  margin-top: 10px;
  width: inherit;
  height: inherit;
}

.title {
  font-size: 14px;
  margin-bottom: 10px;
}

.table-options-menu-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 20px;
  width: 400px;
  background-color: white;
  max-height: 250px;
  height: auto;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
}

.checked-item,
.not-checked-item {
  margin-left: 10px;
  margin-bottom: 20px;
  text-align: center;
  vertical-align: center;
  font-weight: normal;
}

.checked-item {
  font-weight: bold;
}

.table-options-menu-button .menu-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration1);
  color: white;
  margin-left: 17px;
  height: 30px !important;
  width: 30px;
  padding: 8px;
  border: none;
  flex-shrink: 0;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 100px;
  z-index: 100;
}

.table-options-menu-button .menu-icon:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.menu-icon .icon-container {
  color: white;
}

.table-options-checkbox {
  justify-content: center;
  align-items: center;
  border-right: 1px solid rgb(228, 228, 228);
  padding: 5px;
  border: none;
  background: none;
  accent-color: var(--heritage);
}

.table-options-menu-save {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: white;
  background-color: rgb(13, 166, 66);
  background-color: var(--natural1);

  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 15px;
  width: 150px;
  height: 30px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0px;
}

.table-options-menu-footer {
  align-self: center;
  padding: 10px 0px;
}

.table-options-menu-body {
  display: flex;
  flex-direction: column;
  background-color: white;
}

.table-menu-options-container {
  width: 150px;
  height: 30px;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.table-menu-options-error {
  color: var(--strength1);
}

.table-menu-options-success {
  color: var(--natural1);
}

.table-options-cog-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration2);
  color: white;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  margin-left: 5px;
  position: absolute;
  border-width: 0px;
}

.table-options-cog-menu:hover {
  background-color: var(--heritage);
}

.cog-menu-icon {
  width: 50px;
  height: 50px;
  color: var(--natural1);
}