.project-control-notify {
  padding: 15px;
}

.notify-team-message {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  font-size: 20px;
  color: var(--natural1);
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

.notify-team-container {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.notify-team-textarea {
  width: 100%;
  min-height: 100px;
  max-height: 100px;
}

.notify-team-container .notify-team-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: white;
  background-color: var(--natural1);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-size: 15px;

  width: 150px;
  height: 30px;

  user-select: none;
}

.notify-team-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}
