/* BUTTONS */

.value-engineering-upload-modal-button {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: var(--collaboration1);
    color: white;
    padding: 5px;
    width: 150px;
    height: 35px;
    border: none;
    text-align: center;
  
    font-size: 13px;
    z-index: 200;
  
    border-radius: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  
    margin-bottom: 15px;
  }
  
  .value-engineering-upload-modal-button:hover {
    background-color: var(--heritage);
    cursor: pointer;
  }
  .field-name{
    margin-right: 10px;
  }
  .row-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-height: 30px;
    margin-bottom:5px;
  }
  