.toggle-control-switch-container {
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}

.toggle-control-switch {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 50px;
  height: 24px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 10px;
}

.toggle-control-switch-label {
  margin-right: auto;
}

.toggle-control-switch-label.disabled {
  color: var(--collaboration2);
}

.toggle-control-switch > input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-control-switch > input:checked + .slider {
  background-color: var(--heritage);
}

.toggle-control-switch > input:disabled + .slider,
.toggle-control-switch > input:checked:disabled + .slider {
  background-color: var(--collaboration3);
}

.toggle-control-switch > input:focus + .slider {
  box-shadow: 0 0 1px var(--heritage);
}
