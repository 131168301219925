.estimates-TPI {
  display: flex;
  flex-direction: column;
}

.estimate-wrapper.breakdown .estimates-TPI {
  align-items: flex-start;
}

.estimate-wrapper.summary .estimates-TPI {
  align-items: flex-end;
}

.estimates-TPI-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 1000px;
}

.estimates-TPI-kpx-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 1000px;
}

.estimates-TPI-container {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  width: 1000px;
  height: 40px;
  background-color: var(--collaboration1);
  color: var(--integrity);

  margin-bottom: 20px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.estimates-breakdown-TPI-container {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  width: 1430px;
  height: 40px;
  background-color: var(--collaboration1);
  color: var(--integrity);

  margin-bottom: 20px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.estimates-TPI-container-total {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  width: 1000px;
  height: 40px;
  background-color: var(--collaboration1);
  color: var(--integrity);

  margin-bottom: 30px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.estimates-breakdown-TPI-container-total {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  width: 1430px;
  height: 40px;
  background-color: var(--collaboration1);
  color: var(--integrity);

  margin-bottom: 30px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.estimates-TPI-save-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: inline-block;
  background-color: var(--collaboration1);
  color: white;
  height: 30px !important;
  width: 30px;
  border: none;
  text-align: center;
  flex-shrink: 0;
  margin-left: 17px;
  border-radius: 100px;
}

.estimates-TPI-save-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.estimates-TPI-description {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 570px;
  border-right: 1px solid var(--collaboration3);

  height: 40px;
}

.estimates-breakdown-TPI-description {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 1020px;
  border-right: 1px solid var(--collaboration3);

  height: 40px;
  font-weight: bold;
}

.estimates-TPI-icon {
  width: 60px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.estimates-TPI-icon.linkedTPI:hover,
.estimates-TPI-icon.delinkedTPI {
  background-color: var(--collaboration3);
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  box-shadow: none;
  color: var(--confident);
}

.estimates-TPI-icon.linkedTPI,
.estimates-TPI-icon.delinkedTPI:hover {
  background-color: var(--heritage);
  /* box-shadow: 0 4px 8px 0 var(--strength2), 0 6px 20px 0 var(--strength2); */
  box-shadow: none;
}

.estimates-TPI-label {
  margin-left: 10px;
  margin-right: 10px;
}

.estimates-TPI-label.disabled {
  color: var(--collaboration2);
}

.estimates-TPI-value {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: space-evenly;
  width: 100px;
  border-right: 1px solid var(--collaboration3);

  height: 40px;
}

input.estimates-TPI-value[readonly] {
  background-color: var(--collaboration3);
  cursor: not-allowed;
}

.estimates-TPI-unit {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 65px;
  border-right: 1px solid var(--collaboration3);

  height: 40px;
}

.estimates-TPI-unit.disabled {
  color: var(--collaboration2);
}

.estimates-TPI-construction-cost {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  border-right: 1px solid var(--collaboration3);

  height: 40px;
}

.estimates-TPI-total {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 125px;
  height: 40px;
  padding-right: 10px;
}

.estimates-TPI-divider {
  width: 1px;
  height: 40px;
  background-color: var(--collaboration4);
}

.estimates-tpi-slider-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.estimates-tpi-slider {
  -webkit-appearance: none;
  appearance: none;
  width: 220px;
  height: 10px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border-radius: 100px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.estimates-tpi-slider:hover {
  opacity: 1;
}

.estimates-tpi-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: var(--heritage);
  cursor: pointer;
  border-radius: 100px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.estimates-tpi-slider::-moz-range-thumb {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  width: 25px;
  height: 25px;
  background: var(--heritage);
  cursor: pointer;
  border-radius: 100px;
}

.estimates-TPI-save-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 220px;
  height: 40px;
}
