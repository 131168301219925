/* Project Dashboard */

.graph-title {
  font-size: 20px;
}

.doughnut-chart-item-project-dashboard {
  height: 235px;
  margin-top: -30px;
}

.doughnut-chart-total-project-dashboard-container {
  display: flex;
  flex-direction: column;
  margin-top: 90px;
}

.doughnut-chart-total-project-dashboard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 256px;

  margin-top: 70px;
  margin-left: 24px;
}

/* Cost Planning Dashboard */

.doughnut-chart-container {
  display: flex;
  flex-direction: column;
}

.doughnut-chart-box-cp-dashboard1 {
  width: 400px;
  height: 390px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: white;

  padding-left: 10px;
  padding-top: 10px;
  margin-bottom: 30px;

  display: flex;
  flex-direction: column;
}

.doughnut-chart-box-cp-dashboard2 {
  width: 400px;
  height: 430px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: white;

  padding-left: 10px;
  padding-top: 10px;
  margin-bottom: 30px;

  display: flex;
  flex-direction: column;
}

.doughnut-chart-item {
  height: 300px;
}

.doughnut-chart-item2 {
  height: 350px;
}

.doughnut-chart-total {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 313px;
  margin-left: 43px;
}

.doughnut-chart-total-label,
.doughnut-chart-total-value {
  font-size: 12px;
  letter-spacing: 0.5px;
}

.project-dashboard-cost-summary-graph {
  display: flex;
  flex-direction: row;
}

.costplanning-chart-box-cp-dashboard2 {
  display: flex;
  justify-content: space-between;
  margin-right: 5px;
}

.costplanning-toggle-chart-box {
  margin-bottom: 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: white;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 20px;
}

.costplanning-chart-box-disabled {
  display: none;
}

.costplanning-chart-box-hidden {
  margin-bottom: 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration4);
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 20px;
}
