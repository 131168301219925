/* Form */
.edit-estimate {
  width: 600px;
  padding: 15px;
}

.edit-estimate-label {
  width: 200px;
}

.edit-estimate-field {
  width: 350px;
}

.edit-estimate-container {
  display: flex;
  align-items: center;
  height: 30px;
  margin-bottom: 15px;
}

.estimate-edit-wrapper {
  height: min-content;
}
.allocate-budget-modal-error {
  color: var(--heritage);
  justify-content: center;
  display: flex;
  margin-bottom: 10px;
}
