.create-project {
  margin-top: 15px;
  margin-bottom: 15px;

  width: 1440px;
}

/* Form */

.create-project-input-row {
  margin-bottom: 1px;
}

.create-project-input {
  display: flex;
  width: 100%;
  height: 30px;
  border: 1px solid lightgrey;
}

.create-project-location-input {
  display: flex;
  width: 205px;
  height: 30px;
  border: 1px solid lightgrey;
}

.create-project-select {
  display: flex;
  width: 100%;
  height: 30px;
  border: 1px solid lightgrey;
}

.create-project-location-select {
  display: flex;
  width: 205px;
  height: 30px;
  border: 1px solid lightgrey;
}

.create-project-input-limit {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}

.invalid-char-counter {
  color: var(--strength1);
  margin-left: 5px;
}

.char-counter {
  color: var(--natural1);
  margin-left: 5px;
}

.create-project-modal-error {
  color: var(--heritage);
}

/* Buttons */

.create-project-submit-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: white;
  background-color: var(--natural1);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-size: 15px;

  width: 150px;
  height: 30px;

  border: none;
}

.create-project-submit-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.create-project-submit-button:disabled {
  background-color: var(--collaboration2);
}

.create-project-modal-button {
  background-color: var(--natural1);
  color: var(--integrity);
  height: 30px;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: max-content;
  margin: 25px 0;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  border: none;
  outline: inherit;
}

.create-project-modal-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.create-project-coordinates-button {
  background-color: var(--natural1);
  color: var(--integrity);
  height: 30px;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: max-content;
  min-width: 205px;
  margin-top: 30px;
  margin-bottom: 10px;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  border: none;
  outline: inherit;
}

.create-project-coordinates-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

/* PANELS */

.create-project-form {
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.create-project-panel-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-bottom: 15px;
}

.create-project-panel {
  background-color: rgba(248, 249, 250, 1);
  width: 450px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 10px;

  transition: all 1s;

  margin-left: 15px;
  margin-right: 15px;

  height: 650px;
  overflow-y: auto;
}

.create-project-panel:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

/* Modal Layout */

.create-project-col {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.create-project-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.create-project-label {
  height: 30px;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.create-project-label .display-4 {
  font-size: 20px;
  margin-bottom: 0px;
}

/* AVATAR */

.create-project-avatar {
  box-shadow: 0 4px 8px 0 rgba(19, 14, 14, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);

  border-radius: 100px;
  width: 30px;
  height: 30px;
  margin-left: 20px;
  margin-right: 10px;
}

.create-project-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
  margin-left: 5px;
}

.create-project-selection-number {
  font-size: 15px;
  color: var(--natural1);
  margin-left: 20px;
}

/* SAVE */

.create-project-save-cost-managers-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--natural1);
  color: var(--integrity);

  height: 30px;
  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;

  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.create-project-save-cost-managers-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.create-project-company-logo-and-name {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px 0 10px 10px;
  border-left: 1px solid var(--collaboration4);
  padding-left: 10px;
}

.create-project-company-logo {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.create-project-company-logo > img {
  height: 30px;
}

.create-project-company-name {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: 20px;
  font-size: 8pt;
}

/* MAP */

.create-project-location-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.create-project-location-container {
  width: 100px;
  margin-top: 45px;
}

.create-project-map {
  margin-left: 10px;
  margin-top: 20px;
  margin-bottom: 15px;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  height: 200px;
  width: 310px;
}
