.benchmark-create-load-or-save-modal-wrapper {
  display: flex;
  flex-direction: row;
  --content-pane-height: 800px;
  --footer-height: 50px;
  --full-height: calc(var(--content-pane-height) + var(--footer-height));
  --list-section-heading-height: 30px;
  --list-item-height: 50px;
}

.benchmark-create-load-or-save-modal-wrapper button {
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.benchmark-create-load-or-save-modal-wrapper input::placeholder {
  color: var(--collaboration2);
  font-size: 16px;
}

.benchmark-create-load-or-save-modal-wrapper input:focus::placeholder {
  color: transparent;
}

.benchmark-create-load-or-save-modal-wrapper input:disabled {
  background-color: transparent;
}

.benchmark-create-load-or-save-modal-wrapper p.message {
  color: var(--heritage);
}

.benchmark-create-load-or-save-modal-wrapper .button {
  margin: 0px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
}

.benchmark-create-load-or-save-modal-wrapper .button > span.text {
  padding-left: 10px;
}

.benchmark-create-load-or-save-modal-wrapper .button.unavailable {
  background-color: var(--collaboration3);
  color: var(--collaboration1);
}

.benchmark-create-load-or-save-modal-wrapper .actions-panel {
  display: flex;
  flex-direction: row;
  padding: 20px 0;
}

.benchmark-create-load-or-save-modal-left-pane {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  flex-direction: column;
  width: 300px;
  font-size: 13px;
  overflow-y: hidden;
  max-height: var(--full-height);
}

.benchmark-create-load-or-save-modal-left-pane.loading {
  align-items: center;
  justify-content: center;
}

.benchmark-create-load-or-save-modal-left-pane-list {
  --size: var(--content-pane-height);
  min-height: var(--size);
  max-height: var(--size);
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 13px;
  overflow-y: hidden;
  margin-bottom: auto;
  gap: 30px;
}

.benchmark-create-load-or-save-modal-left-pane-list-section {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.benchmark-create-load-or-save-modal-left-pane-list-section.current-selection {
  --section-color: var(--heritage);
}

.benchmark-create-load-or-save-modal-left-pane-list-section.previously-saved {
  --section-color: var(--collaboration1);
  height: calc(100% - 110px);
}

.benchmark-create-load-or-save-modal-left-pane-list-section
  > .list-section-heading {
  padding: 5px 15px;
  color: var(--section-color);
  text-transform: uppercase;
  font-weight: bold;
  height: var(--list-section-heading-height);
  display: flex;
  align-items: center;
}

.benchmark-create-load-or-save-modal-left-pane-list-section
  > .list-section-items {
  display: flex;
  flex-direction: column;
}

.benchmark-create-load-or-save-modal-left-pane-list-section.previously-saved
  > .list-section-items {
  overflow-y: auto;
}

.benchmark-create-load-or-save-modal-list-item {
  min-height: var(--list-item-height);
  max-height: var(--list-item-height);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: var(--collaboration1);
  padding: 0 10px;
  border: none;
  border-left: 5px solid var(--section-color);
  background-color: transparent;
}

.benchmark-create-load-or-save-modal-list-item:hover,
.benchmark-create-load-or-save-modal-list-item:active,
.benchmark-create-load-or-save-modal-list-item.selected {
  cursor: pointer;
  background-color: var(--collaboration4);
  padding-left: 10px;
}

.benchmark-create-load-or-save-modal-list-item.flag_save_pending {
  --section-color: var(--strength1);
}

.benchmark-create-load-or-save-modal-list-item.flag_marked_for_deletion {
  color: var(--strength1);
  text-decoration: line-through;
}

.benchmark-create-load-or-save-modal-list-item > .list-item-title,
.benchmark-create-load-or-save-modal-list-item > .list-item-value {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.benchmark-create-load-or-save-modal-list-item > .list-item-title {
  margin-right: 5px;
  display: flex;
  justify-content: flex-start;
}

.benchmark-create-load-or-save-modal-list-item > .list-item-value {
  width: 80px;
  text-align: right;
}

.benchmark-create-load-or-save-modal-list-item.list-items-total
  > .list-item-title {
  width: 60px;
}

.benchmark-create-load-or-save-modal-list-item.list-items-total
  > .list-item-value {
  width: 184px;
}

.benchmark-create-load-or-save-modal-right-pane {
  width: 1050px;
  overflow: hidden;
}

.benchmark-create-load-or-save-modal-right-pane-content-forms {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto 1fr;
  gap: 20px;
}

.benchmark-create-load-or-save-modal-right-pane-content-form {
  padding: 20px;
  border: 1px solid var(--collaboration4);
  border-radius: 4px;
}

.benchmark-create-load-or-save-modal-right-pane-content-form.grid-full-width {
  grid-column: -1 / 1;
}

.benchmark-create-load-or-save-modal-right-pane-modal-title {
  grid-column: -1 / 1;
  padding: 10px 0;
  margin: 0;
  margin-top: 10px;
  border-bottom: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.benchmark-create-load-or-save-modal-right-pane-modal-title.editable {
  border-color: var(--heritage);
}

.benchmark-create-load-or-save-modal-right-pane-modal-title.readonly {
  border-color: var(--collaboration4);
}

.benchmark-create-load-or-save-modal-right-pane-modal-title > input.title,
.benchmark-create-load-or-save-modal-right-pane-modal-title > div.title {
  display: flex;
  min-height: 32px;
  width: 100%;
  border: none;
  outline: none;
  font-size: 20px;
  padding-bottom: 10px;
}

.benchmark-create-load-or-save-modal-right-pane-modal-title > i {
  display: flex;
  width: 32px;
}

.benchmark-create-load-or-save-modal-right-pane h1.display-4.form-title {
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--collaboration4);
}

.benchmark-create-load-or-save-modal-right-pane h1.display-4.form-title > i {
  margin: 0;
  margin-right: 10px;
}

.benchmark-create-load-or-save-modal-right-pane-content-form-control {
  display: grid;
  grid-template-columns: 1fr auto;
}

.benchmark-create-load-or-save-modal-list-item-contents {
  --size: var(--content-pane-height);
  min-height: var(--size);
  max-height: var(--size);
  overflow-y: scroll;
  overflow-x: hidden;
  width: 1050px;
  padding: 0 25px 0 30px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.list-item-information {
  --size: var(--content-pane-height);
  min-height: var(--size);
  max-height: var(--size);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  color: var(--collaboration1);
  padding-left: 10px;
}

.list-item-information.display-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
}

.list-item-information > div.text {
  text-align: center;
}

.list-item-information > i {
  color: var(--collaboration4);
  padding-bottom: 20px;
}

.benchmark-create-load-or-save-modal-list-item-contents > .field-row {
  display: flex;
  flex-direction: row;
}

.benchmark-create-load-or-save-modal-list-item-contents > .field-row.spacer {
  min-height: 15px;
}

.benchmark-create-load-or-save-modal-list-item-contents .list-item-field {
  display: flex;
  flex-direction: column;
  padding: 15px;
  width: 100%;
}

.list-item-field > .field-label {
  margin-bottom: 5px;
}

.list-item-field > .field-value {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.list-item-field-icon {
  --size: 30px;
  min-height: var(--size);
  max-height: var(--size);
  min-width: var(--size);
  max-width: var(--size);
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  color: white;
}

.benchmark-create-load-or-save-modal-left-pane-list .list-item-field-icon,
.benchmark-create-load-or-save-modal-list-item.list-items-total
  .list-item-field-icon {
  --size: 24px;
}

.benchmark-create-load-or-save-modal-left-pane-list
  .list-item-field-icon:first-of-type,
.benchmark-create-load-or-save-modal-list-item.list-items-total
  .list-item-field-icon:first-of-type {
  margin-left: 5px;
}

.list-item-field > .field-value > .text {
  width: 100%;
  min-height: 40px;
  border: 1px solid var(--collaboration2);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  color: var(--collaboration1);
}

.list-item-field.readonly > .field-value > .text {
  border: 1px solid var(--collaboration4);
}

.list-item-field.ref {
  width: 150px;
}

.list-item-field.description > .field-value > .text {
  height: 150px;
}

.benchmark-create-load-or-save-modal-footer {
  min-height: var(--footer-height);
  max-height: var(--footer-height);
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid var(--collaboration4);
}

.benchmark-create-load-or-save-modal-footer.left-pane {
  justify-content: center;
}

.benchmark-create-load-or-save-modal-footer.right-pane {
  justify-content: flex-end;
  padding-left: 30px;
  padding-right: 40px;
}

.benchmark-create-load-or-save-modal-footer.right-pane.message-only {
  background-color: var(--collaboration4);
}

.benchmark-create-load-or-save-modal-footer.right-pane > .message {
  margin-left: auto;
}

.benchmark-create-load-or-save-modal-footer.right-pane.message-only > .message {
  font-size: 14px;
  font-weight: bold;
  color: var(--strength1);
}
