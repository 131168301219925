.options-analysis-container .charts-container {
  display: flex;
  flex-direction: row;
  width: 1665px;
  margin-top: 20px;
}

.options-analysis-container .charts-row {
  display: flex;
  flex-direction: row;
  width: 1665px;
}

.options-analysis-container .charts-column {
  display: flex;
  flex-direction: column;
  width: 780px;
}

.charts-column.left {
  margin-right: 15px;
}

.charts-column.right {
  margin-left: 15px;
}

.charts-column.control {
  width: 105px;
}
