.welcome-page {
  border-radius: 20px;
  max-width: 1800px;

  margin: 0 auto;
}

.welcome-image-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  margin-top: 50px;
  margin-bottom: 50px;

  height: 300px;
  width: 750px;

  background-image: url(../../img/backgrounds/background2.jpg);
  border-radius: 20px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: top;
  background-repeat: unset;
  background-size: 100%;
}

.welcome-image {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 400px;
}

.welcome-message {
  text-align: center;
  margin-bottom: 30px;
}

.welcome-sub-message {
  text-align: center;
  margin-bottom: 40px;
}
