.project-budget {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
}

.project-budget-column {
  display: flex;
  flex-direction: column;
}
