a.service-selector-box-link {
  color: black;
  text-decoration: none;
}

.service-selector-box {
  display: flex;
  flex-direction: column;
  width: 400px;
  color: black;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 0px;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.service-selector-box:hover {
  -webkit-transform: scale(1.085);
  -ms-transform: scale(1.085);
  transform: scale(1.085);
}

.service-selector-box > .image {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: 400px;
  height: 400px;
  background-size: 250px;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 0px;
}

.service-selector-box > .text {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 100px;
  font-size: large;
  font-weight: bold;
}
