.pcra-status-summary {
  display: grid;
  width: 270px;
  grid-template-rows: 20px 60px;
  justify-items: center;
  font-size: 16px;
  font-weight: 400;
}

.pcra-status-summary.PCRA {
  width: 270px;
}

.pcra-status-summary.PCA {
  width: 210px;
}

.pcra-status-summary > .arrow-up {
  display: grid;
  align-self: end;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
}

.pcra-status-summary > .summary-bar {
  display: grid;
  width: 100%;
  height: 100%;
  padding: 0 20px 5px 10px;
  border-radius: 4px;
  background: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  grid-template-columns: repeat(7, 1fr);
  grid-column-gap: 5px;
  align-items: center;
  justify-items: center;
}

.pcra-status-summary.PCRA > .summary-bar {
  grid-template-columns: repeat(7, 1fr);
}

.pcra-status-summary.PCA > .summary-bar {
  grid-template-columns: repeat(5, 1fr);
}

.pcra-status-summary .stage-icon {
  display: grid;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 4px;
  color: white;
  text-align: center;
}

.pcra-status-summary .stage-icon > div.text {
  border-radius: 4px;
  margin-bottom: 1px;
}

.pcra-status-summary .stage-icon.PRODUCE > div.text {
  background-color: var(--collaboration3);
}

.pcra-status-summary .stage-icon.CHECK > div.text {
  background-color: var(--collaboration2);
}

.pcra-status-summary .stage-icon.REVIEW > div.text {
  background-color: var(--collaboration1);
}

.pcra-status-summary .stage-icon.APPROVE > div.text {
  background-color: var(--heritage);
}

.pcra-status-summary .stage-icon > i {
  display: grid;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid white;
  position: absolute;
  margin-left: 20px;
  margin-top: 20px;
}

.pcra-status-summary .stage-icon > i.fa-clock {
  background-color: var(--collaboration4);
  color: var(--collaboration1);
}

.pcra-status-summary .stage-icon > i.fa-ellipsis-h {
  background-color: var(--built1);
  color: var(--collaboration1);
}

.pcra-status-summary .stage-icon > i.fa-check {
  background-color: var(--natural1);
  color: white;
}

.pcra-status-summary .stage-connector-line {
  display: grid;
  width: 100%;
  height: 0;
  border: 1px solid transparent;
}

.pcra-status-summary .stage-connector-line.PASSED {
  border-color: var(--natural1);
}

.pcra-status-summary .stage-connector-line.PENDING,
.pcra-status-summary .stage-connector-line.CURRENT {
  border-color: var(--collaboration4);
}
