/* MAIN BACKGROUND */

.portfolio-background {
  display: flex;
  flex-direction: row;

  width: 100%;
  margin: 0 auto;
}

/* BUTTONS AREA */

.portfolio-control-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;

  justify-content: space-between;
}

.portfolio-control-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.portfolio-control-column,
.portfolio-control-column-backer {
  background-color: rgba(248, 249, 250, 1);

  width: 215px;
  height: 815px;
  margin-top: -15px;

  margin-right: 30px;
  border-radius: 0 0 50px 0;
}

.portfolio-control-column-backer {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.portfolio-control-column .create-project-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration1);
  color: white;
  font-size: 13px;
  border: none;
  outline: none;

  margin: 0 auto;

  height: 35px;
  width: 150px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin-bottom: 15px;

  border-radius: 30px;

  user-select: none;
}

.portfolio-control-column .create-project-button:hover {
  background-color: var(--heritage);
  text-decoration: none;
  color: white;
  cursor: pointer;
}

/* PROJECTS AREA */

.portfolio-projects-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.portfolio-box {
  display: flex;
  flex-flow: wrap;
  width: 1460px;
  min-height: 760px;
  margin-top: 20px;
  justify-content: flex-start;
}

.portfolio-title {
  display: flex;
  flex-direction: column;

  margin-bottom: 8px;
}

.portfolio-loading {
  width: 1460px;
  min-height: 760px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
