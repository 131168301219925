.metric-item-item {
  margin: 15px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.metric-item-box1 {
  background: linear-gradient(
    135deg,
    rgb(85, 87, 90) 0%,
    rgb(145, 143, 146) 100%
  );
}
.metric-item-box2 {
  background: linear-gradient(
    135deg,
    rgb(228, 97, 15) 0%,
    rgb(228, 144, 92) 100%
  );
}
.metric-item-box3 {
  background: linear-gradient(
    135deg,
    rgb(50, 98, 149) 0%,
    rgb(101, 137, 176) 100%
  );
}

.metric-item-box1,
.metric-item-box2,
.metric-item-box3 {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  min-width: 135px;
  padding: 30px;
  height: 60px;

  font-size: 20px;
  color: var(--integrity);

  margin-bottom: 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 100px;
}

.metric-item-label {
  font-size: 13px;
}
