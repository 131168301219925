.project-budget-charts {
  display: flex;
  flex-direction: column;
  padding-left: 30px;
}

.project-budget-chart-box {
  margin-bottom: 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: white;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 20px;
  padding-right: 20px;
  width: 500px;
}
