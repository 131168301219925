/* TABLE */

.ebt {
  font-size: 13px;
}

.ebtr-section {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 20px;
}

/* ROWS */

.estimate-breakdown-row-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 34px;
}

.ebt-head-row {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  display: flex;
  flex-direction: row;

  background-color: var(--collaboration2);
  color: var(--integrity);

  width: 1430px;
  min-height: 40px;
}

.ebt-total-row {
  display: flex;
  flex-direction: row;

  background-color: var(--collaboration1);
  color: var(--integrity);

  width: 1430px;
  min-height: 40px;
}

.ebt-subhead-row {
  display: flex;
  flex-direction: row;

  background-color: var(--collaboration3);
  color: var(--confident);

  width: 1430px;
  height: 40px;
}

.ebt-row-head1 {
  font-weight: bold;
  text-decoration: underline;

  display: flex;
  flex-direction: row;

  background-color: lightgrey;
  color: var(--confident);

  width: 1430px;
}

.ebt-row-head2 {
  text-decoration: underline;

  display: flex;
  flex-direction: row;

  background-color: var(--collaboration4);
  color: var(--confident);

  width: 1430px;
}

.ebt-row {
  display: flex;
  flex-direction: row;

  min-height: 40px;

  background-color: var(--integrity);
  color: var(--confident);

  width: 1430px;
}

/* Old Row Types */

.ebt-note-row {
  display: flex;
  flex-direction: row;

  background-color: lightgrey;
  color: var(--confident);

  width: 1430px;
}

/* CELLS */

.ebt-section-cell {
  width: 60px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.ebt-description-cell {
  width: 1000px;
  padding: 10px;
  min-height: 34px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ebt-description-cell-ref {
  width: 940px;
  padding: 10px;
  min-height: 34px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ebt-quantity-cell {
  width: 100px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.ebt-unit-cell {
  width: 65px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.ebt-rate-cell {
  width: 140px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.ebt-subtotal-cell {
  width: 125px;
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;

  align-items: center;

  border-bottom: 1px solid grey;
}

/* BORDERS */

.ebt-description-cell,
.ebt-description-cell-ref,
.ebt-quantity-cell,
.ebt-unit-cell,
.ebt-section-cell,
.ebt-rate-cell,
.ebt-description-cell-filtered {
  border-bottom: 1px solid grey;
  border-right: 1px solid grey;
}

.ebt-total-row .ebt-description-cell,
.ebt-total-row .ebt-description-cell-ref,
.ebt-total-row .ebt-quantity-cell,
.ebt-total-row .ebt-unit-cell,
.ebt-total-row .ebt-section-cell,
.ebt-total-row .ebt-rate-cell,
.ebt-total-row .ebt-subtotal-cell {
  border-bottom: none;
}

/* BUTTONS */

.ebt-view-all-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--collaboration1);
  color: var(--integrity);

  border-radius: 100px;

  height: 25px;
  width: 25px;

  font-size: 11px;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.ebt-view-all-button:hover {
  color: var(--integrity);
  background-color: var(--heritage);
  text-decoration: none;
}

.ebt-view-zoom-out {
  height: 15px;
  width: 15px;
}

.ebt-search {
  height: 19px;
  border: none;
  width: 300px;
}

/* Consoldiated Filtered Comments for Project level  */

.ebt-filtered {
  font-size: 13px;
  max-height: 700px;
  padding: 30px;
  overflow-y: scroll;
}
.ebt-description-cell-filtered {
  width: 500px;
  padding: 10px;
  min-height: 34px;
  display: flex;
  text-align: left;
}
.ebt-row-filtered {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  flex-direction: row;

  min-height: 40px;
  width: 930px;

  background-color: var(--integrity);
  color: var(--confident);
}
.ebt-head-row-filtered {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  display: flex;
  flex-direction: row;

  background-color: var(--collaboration2);
  color: var(--integrity);

  width: 930px;
  min-height: 40px;
}

.estimate-breakdown-plusminus-filtered {
  margin-right: 10px;
  color: var(--confident);
}
