/* TABLE */

.post-contract-cashflow-table {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-right: 30px;
  align-self: flex-start;
}

/* ROWS */

.post-contract-cashflow-table-head-row {
  display: flex;
  flex-direction: row;

  background-color: var(--collaboration2);
  color: var(--integrity);

  width: 700px;
  height: 45px;

  font-size: 15px;
}

.post-contract-cashflow-table-row {
  display: flex;
  flex-direction: row;

  background-color: var(--integrity);
  color: var(--confident);

  width: 700px;
  min-height: 30px;

  font-size: 15px;
}

.post-contract-cashflow-table-total-row {
  display: flex;
  flex-direction: row;

  background-color: var(--collaboration1);
  color: var(--integrity);

  width: 700px;
  height: 30px;
}

/* CELLS */

.post-contract-cashflow-table-description-cell {
  width: 200px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.post-contract-cashflow-table-quantity-cell {
  width: 250px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.post-contract-cashflow-table-unit-cell {
  width: 250px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

/* BORDERS */

.post-contract-cashflow-table-description-cell,
.post-contract-cashflow-table-quantity-cell,
.post-contract-cashflow-table-unit-cell,
.post-contract-cashflow-table-rate-cell {
  border-bottom: 1px solid grey;
  border-right: 1px solid grey;
}

.post-contract-cashflow-table-subtotal-cell {
  border-bottom: 1px solid grey;
}

.post-contract-cashflow-table-total-row
  .post-contract-cashflow-table-description-cell,
.post-contract-cashflow-table-total-row
  .post-contract-cashflow-table-quantity-cell,
.post-contract-cashflow-table-total-row .post-contract-cashflow-table-unit-cell,
.post-contract-cashflow-table-total-row .post-contract-cashflow-table-rate-cell,
.post-contract-cashflow-table-total-row
  .post-contract-cashflow-table-subtotal-cell {
  border-bottom: none;
}
