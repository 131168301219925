.pm-popup {
  margin-bottom: 30px;
  border-radius: 100px;
  text-decoration: none;
}

.pm-popup .leaflet-popup-content-wrapper {
  background-color: rgba(255, 255, 255, 0.7); /* color */
  height: 420px;
  width: 400px;
  padding-top: 20px;
}

.pm-popup .leaflet-popup-tip {
  background-color: rgba(255, 255, 255, 0.7); /* color */
}

.pm-popup-link:hover {
  text-decoration: none;
}
