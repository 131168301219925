.ve-status-cell {
  --neutral: var(--collaboration1);
  --neutral-shadow: rgba(0, 0, 0, 0.2);
  --glow: var(--heritage);
  --glow-shadow: var(--heritage);
  --approved: var(--natural1);
  --approved-shadow: var(--natural1);
  --forreview: var(--heritage2);
  --forreview-shadow: var(--heritage2);
  --rejected: var(--strength1);
  --rejected-shadow: var(--strength1);
  --inactive: var(--collaboration4);
  --inactive-shadow: var(--collaboration4);
  --readonly: var(--collaboration3);
}

.ve-status-cell .box-set {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px;
}

.ve-status-cell .box {
  color: var(--neutral);
  background-color: white;
  box-shadow: 0 4px 8px 0 var(--neutral-shadow),
    0 6px 20px 0 var(--neutral-shadow);

  --size: 24px;
  min-height: var(--size);
  max-height: var(--size);
  min-width: var(--size);
  max-width: var(--size);

  padding: 5px;
  margin: 0 10px;
  border-radius: 50%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  user-select: none;

  transition: linear 0.2s;
}

.ve-status-cell .box.not-selected {
  color: var(--neutral);
  background-color: white;
}

.ve-status-cell .box.selected,
.ve-status-cell .box:hover,
.ve-status-cell .box:active {
  color: white;
}

.ve-status-cell .box.approved.selected,
.ve-status-cell .box.approved:not(.readonly):hover,
.ve-status-cell .box.approved:not(.readonly):active {
  background-color: var(--approved);
  box-shadow: 0 4px 8px 0 var(--approved-shadow),
    0 6px 20px 0 var(--approved-shadow);
}

.ve-status-cell .box.forreview.selected,
.ve-status-cell .box.forreview:not(.readonly):hover,
.ve-status-cell .box.forreview:not(.readonly):active {
  background-color: var(--forreview);
  box-shadow: 0 4px 8px 0 var(--forreview-shadow),
    0 6px 20px 0 var(--forreview-shadow);
}

.ve-status-cell .box.rejected.selected,
.ve-status-cell .box.rejected:not(.readonly):hover,
.ve-status-cell .box.rejected:not(.readonly):active {
  background-color: var(--rejected);
  box-shadow: 0 4px 8px 0 var(--rejected-shadow),
    0 6px 20px 0 var(--rejected-shadow);
}

.ve-status-cell .box.readonly.not-selected {
  background-color: white;
  color: var(--readonly);
}

.ve-status-cell .box.readonly.selected,
.ve-status-cell .box.readonly.approved,
.ve-status-cell .box.readonly.forreview,
.ve-status-cell .box.readonly.rejected {
  cursor: auto;
  box-shadow: none;
}
