/* Risks Advice */

.risks-advice-display-area {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.risks-advice-table {
  display: flex;
  flex-direction: column;
}

.risks-advice-row {
  font-size: 11px;
  border-bottom: 1px lightgrey solid;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.risks-advice-label {
  width: 365px;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-right: 10px;
  border-right: 1px lightgrey solid;
}

.risks-advice-level {
  width: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.risks-advice-bullet {
  box-shadow: 0 2px 4px 0 var(--heritage), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  height: 5px;
  width: 5px;
  margin-right: 15px;
  margin-left: 15px;

  background-color: var(--heritage);

  border-radius: 100px;
}

/* Risk Levels */

.risks-advice-urgency-high {
  background-color: var(--heritage);
  box-shadow: 0 2px 4px 0 var(--heritage), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  justify-content: center;
  align-items: center;
  display: flex;
  color: white;
  width: 60px;
  height: 20px;
  border-radius: 100px;
}

.risks-advice-urgency-medium {
  background-color: rgb(218, 144, 98);
  box-shadow: 0 2px 4px 0 rgb(218, 144, 98), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  justify-content: center;
  align-items: center;
  display: flex;
  color: white;
  width: 60px;
  height: 20px;
  border-radius: 100px;
}

.risks-advice-urgency-low {
  background-color: var(--collaboration3);
  box-shadow: 0 2px 4px 0 var(--collaboration3), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  justify-content: center;
  align-items: center;
  display: flex;
  color: white;
  width: 60px;
  height: 20px;
  border-radius: 100px;
}

/* Portfolio Version */

.risks-advice-display-area-portfolio {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  max-height: 300px;
  overflow-y: scroll;
}

.risks-advice-label-project-portfolio {
  width: 383px;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 10px;
  border-right: 1px lightgrey solid;
}

.risks-advice-label-portfolio {
  width: 348px;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-right: 10px;
  border-right: 1px lightgrey solid;
}
