.pc-ccv-status .post-contract-table {
    width: 500px;
    padding: 20px;
    overflow: hidden;
  }
  
  .pc-ccv-status .post-contract-table .pct-container {
    width: 500px;
    box-shadow: none;
  }
  
  .pc-ccv-status .post-contract-table .pct {
    background:rgba(248, 249, 250, 1);
    box-shadow: none;
  }
  
  .pc-ccv-status .post-contract-table .pct-headings-row {
    display: none;
  }
  
  .pc-ccv-status .post-contract-table .pct-headings-container {
    background: rgba(248, 249, 250, 1);
    width: max-content;
    color: var(--collaboration1);
  }
  
  .pc-ccv-status .post-contract-table .pct-heading {
    color: var(--collaboration1);
  }
  
  .pc-ccv-status .post-contract-table .pct-quantity,
  .pc-ccv-status .post-contract-table .pct-description {
    background-color:rgba(248, 249, 250, 1); 
    border-right: solid lightgrey 1px;
    justify-content: center;
    width: 110px;
    display: flex;
    flex-direction: row;
    align-items: center;
  
    min-height: 30px;
  
    border-bottom: solid lightgrey 1px;
    padding: 3px;
  }
  
  .pc-ccv-status .post-contract-table .pct-row .pct-number,
  .pc-ccv-status .post-contract-table .pct-row-selected .pct-number {
     background: rgba(248, 249, 250, 1); 
     border-right: none;
     padding-right: 30px;
  }
  
  .pc-ccv-status .post-contract-table .pct-heading,
  .pc-ccv-status .post-contract-table .pct-heading-description {
    border-right: solid lightgrey 1px;
  }
  

  .pc-ccv-status .post-contract-table .pct-row-page-total .pct-description,
  .pc-ccv-status .post-contract-table .pct-row-page-total .pct-quantity,
  .pc-ccv-status .post-contract-table .pct-row-page-total .pct-number {
  
    background: rgba(248, 249, 250, 1);
    justify-content: center;
    border-left: none;
 
    border-top: none;
    border-bottom: solid lightgrey 1px;
    font-weight: 650;
  }
  
  .pc-ccv-status .post-contract-table .pct-row-total .pct-description,
  .pc-ccv-status .post-contract-table .pct-row-total .pct-number,
  .pc-ccv-status .post-contract-table .pct-row-total .pct-quantity {
    color:  black;
     background:rgba(248, 249, 250, 1);
     justify-content: center;
    border-left: none;
   
    border-top: none;
    border-bottom: none;
    font-weight: 800;
  }

  .pc-ccv-status .post-contract-table .pct-row-page-total .pct-number, 
  .pc-ccv-status .post-contract-table .pct-row-total .pct-number
  {
    justify-content: right;
    padding-right: 30px;
  }
  