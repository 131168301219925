.admin-translation-select-label {
  font-size: 18px;
  margin-right: 15px;
}
.admin-translation-languages {
  margin-right: 15px;
}
.admin-translation {
  display: flex;
  flex-direction: column;
}

.admin-translation-row {
  display: flex;
  flex-direction: row;

  padding-bottom: 5px;
}
.admin-translation-container {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: rgba(248, 249, 250, 1);
  padding: 5%;
}
.admin-translation-label {
  width: 400px;
}

.admin-translation-input {
  width: 500px;
  height: 30px;
}
.admin-translation-controls {
  padding: 20px;
  display: flex;
  flex-direction: row;
}
table#test td,
table#test tr {
  border: none;
  height: 35px;
}
table#test {
  margin: 20px;
}
