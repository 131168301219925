/* First Prototype */

#app {
  font-family: "Roboto", sans-serif;
  margin: 20px;
}

strong {
  font-weight: 700;
}

.inline-link {
  color: hotpink;
  text-decoration: none;
}

.nav-list {
  list-style: none;
  padding: 8px;
  background-color: oldlace;
}

.nav-list__item {
  display: inline;
  color: black;
  padding: 0 10px 10px 0;
}

.nav-link {
  color: black;
  text-decoration: none;
}

.nav-link.active {
  color: hotpink;
}

@import url("https://fonts.googleapis.com/css?family=Roboto");
.nav-tab {
  font-family: "Roboto", sans-serif;
  background-color: #fff;
  display: inline-block;
  padding: 10px 25px;
  color: #bbb;
  border-bottom: 1px solid #ddd;
  text-decoration: none;
}

.nav-tab:hover {
  background-color: oldlace;
}

.nav-tab.active {
  background-color: #fff;
  color: #555;
  border: 1px solid #ddd;
  border-top: 2px solid orange;
  border-bottom: 1px solid #fff;
  cursor: default;
}

/* Second Prototype */

.links {
  margin-bottom: 10px;
}
.link {
  margin: 0 10px;
}

.tabs {
  padding: 20px;
  border: 1px solid #f5f5f5;
}

/*Buttons*/

.Download-Button {
  background-color: orange;
  height: 50px;
  width: 100px;
}
