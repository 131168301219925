.project-stages-post-contract-duration-input {
    width: 50px;
    margin-left: 10px;
    margin-right: 10px;
  }
  
  .project-stages-post-contract-duration {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .project-stages-phase-select {
    width: 435px;
    height: 30px;
    margin-bottom: 10px;
  }
  
  .project-stages-month-select {
    width: 120px;
    height: 30px;
    margin-left: 10px;
  }
  
  .project-stages-post-contract-control-label {
    width: 80px;
  }
  
  .project-stages-error-container {
    height: 35px;
    width: 200px;
    display: flex;
    justify-content: center;
  }
  
  .project-stages-error {
    color: var(--strength1);
  }
  
  .project-stages-success {
    color: var(--natural1);
  }
  