/* Page Layout */

.cost-report {
  width: 1620px;
  flex-flow: wrap;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

/* Cost Report Panel */

.cost-report-panel-content {
  font-size: 11px;

  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 10px;
}

.cost-report-panel-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.cost-report-panel-row-bold {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  background-color: var(--collaboration4);
}

.cost-report-text-cell {
  width: 360px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-right: 1px lightgrey solid;
  border-bottom: 1px lightgrey solid;
  padding: 5px;
}

.cost-report-value-cell {
  width: 140px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px lightgrey solid;

  padding: 5px;
}
