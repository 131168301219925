/* TABLE */

.area-schedule-table {
  margin-bottom: 15px;
  margin-right: 30px;
}

/* ROWS */

.area-schedule-table-head-row {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  display: flex;
  flex-direction: row;
  background-color: var(--collaboration2);
  color: var(--integrity);
  font-weight: bold;

  width: 935px;
  height: 40px;
}
.area-schedule-table-head2-row {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  display: flex;
  flex-direction: row;
  background-color: var(--integrity);
  color: var(--confident);
  font-weight: bold;

  width: 935px;
  height: 40px;
}

.area-schedule-table-row {
  display: flex;
  flex-direction: row;

  background-color: var(--integrity);
  color: var(--confident);

  width: 935px;
  height: 40px;

  user-select: none;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.area-schedule-table-row-link {
  display: flex;
  flex-direction: row;

  background-color: var(--integrity);
  color: var(--confident);

  width: 935px;
  height: 40px;

  transition: all 0s cubic-bezier(0.25, 0.8, 0.25, 1);
  text-decoration: none;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.area-schedule-table-row-link:hover {
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  background-color: rgb(223, 223, 223);
  color: var(--confident);

  cursor: pointer;
  text-decoration: none;

  -webkit-transform: scale(1.01);
  -ms-transform: scale(1.01);
  transform: scale(1.01);

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.area-schedule-table-total-row,
.area-schedule-table-sub-total-row,
.area-schedule-table-grandtotal-row {
  display: flex;
  flex-direction: row;
  font-weight: bold;

  width: 935px;
  height: 40px;
  color: var(--integrity);
  background-color: var(--confident);

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.area-schedule-table-heading-row {
  display: flex;
  flex-direction: row;
  font-weight: bold;

  width: 935px;
  height: 50px;
  color: var(--integrity);
  background-color: var(--collaboration1);

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.area-schedule-table-total-row {
  background-color: var(--collaboration1);

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.area-schedule-table-sub-total-row {
  background-color: var(--collaboration4);
  color: var(--collaboration1);

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.area-schedule-table-grandtotal-row {
  margin-top: 20px;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* CELLS */

.area-schedule-table-section-cell {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.area-schedule-table-description-cell {
  width: 570px;
  padding-left: 10px;
  padding-right: 10px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.area-schedule-table-description-cell-ref {
  width: 520px;
  padding-left: 10px;
  padding-right: 10px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.area-schedule-table-action {
  color: var(--heritage);
  font-size: 15px;
  display: flex;
  align-items: center;
}

.area-schedule-table-quantity-cell {
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.area-schedule-table-unit-cell {
  width: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.area-schedule-table-rate-cell {
  width: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.area-schedule-table-subtotal-cell {
  width: 125px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 10px;
  border-bottom: 1px solid grey;
}

/* BORDERS */

.area-schedule-table-description-cell,
.area-schedule-table-description-cell-ref,
.area-schedule-table-section-cell,
.area-schedule-table-quantity-cell,
.area-schedule-table-allocation-cell,
.area-schedule-table-unit-cell,
.area-schedule-table-rate-cell {
  border-bottom: 1px solid grey;
  border-right: 1px solid grey;
}

.area-schedule-table-total-row .area-schedule-table-description-cell,
.area-schedule-table-total-row .area-schedule-table-description-cell-ref,
.area-schedule-table-total-row .area-schedule-table-quantity-cell,
.area-schedule-table-total-row .area-schedule-table-allocation-cell,
.area-schedule-table-total-row .area-schedule-table-unit-cell,
.area-schedule-table-total-row .area-schedule-table-rate-cell,
.area-schedule-table-total-row .area-schedule-table-subtotal-cell {
  border-bottom: none;
}

.area-schedule-table-description-cell-heading {
  width: 950px;
  border-bottom: 1px solid grey;
  border-right: 1px solid grey;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.area-schedule-table-allocation-cell {
  width: 150px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 5px;
  line-height: 18px;
}
