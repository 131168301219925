.portfolio-title-banner-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.portfolio-title-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  width: 1560px;
}

.portfolio-title-banner-row-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.portfolio-title-banner-row-title .display-4 {
  font-size: 30px;

  color: var(--heritage);
  font-weight: 450;
}
