.submit-stages {
    width: 150px;
    margin: 10px;
  }
  
  .stage-field-titles {
    width: 485px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .input-stages {
    width: 350px;
  }
  
  .input-stages-code {
    width: 50px;
  }
  
  .input-stages-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  
    width: 600px;
    height: 30px;
    margin-bottom: 15px;
  }
  
  .project-phase-input-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  
    height: 30px;
    margin-bottom: 15px;
  }
  
  .stages-delete {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
  
    font-size: 15px;
  
    background-color: var(--collaboration1);
    color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 100px;
    height: 30px;
    padding: 3px;
    border: none;
  }
  
  .stages-add {
    background-color: var(--natural1);
    color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 150px;
    height: 35px;
    margin-top: 10px;
    padding: 3px;
    border: none;
    margin-bottom: 10px;
  }
  
  .stages-add:hover {
    background-color: var(--heritage);
    cursor: pointer;
  }
  
  .stages-delete:hover {
    background-color: var(--strength1);
    cursor: pointer;
  }
  
  .flex-container-stages {
    display: flex;
    flex-direction: column;
  }
  
  /* Stages Container */
  
  .edit-stages-container {
    padding: 15px;
  }
  
  .stages-save-control-flex {
    display: flex;
    align-items: center;
    flex-direction: row;
  
    padding-left: 240px;
    width: 100%;
  }
  
  .phases-save-control-flex {
    display: flex;
    align-items: center;
    flex-direction: row;
  
    padding-left: 155px;
    width: 100%;
  }
  
  .post-contract-save-control-flex {
    display: flex;
    align-items: center;
    flex-direction: row;
  
    padding-left: 240px;
    width: 100%;
  }
  
  /* Submitted */
  
  .submitted-true-stages {
    margin-left: 30px;
    font-size: 13px;
    color: var(--natural1);
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s; /* Firefox < 16 */
    -ms-animation: fadein 2s; /* Internet Explorer */
    -o-animation: fadein 2s; /* Opera < 12.1 */
    animation: fadein 2s;
  }
  
  .submitted-false-stages {
    display: none;
  }
  
  .spinner-false-stages {
    display: none;
  }
  
  .spinner-true-stages {
    margin-left: 30px;
    width: 50px;
  }
  
  .error-false-stages {
    display: none;
  }
  
  .error-true-stages {
    margin-left: 30px;
    font-size: 13px;
    color: var(--strength1);
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s; /* Firefox < 16 */
    -ms-animation: fadein 2s; /* Internet Explorer */
    -o-animation: fadein 2s; /* Opera < 12.1 */
    animation: fadein 2s;
  }
  