.clarification-field-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 800px;
  margin-bottom: 15px;
}

.post-contract-clarifications-table {
  max-height: 210px;
  overflow-y: scroll;
  margin-bottom: 10px;
  min-height: 210px;
}

/* Highlights */
.clarification-highlight {
  width: 755px;
}

/* Key Decisions */
.clarification-decision {
  width: 600px;
}

.clarification-required-by {
  width: 140px;
}
.clarification-delete-spacer {
  width: 30px;
}

/* Risks */
.clarification-urgency {
  width: 200px;
}

.clarification-title {
  margin-bottom: 15px;
}

.clarification-add-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--natural1);
  color: var(--integrity);

  width: 150px;
  height: 35px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin-top: 30px;
  user-select: none;
}

.clarification-add-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.clarification-delete-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--strength1);
  color: var(--integrity);

  width: 30px;
  height: 30px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  user-select: none;
}

.clarification-delete-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.clarification-save-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration1);
  color: var(--integrity);

  width: 150px;
  height: 35px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin-top: 30px;
  user-select: none;
}

.clarification-save-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.post-contract-clarifications {
  padding: 15px;
}

.post-contract-toggle {
  display: flex;
  flex-direction: row;
  justify-content: center;

  height: 30px;

  margin-top: 15px;
  margin-bottom: 15px;
}
.pc-toggle-active-button {
  color: white;
  background-color: var(--heritage);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-size: 15px;

  width: 420px;
  height: 40px;

  user-select: none;
}
.pc-toggle-active-button:hover {
  background-color: var(--collaboration1);
  cursor: pointer;
}
.pc-toggle-inactive-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: black;
  background-color: white;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-size: 15px;

  width: 420px;
  height: 40px;

  user-select: none;
}

.pc-toggle-inactive-button:hover {
  background-color: var(--collaboration1);
  cursor: pointer;
  color: white;
}
