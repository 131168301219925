.project-item-drag {
  margin-bottom: 15px;
  margin-left: 15px;
  margin-right: 15px;
  width: 330px;
  height: 350px;

  text-align: left;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.project-item-title {
  font-size: 20px;

  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  height: 25px;
}

.project-item-title-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  height: 25px;
}

.project-item-title-row .display-4 {
  margin: 0px;
}

.title-image {
  object-fit: cover;
  object-position: 100% 25%;
  height: 190px;
  width: 100%;
  border-radius: 0px;
}

.project-card {
  width: 330px;
  height: 350px;
  color: black;
  background-color: rgba(248, 249, 250, 1);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 0px;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.project-card:hover {
  /* background: orange;
  transition: 1s ease all; */
  -webkit-transform: scale(1.085);
  -ms-transform: scale(1.085);
  transform: scale(1.085);
}

.project-card-dragged {
  width: 330px;
  height: 350px;
  color: black;
  background-color: var(--collaboration1);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 0px;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.project-card-dragged:hover {
  /* background: orange;
  transition: 1s ease all; */
  -webkit-transform: scale(1.085);
  -ms-transform: scale(1.085);
  transform: scale(1.085);
}

.project-link {
  width: 330px;
  height: 350px;
}

.project-link-disabled {
  width: 330px;
  height: 350px;
  margin-bottom: 30px;
  margin-left: 15px;

  pointer-events: none;
  user-select: none;
}

.project-link:hover {
  text-decoration: none;
}

.project-details {
  margin: 10px;
  height: 140px;
}

.project-card-pulse {
  width: 330px;
  height: 350px;
  color: black;
  background-color: rgba(248, 249, 250, 1);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  border: solid;
  animation: glow 400ms ease-out infinite alternate;
  border-color: var(--heritage);
  border-width: 3px;
}

.project-card-pulse:hover {
  -webkit-transform: scale(1.085);
  -ms-transform: scale(1.085);
  transform: scale(1.085);
}

@keyframes glow {
  0% {
    border-color: rgb(153, 171, 202);
  }
  100% {
    border-color: rgb(50, 98, 149);
  }
}

.project-item-notification {
  height: 15px;
  border-radius: 10px;
  font-size: 12px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.project-item-notification-text {
  margin-right: 5px;
}

.project-item-notification-icon {
  color: var(--heritage);
}
