.budget-description {
    width: 500px;
  }
  .budget-data{
    width: 150px;
  }
  .allocate-cost-label{
    width: 400px;
  }
  .budget-allocated{
    width: 150px;
    text-align: right;
  }
  .budget-estimate{
    width: 300px;
    text-align: right;
    padding-right: 15px;
  }
  .budget-allocated-heading{
    width: 125px
  }
  .budget-to-allocate{
    width: 150px;
    text-align: right;
    padding-right: 20px;
  }
  .budget-approval {
    width: 15px;
  }
  .budget-total-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    margin-top:15px;
  }
  .budget-total-data{
    width: 150px;
    margin-left: 60px;
  }
  .budget-heading-value {
    width: 100px;
  }
  .budget-heading-approval {
    width: 50px;
  }
  .budget-total-approval{
    margin-left: 65px;
    width:15px;
  }
  .edit-budget-container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  