.tpi-calc {
  width: 500px;

  padding: 20px;

  /* Keeps the close button at the bottom */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tpi-calc-select {
  height: 27px;
  width: 205px;
}

.tpi-calc-cost-input {
  height: 27px;
  width: 205px;
}

.tpi-calc-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  height: 35px;
  width: 100px;
  background-color: var(--collaboration1);
  color: white;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 100px;
}

.tpi-calc-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.tpi-calc-control-button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.tpi-calc-button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.tpi-calc-slider-label {
  width: 160px;
}

.tpi-calc-result {
  font-size: 20px;
  color: var(--heritage);
}

.tpi-calc-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.tpi-calc-slider-row {
  width: 370px;
  height: 27px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}
