.project-team-background {
  display: flex;
  flex-direction: row;

  width: 100%;
  margin: 0 auto;
}

.project-team-area {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.project-team-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin-top: 30px;
  margin-left: 30px;
}

.project-team-profile-card:hover {
  cursor: pointer;
  text-decoration: none;
  -webkit-transform: scale(1.04);
  -ms-transform: scale(1.04);
  transform: scale(1.04);
}

.project-team-cost-manager-title {
  margin-bottom: 10px;
}

.project-team-client-title {
  margin-bottom: 10px;
  height: 30px;
}

.project-team-title {
  margin-bottom: 18px;
}
