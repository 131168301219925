.guest-contacts {
  height: 100vh;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.guest-contacts-background {
  background-color: rgba(85, 87, 90, 0.8);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 20px;

  width: 1100px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 0 auto;
  margin-bottom: 30px;
}

.guest-contacts-background2 {
  background-color: rgba(85, 87, 90, 0.8);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 20px;

  width: 1100px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 0 auto;
}

.guest-contacts-title {
  margin-top: 20px;
  margin-bottom: 20px;
  color: white;
}

.guest-contacts-title .display-4 {
  font-size: 30px;
}

.project-team-profile-card {
  background-color: var(--integrity);
  display: flex;
  flex-direction: row;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 100px;
  margin-bottom: 30px;
  width: 1070px;
  height: 70px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.project-team-profile-card:hover {
  cursor: pointer;
  text-decoration: none;
  -webkit-transform: scale(1.01);
  -ms-transform: scale(1.01);
  transform: scale(1.01);
}
