.options-analysis-upload-control-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 390px;
  margin-bottom: 1px;
}

.options-analysis-upload-control-label {
  width: 170px;
  margin-right: 20px;
  font-size: 14px;
}

.options-analysis-upload-control-input {
  width: 200px;
  height: 25px;
}

select.options-analysis-upload-control-input option {
  text-align: center;
}

.general-modal-button.disabled,
.general-modal-button.disabled:hover,
.general-upload-button.disabled,
.general-upload-button.disabled:hover {
  cursor: not-allowed;
  background: var(--collaboration3);
}

.upload-disabled-warning {
  text-align: center;
  color: red;
}
