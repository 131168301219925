.options-analysis-modal-form .advice-title {
  font-size: 20px;
}

.options-analysis-modal-form .advice-delete-button {
  margin: 5px;
}

.options-analysis-modal-form .error-message {
  color: red;
}

.options-analysis-modal-form .error-message.success {
  color: green;
}

.options-analysis-modal-form .options-analysis-inputs-grid {
  display: grid;
  grid-template-columns: auto repeat(8, 150px) auto;
  gap: 10px;
  margin-bottom: 15px;
}
