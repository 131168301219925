.portfolio-metrics-banner-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;

  margin-top: 20px;
}

.portfolio-metrics-banner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  border-radius: 60px;

  background-color: var(--integrity);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  width: 1560px;
}

.portfolio-metrics-banner-row-internal {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}

.portfolio-metrics-banner-row-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 200px;
}

.portfolio-metrics-banner .display-4 {
  font-size: 20px;
  font-weight: 400;
}
