.article-content {
  display: flex;
  flex-direction: row;
  padding-top: 20px;
}

.article-author-container {
  margin-left: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.article-author-image {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 200px;
  height: 200px;
  border-radius: 200px;
  margin-bottom: 30px;
}

.article-author-name {
  font-size: 30px;
}

.article-author-job-title {
  font-size: 20px;
}

.article-author-email {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration1);
  color: var(--integrity);

  border-radius: 100px;
  width: 35px;
  height: 35px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: all 0s cubic-bezier(0.25, 0.8, 0.25, 1);

  margin-top: 10px;
}

.article-author-email:hover {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);

  cursor: pointer;
  background-color: var(--heritage);
  color: var(--integrity);
  text-decoration: none;
}

.article-pdf {
  width: 1175px;
}

.article-heading {
  margin-bottom: 30px;
}

/* Article Buttons */

.article-delete-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration1);
  color: var(--integrity);

  border-radius: 100px;
  width: 20px;
  height: 20px;
  font-size: 10px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: all 0s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.article-delete-button:hover {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);

  cursor: pointer;
  background-color: var(--heritage);
}

/* Button Tooltips */

.articles-card-button-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 50px;
  border-radius: 100px;
}

.articles-card-button-container .tooltiptext {
  visibility: hidden;
  background-color: var(--heritage);
  color: #fff;
  padding: 4px 4px;
  text-align: center;
  width: max-content;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  /* Position the tooltip */
  border-radius: 100px;
  margin-left: 35px;
  margin-top: -1px;
  position: absolute;
  font-size: 10px;
  z-index: 1000;
}

.articles-card-button-container .tooltiptext:hover {
  visibility: hidden;
  z-index: 1000;
}

.articles-card-button-container:hover .tooltiptext {
  visibility: visible;
  z-index: 1000;
}
