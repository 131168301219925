.project-highlights-bar-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  user-select: none;
}

.project-highlights-bar {
  background-color: var(--integrity);

  height: 142.5px;
  width: 500px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  transition: transform 0.3s;
}

.project-highlights-bar-link {
  text-decoration: none;
  height: 142.5px;
  margin-bottom: 15px;
}

.project-highlights-bar-link:hover {
  text-decoration: none;
}

.project-highlights-benchmarking {
  width: 200px;
  height: 55px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.project-highlights-benchmarking-temp {
  width: 100%;
  height: 55px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.project-highlights-benchmarking-number {
  color: var(--heritage);
  margin-top: 15px;
  font-size: 17px;
}

.project-highlights-cost {
  width: 260px;
  height: 55px;
  margin-top: 50px;
  margin-left: 15px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}

.project-highlights-cost-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
}

.project-highlights-cost-text {
  color: var(--collaboration1);
  font-size: 12px;
}

.project-highlights-cost-number {
  color: var(--heritage);
  font-size: 12px;
}

.budget-graph {
  width: 50px;
}

.stacked-budget-bar {
  height: 100px;
  width: 300px;

  padding-top: 1px;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
}

.budget-bar {
  position: absolute;
  height: 15px;
  margin-top: 60px;
}

.cost-bar {
  position: absolute;
  height: 15px;
  margin-top: 60px;
  max-width: 300px;
}

.budget-line {
  position: absolute;
  height: 45px;
  width: 3px;
  margin-top: 45px;

  background-color: black;
}

.budget-label {
  display: flex;
  flex-direction: row;
  margin-top: 90px;
}

.pdcp-benchmark-bar-percentage {
  font-size: 20px;
  color: rgba(50, 98, 149, 1);
  margin-top: 4px;
}

.pdcp-benchmark-bar-text {
  margin-top: 11px;
  margin-left: 10px;
  font-size: 13px;
  color: var(--collaboration1);
}

.pdcp-benchmark-bar-value {
  font-size: 20px;
  margin-top: 4px;
  color: var(--confidence);
  margin-left: 7px;
}

.budget-value {
  margin-top: 9px;
  margin-left: 5px;
  font-size: 15px;
  color: var(--heritage);
}

.project-highlights-cost-title {
  position: absolute;
  color: var(--collaboration1);
  width: 630px;
}

.project-highlights-benchmark-title {
  position: absolute;
  color: var(--collaboration1);
  width: 630px;
}

.cost-bar-svg {
  height: 35px;
}
