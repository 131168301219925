.estimate-benchmark-chart-box-dashboard {
  width: 400px;
  margin-bottom: 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: white;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 20px;
}

.estimate-benchmark-chart-box-dashboard-hidden {
  width: 400px;
  margin-bottom: 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration4);
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 20px;
}

.estimate-benchmark-chart-box-dashboard-disabled {
  display: none;
}

.estimate-construction-cost-comparison {
  margin-top: 20px;
  margin-bottom: 20px;
}

.estimate-dashboard .legend-project-label {
  margin-left: 40px;
}

.estimate-dashboard .legend-benchmark-label {
  margin-left: 143px;
  margin-top: 90px;
}

.estimate-dashboard .legend-svg1 {
  margin-left: 33px;
}

.estimate-dashboard .legend-svg2 {
  margin-left: 114px;
  margin-top: 88px;
  padding-right: 20px;
  padding-left: 8px;
}

.estimate-benchmark-legend-row,
.estimate-benchmark-legend-row-total {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  margin-left: 10px;
  width: 335px;
}

.estimate-benchmark-legend-row-total {
  font-weight: bold;
}

.estimate-benchmark-legend-label {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.estimate-benchmark-legend-circle {
  width: 16px;
  height: 16px;
  border-radius: 100px;
  margin-right: 8px;
}
