/* TABLE */

.detailed-comparison-table {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--integrity);
  width: 700px;
  font-size: 13px;
  margin-bottom: 30px;
}

/* ROWS */

.detailed-comparison-table-head-row {
  display: flex;
  flex-direction: row;

  background-color: var(--collaboration1);
  color: var(--integrity);

  width: 700px;
  height: 30px;
}

.detailed-comparison-table-row {
  display: flex;
  flex-direction: row;

  background-color: var(--integrity);
  color: var(--confident);

  width: 700px;
  height: 30px;
}

.detailed-comparison-table-total-row {
  display: flex;
  flex-direction: row;

  background-color: var(--collaboration4);
  color: var(--confident);

  width: 700px;
  height: 30px;
}

/* CELLS */

.detailed-comparison-table-description-cell {
  width: 350px;
  padding: 5px;
}

.detailed-comparison-table-previous-cell {
  width: 100px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
}

.detailed-comparison-table-current-cell {
  width: 100px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
}

.detailed-comparison-table-subtotal-cell {
  width: 100px;
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

.detailed-comparison-table-note-cell {
  width: 50px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid grey;
}

/* HEADER CELLS */

.detailed-comparison-table-previous-cell-head {
  width: 100px;
  padding: 5px;
  display: flex;
  justify-content: flex-end;
}

.detailed-comparison-table-current-cell-head {
  width: 100px;
  padding: 5px;
  display: flex;
  justify-content: flex-end;
}

.detailed-comparison-table-subtotal-cell-head {
  width: 100px;
  display: flex;
  justify-content: flex-end;
  padding: 5px;
}

.detailed-comparison-table-note-cell-head {
  width: 50px;
  padding: 5px;
  display: flex;
  justify-content: center;

  border-bottom: 1px solid grey;
}

/* BORDERS */

.detailed-comparison-table-previous-cell-head,
.detailed-comparison-table-current-cell-head,
.detailed-comparison-table-subtotal-cell-head {
  border-bottom: 1px solid grey;
  border-right: 1px solid grey;
}

.detailed-comparison-table-description-cell,
.detailed-comparison-table-previous-cell,
.detailed-comparison-table-current-cell,
.detailed-comparison-table-subtotal-cell {
  border-bottom: 1px solid grey;
  border-right: 1px solid grey;
}

.detailed-comparison-table-value-positive {
  color: var(--confident);
}

.detailed-comparison-table-value-negative {
  color: var(--heritage);
}
