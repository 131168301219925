.admin-background {
  display: flex;
  flex-direction: row;

  width: 100%;
  margin: 0 auto;
}

.admin-control-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;

  justify-content: space-between;
}

.admin-control-column,
.admin-control-column-backer {
  background-color: rgba(248, 249, 250, 1);

  width: 215px;
  height: 800px;
  margin-top: -15px;

  margin-right: 25px;
  border-radius: 0 0 50px 0;
}

.admin-control-column-backer {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.admin-container {
  border-radius: 20px;

  margin: 0 auto;
}

.edit-permissions-button {
  margin-right: 30px;
  height: 35px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration1);
  color: white;
  padding: 5px;
  width: 200px;
  border: none;
  text-align: center;

  border-radius: 100px;
}

.edit-permissions-button:hover {
  text-decoration: none;
  color: white;
  background-color: var(--heritage);
}

.control-container {
  display: flex;
}

.admin-control-label {
  width: 600px;
}

.admin-navigation-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 13px;
}
