.post-contract-control-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.post-contract-control-panel-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
}

.post-contract-control-button,
.post-contract-control-button-upload,
.post-contract-control-button-delete,
.post-contract-control-button-dashboard,
.post-contract-control-button-clicked {
  color: white;
  padding: 5px;
  width: 150px;
  height: 35px;
  border: none;
  text-align: center;
  font-size: 13px;

  border-radius: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin-bottom: 15px;
}

.post-contract-control-button {
  background-color: var(--collaboration1);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.post-contract-control-button-clicked {
  background-color: var(--collaboration3);
}

.post-contract-control-button-upload:hover,
.post-contract-control-button:hover,
.post-contract-control-button-dashboard:hover,
.post-contract-control-button-delete:hover,
.post-contract-control-button-clicked:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.post-contract-control-button-upload {
  background-color: var(--natural1);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.post-contract-control-button-delete {
  background-color: var(--collaboration2);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.post-contract-control-button-dashboard {
  background-color: var(--fluidity1);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
