/* TABLE */

.cashflow-table {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-top: 30px;
  align-self: flex-start;
}

/* ROWS */

.cashflow-table-head-row {
  display: flex;
  flex-direction: row;

  background-color: var(--collaboration2);
  color: var(--integrity);

  width: 700px;
  height: 45px;
}

.cashflow-table-row {
  display: flex;
  flex-direction: row;

  background-color: var(--integrity);
  color: var(--confident);

  width: 700px;
  height: 45px;
}

.cashflow-table-total-row {
  display: flex;
  flex-direction: row;

  background-color: var(--collaboration1);
  color: var(--integrity);

  width: 700px;
  height: 45px;
}

/* CELLS */

.cashflow-table-description-cell {
  width: 200px;
  padding: 10px;
}

.cashflow-table-quantity-cell {
  width: 250px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.cashflow-table-unit-cell {
  width: 250px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

/* BORDERS */

.cashflow-table-description-cell,
.cashflow-table-quantity-cell,
.cashflow-table-unit-cell,
.cashflow-table-rate-cell {
  border-bottom: 1px solid grey;
  border-right: 1px solid grey;
}

.cashflow-table-subtotal-cell {
  border-bottom: 1px solid grey;
}

.cashflow-table-total-row .cashflow-table-description-cell,
.cashflow-table-total-row .cashflow-table-quantity-cell,
.cashflow-table-total-row .cashflow-table-unit-cell,
.cashflow-table-total-row .cashflow-table-rate-cell,
.cashflow-table-total-row .cashflow-table-subtotal-cell {
  border-bottom: none;
}
