.benchmark-create-analysis-disclaimer {
  display: grid;
  grid-auto-flow: column;
  gap: 40px;
  min-width: 1430px;
  width: 1430px;
  margin-bottom: 30px;
  grid-auto-columns: 1fr;
}

.benchmark-create-analysis-disclaimer-item {
  display: flex;
  flex-direction: column;
}

.benchmark-create-analysis-disclaimer-item > div.value,
.benchmark-create-analysis-disclaimer-item > div.label {
  display: flex;
  justify-content: center;
  align-items: center;
}

.benchmark-create-analysis-disclaimer-item > div.value {
  height: 40px;
  font-weight: bold;
  color: white;
  background-color: var(--heritage2);
  border-radius: 20px;
}

.benchmark-create-analysis-disclaimer-item > div.label {
  height: 30px;
  font-size: small;
  color: var(--collaboration1);
}
