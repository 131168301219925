.value-engineering-multiple-totals {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration1);
  color: var(--integrity);

  width: 1430px;
  height: 30px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;

  font-size: 16px;
  user-select: none;

  margin-right: 30px;
  margin-bottom: 10px;
}

.value-engineering-multiple-totals.primary {
  background-color: var(--collaboration1);
}

.value-engineering-multiple-totals.secondary {
  background-color: var(--collaboration2);
}

.value-engineerings-multiple-totals-label {
  display: flex;
  flex-direction: start;
  align-items: center;
  padding-left: 15px;
  width: 100%;
  height: 30px;

  border-right: 1px solid white;
}

.value-engineerings-multiple-totals-costs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.value-engineerings-multiple-totals-total {
  width: 125px;
  height: 30px;

  padding-right: 15px;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.value-engineering-multiple-totals:last-of-type {
  margin-bottom: 70px;
  /* Increase the margin to create space for the next accordion */
}
