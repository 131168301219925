.ctv-table {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 100%;
}

.ctv-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  border-left: 1px solid grey;
}

.ctv-cell {
  color: var(--collaboration1);
  background-color: var(--integrity);

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;

  border-right: 1px solid grey;
  border-bottom: 1px solid grey;
  width: 100%;
}

.ctv-cell-head {
  width: 100%;
  color: var(--integrity);
  height: 45px;
  background-color: var(--collaboration2);

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;

  border-bottom: 1px solid grey;
  border-top: 1px solid grey;
}
