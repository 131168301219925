.project-control-estimate-metrics {
  padding: 15px;

  width: 885px;
}

/* Background Object */
.project-control-estimate-metrics-container {
  height: 245px;
  width: 630px;
  background-color: rgba(248, 249, 250, 1);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 10px;
  margin-bottom: 15px;
}

.project-control-estimate-metric-error-container {
  height: 30px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-self: center;
}

/* Table */

.project-control-metrics-table {
  margin-bottom: 15px;
}

.project-control-metrics-selection {
  padding: 15px;
}
