.pd-projects-list {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  width: 500px;
}

.pd-projects-list-row-image {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  width: 40px;
  object-fit: cover;
  margin-right: 10px;
}

.pd-projects-list-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 500px;
  padding: 5px;

  border-bottom: 1px lightgrey solid;
  text-decoration: none;
  color: var(--collaboration1);
}

.pd-projects-list-row:hover {
  background-color: var(--heritage);
  cursor: pointer;
  text-decoration: none;
  color: var(--integrity);
}

.pd-projects-list-title {
  margin-left: 10px;
}
