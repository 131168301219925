.image-display-bezier {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: all 1s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.image-loading {
  display: flex;
  flex-direction: center;
  align-items: center;
  justify-content: center;
}

.image-display-bezier:hover {
  -webkit-transform: scale(1.085);
  -ms-transform: scale(1.085);
  transform: scale(1.085);
  cursor: pointer;
}
