.detailed-comparison {
  display: flex;
  flex-direction: row;
}

.detailed-comparison-container {
  margin-right: 30px;
  width: 700px;
}

.detailed-comparison-container-none {
  margin-right: 30px;
  width: 700px;
  display: none;
}

.recon-section-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 45px;
  margin-bottom: 20px;
}

.recon-section-title .display-4 {
  font-size: 30px;
}

.recon-comparison-toggle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 10px;
  user-select: none;
}

.recon-comarison-toggle-button-in-left,
.recon-comarison-toggle-button-in-right {
  display: flex;
  flex-direction: row;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 10px;
  padding-right: 10px;
  height: 40px;
  background-color: var(--heritage);
  color: var(--integrity);
}

.recon-comarison-toggle-button-out-right,
.recon-comarison-toggle-button-out-left {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  display: flex;
  flex-direction: row;
  justify-content: center;

  padding-left: 10px;
  padding-right: 10px;
  height: 40px;
  background-color: white;
  color: var(--confident);
}

.recon-comarison-toggle-button-out-left:hover,
.recon-comarison-toggle-button-out-right:hover {
  cursor: pointer;
  background-color: var(--heritage);
  color: var(--integrity);
}

.recon-comarison-toggle-button-in-left,
.recon-comarison-toggle-button-out-left {
  border-radius: 100px 0px 0px 100px;
}

.recon-comarison-toggle-button-in-right,
.recon-comarison-toggle-button-out-right {
  border-radius: 0px 100px 100px 0px;
}

/* benchmarkinig */

.bench-recon-comarison-toggle-button-in-left,
.bench-recon-comarison-toggle-button-in-right {
  display: flex;
  flex-direction: row;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 10px;
  padding-right: 10px;
  height: 25px;
  background-color: var(--heritage);
  color: var(--integrity);
}

.bench-recon-comarison-toggle-button-out-right,
.bench-recon-comarison-toggle-button-out-left {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  display: flex;
  flex-direction: row;
  justify-content: center;

  padding-left: 10px;
  padding-right: 10px;
  height: 25px;
  background-color: white;
  color: var(--confident);
}

.bench-recon-comarison-toggle-button-in-left .display-4,
.bench-recon-comarison-toggle-button-in-right .display-4,
.bench-recon-comarison-toggle-button-out-right .display-4,
.bench-recon-comarison-toggle-button-out-left .display-4 {
  font-size: 19px;
}

.bench-recon-comarison-toggle-button-out-left:hover,
.bench-recon-comarison-toggle-button-out-right:hover {
  cursor: pointer;
  background-color: var(--heritage);
  color: var(--integrity);
}

.bench-recon-comarison-toggle-button-in-left,
.bench-recon-comarison-toggle-button-out-left {
  border-radius: 100px 0px 0px 100px;
}

.bench-recon-comarison-toggle-button-in-right,
.bench-recon-comarison-toggle-button-out-right {
  border-radius: 0px 100px 100px 0px;
}
