.ebc-container {
  padding: 15px;

  /* Keeps the close button at the bottom */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ebc-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.ebc-table {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-left: 1px solid grey;
  border-right: 1px solid grey;
  margin-bottom: 20px;
}

.ebc-heading-row {
  width: 800px;

  display: flex;
  flex-direction: row;
  font-size: 15px;

  border-bottom: 1px solid grey;

  background-color: var(--collaboration2);
  color: var(--integrity);
  height: 25px;
}

.ebc-row {
  width: 800px;

  display: flex;
  flex-direction: row;
  font-size: 15px;

  border-bottom: 1px solid grey;
}

.ebc-description {
  text-align: left;
  border-right: 1px solid grey;
  padding-left: 10px;
  width: 430px;
}

.ebc-row .ebc-description {
  padding: 10px;
}

.ebc-quantity {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100px;
  border-right: 1px solid grey;
}

.ebc-unit_of_measure {
  display: flex;
  align-items: center;
  width: 65px;
  border-right: 1px solid grey;
  justify-content: center;
}

.ebc-rate {
  display: flex;
  align-items: center;
  width: 140px;
  border-right: 1px solid grey;
  justify-content: center;
}

.ebc-subtotal {
  display: flex;
  align-items: center;
  width: 125px;
  justify-content: flex-end;
  padding: 10px;
}

.ebc-error {
  color: var(--strength1);
}

.ebc-success {
  color: var(--natural1);
}

.ebc-error-container {
  padding-top: 5px;
  height: 30px;
  font-size: 15px;
}

/* Comment List */

.ebc-comment-thread {
  height: 300px;
  width: 800px;
  overflow-y: scroll;
  margin-bottom: 20px;
  border-top: 1px solid lightgrey;
}

.ebc-comment-card-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  background-color: var(--integrity);
  padding: 10px;

  border-bottom: 1px solid lightgrey;
  border-left: 1px solid lightgrey;
}

.ebc-comment-card {
  display: flex;
  flex-direction: column;

  width: 100%;
  font-size: 16px;
  min-height: 60px;
  padding: 5px;

  text-align: left;
}

.ebc-comment-top-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ebc-comment-details {
  display: flex;
  flex-direction: row;
  color: var(--collaboration2);
}

.ebc-comment-user-surname {
  margin-left: 5px;
}

.ebc-comment-text {
  width: 100%;
  font-size: 16px;
  text-align: left;
}

.ebc-avatar-comment-container {
  height: 30px;
  width: 30px;
  margin-right: 10px;
}

.ebc-avatar-comment {
  height: 30px;
  width: 30px;
  border-radius: 100px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.ebc-avatar-comment:hover {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  cursor: pointer;
}

/* New Comment */

.ebc-new-comment-container {
  display: flex;
  flex-direction: row;
  width: 800px;
}

.ebc-new-comment {
  display: flex;
  flex-direction: row;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.ebc-avatar {
  height: 30px;
  width: 30px;
  margin: 10px;
  border-radius: 100px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.ebc-comment {
  padding: 5px;
  height: 60px;
  width: 690px;
  font-size: 15px;
  resize: none;
  border: none;
}

.ebc-send {
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--collaboration1);
  color: var(--integrity);
  font-size: 20px;
}

.ebc-send:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.ebc-close-button {
  width: 100px;
  border-radius: 100px;
  background-color: var(--collaboration1);
  font-size: 20px;
  color: var(--integrity);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.ebc-close-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.ebc-button-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.ebc-comment-delete-button {
  color: var(--collaboration1);
}

.ebc-comment-delete-button:hover {
  color: var(--strength1);
  cursor: pointer;
}

.ebc-comment-thread-filtered{
  max-height:300px;
  width: 800px;
  overflow-y: scroll;
  margin-bottom: 10px;
  border-top: 1px solid lightgrey;
}