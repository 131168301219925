.admin-localisation-pagination li {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 45px;
  text-align: center;
  align-items: center;
  font-size: 15px;
  height: 30px;
}

.admin-localisation-pagination .pagination {
  margin: 0px;
}
