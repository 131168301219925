.estimates-TPI-save-message-success,
.estimates-TPI-save-message-fail,
.estimates-TPI-save-message-neutral {
  font-size: 15px;
  margin-left: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.estimates-TPI-save-message-success {
  color: var(--natural1);
}

.estimates-TPI-save-message-fail {
  color: var(--strength1);
}

.estimates-TPI-save-message-neutral {
  color: var(--collaboration1);
}
