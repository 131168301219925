.portfolio-page-control a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--integrity);
  text-decoration: none;
  font-weight: bold;
}

.portfolio-page-control .tab,
.portfolio-page-control .tab-selected,
.portfolio-page-control .tab-arrow-right,
.portfolio-page-control .tab-arrow-left,
.portfolio-page-control .tab-arrow-inactive-right,
.portfolio-page-control .tab-arrow-inactive-left {
  font-size: 15px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin: 3px;
  user-select: none;
  border-radius: 100px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.portfolio-page-control .tab,
.portfolio-page-control .tab-selected,
.portfolio-page-control .tab-arrow-right,
.portfolio-page-control .tab-arrow-left {
  background-color: var(--collaboration1);
}

.portfolio-page-control .tab,
.portfolio-page-control .tab-selected {
  width: 40px;
  height: 40px;
}

.portfolio-page-control .tab:hover,
.portfolio-page-control .tab-selected:hover,
.portfolio-page-control .tab-arrow-right:hover,
.portfolio-page-control .tab-arrow-left:hover {
  background-color: var(--heritage);
  cursor: pointer;
  text-decoration: none;
}

.portfolio-page-control .tab-selected {
  background-color: var(--heritage);
}

.portfolio-page-control .tab-arrow-left,
.portfolio-page-control .tab-arrow-right {
  width: 30px;
  height: 30px;
}

.portfolio-page-control .tab-arrow-right {
  margin-left: 20px;
  margin-top: 8px;
}

.portfolio-page-control .tab-arrow-left {
  margin-right: 20px;
  margin-top: 8px;
}

.portfolio-page-control .tab-arrow-inactive {
  width: 30px;
  background-color: var(--collaboration2);
}

.portfolio-page-control .tab-arrow-inactive:hover {
  cursor: default;
  background-color: var(--collaboration2);
}

.portfolio-page-control .tab-arrow-inactive a:hover {
  cursor: default;
  background-color: var(--collaboration2);
  text-decoration: none;
}
