.estimate-breakdown-comment-open {
  margin-left: 20px;
  height: 30px;
  width: 30px;

  /* display: flex; */
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;

  font-size: 20px;
}

.estimate-breakdown-comment-modal-button {
  background: none;
  color: inherit;
  padding: 0;
  font: inherit;
  cursor: pointer;

  border: none;
  outline: inherit;
}

.estimate-breakdown-comment-modal-button:focus {
  outline: inherit;
}

.estimate-breakdown-comment-display-block {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);

  display: table;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.estimate-breakdown-comment-display-none {
  display: none;
}

.estimate-breakdown-comment-icon-on {
  color: var(--heritage);
}

.estimate-breakdown-comment-icon-off {
  color: var(--collaboration1);
}

.estimate-breakdown-comment-icon-on:hover {
  color: var(--collaboration1);
  cursor: pointer;
}

.estimate-breakdown-comment-icon-off:hover {
  color: var(--heritage);
  cursor: pointer;
}

.estimate-breakdown-comment-icon-on-filtered {
  color: var(--heritage);
}

.estimate-breakdown-comment-icon-off-filtered {
  color: var(--collaboration1);
}
