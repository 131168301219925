.company-management-logo {
  height: 16px;
  margin-top: -2px;
  margin-left: 4px;
}

.company-management-logo-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 21px;
}

.company-management-table-employees {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
