.ve-items-list-table {
  display: flex;
  flex-direction: column;
  padding: 10px;
  font-size: small;
}

.ve-items-list-table .value-engineering-head-row,
.ve-items-list-table .value-engineering-row {
  width: 480px;
}

.ve-items-list-table .ve-description-cell {
  width: 240px;
}
