.feedback-display-block {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);

  display: table;
}

.feedback-display-none {
  display: none;
}

.feedback-main {
  width: 100%;
  height: 100%;
  transition: 1.1s ease-out;
  box-shadow: -2rem 2rem 2rem rgba(black, 0.2);
  filter: blur(0);
  transform: scale(1);
  border-radius: 0px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.feedback-open {
  color: var(--confident);
  display: flex;
  flex-direction: row;
  align-items: center;

  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.feedback-open:focus {
  outline: inherit;
}

.feedback-open:hover {
  color: var(--heritage);
  cursor: pointer;
}

.feedback-icon {
  margin-right: 6px;
  font-size: 16px;
  color: var(--collaboration2);
}

.feedback-open:hover .feedback-icon {
  color: var(--heritage);
}

.feedback {
  background-color: var(--integrity);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  height: 500px;
  width: 500px;

  color: var(--confident);

  padding: 20px;

  /* Keeps the close button at the bottom */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.feedback-select {
  height: 40px;
}

.feedback-textarea {
  height: 200px;
  width: 100%;
  resize: none;
}

.feedback-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  height: 35px;
  width: 100px;
  background-color: var(--collaboration1);
  color: white;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 100px;
}

.feedback-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.feedback-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.feedback-error {
  color: var(--strength1);
}

.feedback-success {
  color: var(--natural1);
}

.feedback-text-counter-container {
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
}

.feedback-error-container {
  padding-top: 5px;
}

.feedback-counter-error {
  color: var(--strength1);
  margin-right: 7px;
}

.feedback-counter-success {
  color: var(--natural1);
  margin-right: 7px;
}
