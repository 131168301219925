.idle-logout {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.idle-logout-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration1);
  color: var(--integrity);

  height: 30px;
  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;

  margin-top: 20px;
}

.idle-logout-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}
