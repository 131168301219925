.profile-modal {
  background-color: rgb(63, 63, 63);

  padding: 15px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.profile-details {
  margin-right: 100px;
}

.profile-container {
  border-radius: 20px;

  margin: 0 auto;

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.profile-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.profile-contact-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.profile-contact-text {
  margin-top: 3px;
}

.profile-contact-icon {
  width: 60px;
  font-size: 13px;
}

.profile-image {
  border-radius: 100px;
  width: 150px;
  margin-right: 100px;
  margin-left: 50px;
  box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.2), 0 6px 40px 0 rgba(0, 0, 0, 0.19);
}

.profile-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: white;
}

.profile-job-title {
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: var(--collaboration3);
}

.profile-company {
  color: white;

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.profile-location {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-bottom: 30px;
  color: var(--collaboration3);
}

.profile-contacts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: var(--collaboration3);
}

.email-link {
  color: white;
}

.email-link:hover {
  text-decoration: none;
  color: var(--heritage);
}
