.key-decisions-spacer {
  height: 142.5px;
  width: 630px;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: rgba(248, 249, 250, 1);
  padding-top: 1px;
  margin-bottom: 15px;
}

.key-decisions-top-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
}

.key-decisions-top-row .fas {
  color: var(--collaboration2);
}

.key-decisions-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: inline-block;
  background-color: var(--collaboration1);
  color: white;
  margin-left: 17px;
  height: 25px;
  cursor: pointer;
  width: 25px;
  border: none;
  text-align: center;
  border-radius: 100px;
  flex-shrink: 0;
  font-size: 10px;
  margin-top: 10px;
}

.key-decisions-button:hover {
  background-color: var(--heritage);
}

.key-decisions-display-area {
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: flex-start;
  width: 100%;
  height: 100px;
}

.key-decisions-table {
  display: flex;
  flex-direction: column;
}

.key-decisions-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 11px;
  margin-bottom: 1px;
}

.key-decisions-decision {
  width: 550px;
}

.key-decisions-required_by {
  width: 60px;
}

.key-decisions-risk {
  width: 550px;
}

.key-decisions-urgency-high {
  background-color: var(--heritage);
  justify-content: center;
  display: flex;
  color: white;
  width: 60px;
}

.key-decisions-urgency-medium {
  background-color: rgb(218, 144, 98);
  justify-content: center;
  display: flex;
  color: white;
  width: 60px;
}

.key-decisions-urgency-low {
  background-color: var(--fluidity1);
  justify-content: center;
  display: flex;
  color: white;
  width: 60px;
}

/* Highlights */

.pdcp-advice-highlights-display-area {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.pdcp-advice-highlights-highlight-table {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.pdcp-advice-highlights-highlight-row {
  font-size: 11px;
  border-bottom: 1px lightgrey solid;
  height: 37px;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.pdcp-advice-highlights-highlight {
  width: 100%;

  padding-bottom: 10px;
  padding-top: 10px;
  padding-right: 10px;
}

.pdcp-advice-highlights-bullet {
  box-shadow: 0 2px 4px 0 var(--fluidity1), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  height: 5px;
  width: 5px;
  margin-right: 15px;
  margin-left: 15px;

  background-color: var(--fluidity1);

  border-radius: 100px;
}
