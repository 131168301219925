.market-insights-display {
  display: flex;
  flex-flow: wrap;
  flex-direction: row;
  justify-content: flex-start;
  width: 1650px;
}

.market-insights-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--integrity);
  color: var(--confident);
  width: 460px;
  height: 300px;

  display: flex;
  justify-content: flex-end;

  flex-direction: column;

  transition: all 1s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.market-insights-card:hover {
  -webkit-transform: scale(1.085);
  -ms-transform: scale(1.085);
  transform: scale(1.085);
  cursor: pointer;
}

.market-insights-card-link {
  margin: 30px;
  text-decoration: none;
}

.market-insights-card-link:hover {
  text-decoration: none;
}

.market-insights-card-link:hover .market-insights-card-title-row {
  background-color: rgba(0, 0, 0, 0.7);
  transition: 1s ease all;
}

.market-insights-card-title-row {
  background-color: rgba(0, 0, 0, 0.3);
  color: var(--integrity);
  margin-top: -93px;
  padding: 30px;
  transition: all 1s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.market-insights-card-image {
  height: 300px;
  width: 450px;
}
