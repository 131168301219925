/* CONTAINER */

.tpi-admin-container {
  margin-left: 5px;
}

/* TABLE AND BUTTON CONTAINER */

.tpi-admin-table-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
/* TABLE */

.tpi-admin-table {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-size: 12px;
}

/* BUTTON */

.tpi-control-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration1);
  color: var(--integrity);

  border-radius: 100px;
  width: 35px;
  height: 35px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: all 0s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.tpi-control-button:hover {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);

  cursor: pointer;
  background-color: var(--heritage);
  color: var(--integrity);
  text-decoration: none;
}

/* ROWS */

.tpi-admin-table-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  color: var(--integrity);
  background-color: var(--collaboration1);
}

.tpi-admin-table-row1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--integrity);
}

.tpi-admin-table-row2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--collaboration4);
}

/* CELLS */

.tpi-admin-table-header-cell {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 210px;
  padding: 10px;
  border: 1px var(--collaboration3) solid;

  flex-shrink: 0;
}

.tpi-admin-table-cell1-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 70px;
  padding: 10px;
  border: 1px var(--collaboration3) solid;
  flex-shrink: 0;
}

.tpi-admin-table-cell1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 70px;
  height: 30px;
  padding: 10px;
  border: 1px var(--collaboration3) solid;
  flex-shrink: 0;
}

.tpi-admin-table-cell2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 70px;
  height: 30px;
  padding: 10px;
  border: 1px var(--collaboration3) solid;
  background-color: var(--collaboration1);
  color: var(--integrity);
  flex-shrink: 0;
}

.tpi-admin-heading-row {
  display: flex;
  flex-direction: row;
}
