@import "../../components/estimates/EstimateSummaryTable.css";

.options-analysis-table-container {
  display: flex;
  flex-direction: row;
  width: 1560px;
  align-items: flex-start;
  --size: 1560px;
}

.options-analysis-table {
  margin-bottom: 15px;
  width: var(--size);
  font-size: 11px;
}

.options-analysis-table-options-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration1);
  color: white;
  height: 45px !important;
  width: 45px;
  padding: 8px;
  margin-left: 15px;
  border: none;
  flex-shrink: 0;
  user-select: none;
  border-radius: 50%;

  cursor: pointer;
}

.options-analysis-table-options-button:hover,
.options-analysis-table-options-button:active,
.options-analysis-table-options-button.clicked {
  background-color: var(--heritage);
}

/* HEADERS */

.options-analysis-table-heading-row {
  display: flex;
  flex-direction: row;
  width: var(--size);
  font-weight: bold;
  height: 50px;
  color: var(--integrity);
  background-color: var(--collaboration1);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.options-analysis-table-heading-row > div {
  font-weight: bold;
}

/* ROWS */

.options-analysis-table-row {
  display: flex;
  flex-direction: row;
  background-color: var(--integrity);
  color: var(--confident);
  width: var(--size);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* CELLS */

.options-analysis-table-cell {
  border-bottom: 1px solid grey;
  border-right: 1px solid grey;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.options-analysis-table-cell.description-cell {
  min-width: 460px;
  padding: 10px;
  justify-content: flex-start;
  border-left: 1px solid grey;
}

.options-analysis-table-cell.quantity-cell {
  min-width: 220px;
  display: flex;
  flex-direction: column;
  padding: 5px 0 5px 0;
}

.options-analysis-table-cell.quantity-cell > .sub-quantity-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 20px;
}

.options-analysis-table-cell.quantity-cell > .sub-quantity-cell.divider {
  height: 2px;
  width: 80%;
  border-top: 1px solid var(--collaboration3);
  margin: 0 0 5px 0;
}

.options-analysis-table > .card {
  margin: 30px 0 0 0;
  padding: 10px;
  border-radius: 0;
}

.options-analysis-table > .legend {
  display: flex;
  flex-direction: row;
  gap: 15px;
  border: 1px solid grey;
}

.options-analysis-table > .legend > .legend-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: auto;
}

.estimate-summary-table-row-link {
  height: auto;
  width: 100%;
}

.options-analysis-table-row-container {
  display: flex;
  flex-direction: row;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.options-analysis-table-row-upload {
  margin-left: 25px;
}

.options-summary {
  height: 35px !important;
  width: 35px !important;
  margin: 0px 0px 0px 10px;
}

.options-analysis-table-cell.breakdown-quantity-cell {
  min-width: 125px;
}

.options-analysis-table-cell.breakdown-description-cell {
  width: 1000px;
  justify-content: flex-start;
  padding-left: 10px;
  overflow: hidden;
}

.options-analysis-table-heading-row.breakdown-heading-row {
  height: 25px;
  width: auto;
}

.options-analysis-table-container.breakdown-container {
  --size: auto;
  font-size: medium;
  margin: 25px 25px 0px 25px;
}

.options-analysis-table-row.breakdown-heading {
  background-color: var(--fluidity3);
  font-weight: bold;
  color: white;
}

.options-analysis-table-row.breakdown-subtotal {
  background-color: var(--fluidity2);
  font-weight: bold;
  color: white;
}

.options-analysis-table-row.breakdown-total {
  background-color: var(--fluidity1);
  font-weight: bold;
  color: white;
}

.options-analysis-table-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
