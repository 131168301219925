.article-profile-image {
  height: 200px;
  width: 160px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 30px;

  color: var(--integrity);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.article-profile-image-banner {
  margin-left: -160px;

  padding-top: 6px;
  width: 160px;

  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
