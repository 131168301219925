/* Decisions */

.decisions-advice-display-area {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.decisions-advice-table {
  display: flex;
  flex-direction: column;
}

.decisions-advice-row {
  font-size: 11px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px lightgrey solid;
}

.decisions-advice-bullet {
  box-shadow: 0 2px 4px 0 var(--collaboration1),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);

  height: 5px;
  width: 5px;
  margin-right: 15px;
  margin-left: 15px;

  background-color: var(--collaboration1);

  border-radius: 100px;
}

.decisions-advice-bullet-spacer {
  height: 5px;
  width: 5px;
  margin-right: 15px;
  margin-left: 15px;
}

/* DECISIONS */

.decisions-advice-decision {
  width: 365px;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-right: 10px;
  border-right: 1px lightgrey solid;
}

.decisions-advice-required_by {
  width: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

/* GLOSSARY */

.decisions-advice-glossary-term {
  width: 250px;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 10px;
  border-right: 1px lightgrey solid;
}

.decisions-advice-glossary-definition {
  width: 250px;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 10px;
}

.cost-report-comparison-chart-hint{
color: var(--collaboration2);
font-size: 13px;
margin: 10px;
}