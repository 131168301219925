.admin-localisation-container {
  display: flex;
  flex-direction: row;
}

.admin-localisation-input {
  height: 25px;
  margin-bottom: 20px;
}

.admin-localisation-languages {
  margin-bottom: 20px;
}

.admin-localisation-controls {
  display: flex;
  flex-direction: column;
  margin-top: 65px;
}

.admin-localisation-table {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 900px;
  min-height: 730px;
  margin-right: 30px;
}

.admin-localisation-table-footer-right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 500px;
}
