.account-filters-container {
  margin-bottom: 55px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

/* INPUTS */

.account-filter-input-col {
  font-size: 12px;
  margin-bottom: 10px;
}

.account-filter-input-icon {
  color: var(--collaboration2);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  width: 40px;
}

.account-filter-input-icon-focus {
  color: var(--heritage);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  width: 40px;
}

.account-filter-input-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 180px;
}

.account-filter-search-input,
.account-filter-search-input-pair,
.account-filter-search-input-pair-second {
  height: 20px;
}

.account-filter-search-input-pair,
.account-filter-search-input-pair-second {
  width: 60px;
}

.account-filter-search-input {
  width: 130px;
}

.account-filter-input-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 130px;
}
