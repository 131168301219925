.pdcp-currency {
  min-height: 54px;

  font-size: 15px;

  display: flex;
  flex-flow: wrap;
  flex-direction: row;
  justify-content: left;
  align-items: center;

  padding: 15px;
}

.pdcp-project-text1 {
  margin-right: 5px;
}

.pdcp-project-title {
  color: var(--heritage);
  margin-right: 5px;
}

.pdcp-project-text2 {
  margin-right: 5px;
}

.pdcp-project-currency {
  color: var(--fluidity1);
}
