.estimate-drawings {
  margin-top: 30px;
}

.drawings-row {
  display: flex;
  flex-direction: row;
}

.drawings-container {
  display: flex;
  flex-direction: column;
}

.drawings-text {
  margin-bottom: 30px;
}
