.project-dashboard-estimate-metric-display {
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 500px;
  min-height: 30px;

  margin-bottom: 5px;
  margin-top: 5px;
}

.project-dashboard-estimate-metric-column {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.project-dashboard-estimate-metric-display-value0 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 200px;

  font-size: 14px;
  color: var(--heritage);
}

.project-dashboard-estimate-metric-display-value1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 200px;

  font-size: 14px;
  color: var(--collaboration1);
}

.project-dashboard-estimate-metric-display-value2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 200px;

  font-size: 14px;
  color: var(--fluidity1);
}

.project-dashboard-estimate-metric-display-metric {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  font-size: 11px;
  color: var(--confidence);
  width: 290px;

  word-wrap: break-word;
}

.project-dashboard-estimate-metric-top-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
}

.project-dashboard-estimate-metric-top-row .fas {
  color: var(--collaboration3);
}
