.costplanning-background {
  margin: 0 auto;
  width: 1620px;
}

.costplanning-top-row {
  height: 50px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 1560px;
}

.costplanning-control-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;

  justify-content: flex-start;
}

.costplanning-control-column,
.costplanning-control-column-backer {
  background-color: rgba(248, 249, 250, 1);

  width: 215px;
  height: 800px;
  margin-top: -15px;

  margin-right: 25px;
  border-radius: 0 0 50px 0;
}

.costplanning-control-column-backer {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.cost-planning-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.label-costplanning-name-container {
  display: flex;
  width: 1330px;
  margin: 0 auto;
  margin-bottom: 30px;
}

.costplanning-back-button {
  display: inline-block;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration1);
  color: white;
  padding: 5px;
  width: 150px;
  height: 35px;
  border: none;
  text-align: center;
  border-radius: 0px;
  margin-left: 60px;
}

.label-costplanning-name {
  font-size: 20px;
  margin-left: 20px;
  color: var(--collaboration1);
}

.costplanning-back-button:hover {
  background-color: var(--heritage);
  text-decoration: none;
  color: white;
}

.estimate-select-box {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 10px;
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 20px;
  border: 0px;
  outline: 0px;
}

.display-date {
  display: inline-block;
  margin-top: 10px;
  font-size: 18px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

.estimate-select-box:hover {
  padding: 10px;
}

.estimate-select-box:active {
  padding: 10px;
  background-color: white;
}

.cost-planning-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1280px;
  margin-bottom: 10px;
}

.cost-planning-stage-area {
  padding-top: 20px;
  margin-top: -10px;
  margin-bottom: 5px;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
}
