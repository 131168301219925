/* TABLE */

.estimate-summary-table {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 15px;
  margin-right: 30px;
}

/* ROWS */

.estimate-summary-table-head-row {
  display: flex;
  flex-direction: row;
  background-color: var(--collaboration2);
  color: var(--integrity);
  font-weight: bold;

  width: 1000px;
  height: 40px;
}

.estimate-summary-table-row {
  display: flex;
  flex-direction: row;

  background-color: var(--integrity);
  color: var(--confident);

  width: 1000px;
  height: 40px;

  user-select: none;
}

.estimate-summary-table-row-link {
  display: flex;
  flex-direction: row;

  background-color: var(--integrity);
  color: var(--confident);

  width: 1000px;
  height: 40px;

  transition: all 0s cubic-bezier(0.25, 0.8, 0.25, 1);
  text-decoration: none;
}

.estimate-summary-table-row-link:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: rgb(223, 223, 223);
  color: var(--confident);

  cursor: pointer;
  text-decoration: none;

  -webkit-transform: scale(1.01);
  -ms-transform: scale(1.01);
  transform: scale(1.01);
}

.estimate-summary-table-total-row {
  display: flex;
  flex-direction: row;
  font-weight: bold;

  background-color: var(--collaboration1);
  color: var(--integrity);

  width: 1000px;
  height: 40px;
}

/* CELLS */

.estimate-summary-table-section-cell {
  width: 50px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.estimate-summary-table-description-cell {
  width: 570px;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.estimate-summary-table-description-cell-ref {
  width: 520px;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.estimate-summary-table-action {
  color: var(--heritage);
  font-size: 15px;
  display: flex;
  align-items: center;
}

.estimate-summary-table-quantity-cell {
  width: 100px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.estimate-summary-table-unit-cell {
  width: 65px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.estimate-summary-table-rate-cell {
  width: 140px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.estimate-summary-table-subtotal-cell {
  width: 125px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 10px;

  height: 40px;

  border-bottom: 1px solid grey;
}

/* BORDERS */

.estimate-summary-table-description-cell,
.estimate-summary-table-description-cell-ref,
.estimate-summary-table-section-cell,
.estimate-summary-table-quantity-cell,
.estimate-summary-table-unit-cell,
.estimate-summary-table-rate-cell {
  border-bottom: 1px solid grey;
  border-right: 1px solid grey;
}

.estimate-summary-table-total-row .estimate-summary-table-description-cell,
.estimate-summary-table-total-row .estimate-summary-table-description-cell-ref,
.estimate-summary-table-total-row .estimate-summary-table-quantity-cell,
.estimate-summary-table-total-row .estimate-summary-table-unit-cell,
.estimate-summary-table-total-row .estimate-summary-table-rate-cell,
.estimate-summary-table-total-row .estimate-summary-table-subtotal-cell {
  border-bottom: none;
}
