.post-contract-cashflow-container {
  margin-top: 30px;
}

.post-contract-cashflow-graph-container {
  width: 700px;
  height: 400px;
  padding: 10px;
  padding-right: 20px;
  padding-bottom: 50px;
  background-color: var(--integrity);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
