.commentary {
  margin-top: 30px;
}

.commentary-flex-box {
  display: flex;
  margin-bottom: 30px;
  width: 1600px;
}

.commentary-buttons-container {
  display: flex;
  flex-direction: column;
}

.commentary-buttons {
  display: flex;
  flex-direction: row;
}

.commentary-move-buttons {
  display: flex;
  flex-direction: column;
  margin-left: 17px;
}

.commentary-move-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 21px;
  height: 21px;
  margin: 1px;
  font-size: 11px;
  color: white;
  background-color: var(--collaboration1);
  border: none;
  border-radius: 100px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.commentary-move-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.commentary-edit-button,
.commentary-delete-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: inline-block;
  background-color: var(--collaboration1);
  color: white;
  margin-left: 17px;
  height: 45px !important;
  cursor: pointer;
  width: 45px;
  padding: 8px;
  border: none;
  text-align: center;
  border-radius: 100px;
  flex-shrink: 0;
}

.commentary-delete-button:hover {
  background-color: var(--strength1);
}

.commentary-edit-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.commentary-box {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px;
  width: 1430px;
  background: var(--integrity);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.commentary-box-title {
  font-size: 24px;
  color: var(--heritage);
}

.ect-container {
  width: 100%;
}
