.pm-tooltip {
  text-decoration: none;
  opacity: 1;
}

.pm-tooltip:hover {
  background-color: var(--heritage);
  text-decoration: none;
}

.pm-tooltip-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
}

.pm-tooltip-link:hover {
  text-decoration: none;
}

.pm-tooltip-project-image {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  width: 50px;
  object-fit: cover;
  margin-right: 10px;
}

.pm-tooltip-text {
  margin-left: 5px;
}

.pm-tooltip:hover .pm-tooltip-text {
  color: var(--integrity);
}
