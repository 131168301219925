.portfolio-edit-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 15px;
  margin-top: 15px;
}

.portfolio-edit-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 110px;
}

.portfolio-edit-selection-number {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-size: 15px;
  color: var(--heritage);
  width: 50px;
  height: 30px;
  border-radius: 30px;

  background-color: var(--integrity);
  -moz-box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.24);
}

.portfolio-edit-modal-button {
  background-color: var(--collaboration1);
  color: var(--integrity);
  height: 30px;
  padding: 10px;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 50px;

  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.portfolio-edit-modal-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.portfolio-edit-controls-input {
  width: 110px;
}
