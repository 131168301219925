.create-benchmark-container {
  padding-top: 5px;
  z-index: 1;
}

.create-benchmark-form-container {
  display: flex;
  flex-direction: row;
}

.create-benchmark-form-container-column-details {
  width: max-content;
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  margin-right: 20px;
}

.create-benchmark-form-container-column-rows {
  width: max-content;
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  margin-right: 20px;
}

.create-benchmark-form-container-column-metrics {
  width: max-content;
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  margin-right: 20px;
}

.benchmarks-row-code {
  width: 75px;
}

.benchmarks-row-description {
  width: 250px;
}

.benchmarks-row-elemental-analysis {
  width: 160px;
}

.benchmarks-row-value {
  width: 100px;
}

.benchmarks-row-rate {
  margin-left: 20px;
  width: 75px;
}

.benchmarks-metric-code {
  width: 50px;
  margin-right: 5px;
}

.benchmarks-metric-metric {
  width: 250px;
  margin-right: 5px;
}

.benchmarks-metric-quantity {
  width: 80px;
  margin-right: 5px;
}

.benchmarks-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.create-project-panel .display-4 {
  font-size: 20px;
}

.quick-benchmark-button-container {
  height: 45px;
  width: 100%;
  display: flex;
  justify-content: right;
  margin-top: 5px;
}
