.post-contract-table {
  width: 500px;
}

.post-contract-table .pct-container {
  width: 500px;
  box-shadow: none;
}

.post-contract-table .pct {
  background: white;
  box-shadow: none;
}

.post-contract-table .pct-headings-row {
  height: 25px;
}

.post-contract-table .pct-headings-container {
  background: white;
  width: max-content;
  color: var(--collaboration1);
}

.post-contract-table .pct-heading,
.post-contract-table .pct-heading-number {
  color: var(--collaboration1);
}

.post-contract-table .pct-description {
  background-color: var(--collaboration4);
  border-right: solid lightgrey 1px;

  width: 110px;
  display: flex;
  flex-direction: row;
  align-items: center;

  min-height: 30px;

  border-bottom: solid lightgrey 1px;
  padding: 3px;
}

.post-contract-table .pct-row .pct-value {

  background: var(--integrity);
}

.post-contract-table .pct-heading,
.post-contract-table .pct-heading-description,
.post-contract-table .pct-heading-number {
  border-right: solid lightgrey 1px;
  border-bottom: solid lightgrey 1px;
}

.post-contract-table .pct-row-page-total .pct-description,
.post-contract-table .pct-row-page-total .pct-value,
.post-contract-table .pct-row-page-total .pct-number,
.post-contract-table .pct-row-page-total .pct-quantity{

  background: linear-gradient(
    0deg,
    rgb(254, 249, 255) 0%,
    rgb(219, 215, 223) 100%
  );

  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: solid lightgrey 1px;

}
.post-contract-table .pct-row-page-total .pct-description {

  border-left: none;
 border-right: solid lightgrey 1px; 

}

.post-contract-table .pct-row-page-total .pct-quantity {

   border-left: none;
  border-right: solid lightgrey 1px; 

}

.post-contract-table .pct-row-total .pct-description,
.post-contract-table .pct-row-total .pct-value,
.post-contract-table .pct-row-total .pct-number,
.post-contract-table .pct-row-total .pct-quantity {
  color: var(--integrity);
  background: linear-gradient(
    0deg,
    rgb(145, 143, 146) 0%,
    rgb(85, 87, 90) 100%
  );

  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: none;

  font-weight: 500;
}
.post-contract-table  .pct-number{
  justify-content: right;
}
