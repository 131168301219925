.project-top-row {
  height: 50px;
  width: 1560px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.project-background {
  display: flex;
  flex-direction: row;

  width: 100%;
  margin: 0 auto;
  margin-top: 12px;
}

.project-control-container {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}

.project-control-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;

  justify-content: space-between;
}

.project-control-column,
.project-control-column-backer {
  background-color: rgba(248, 249, 250, 1);

  width: 215px;
  height: 820px;
  margin-top: -20px;

  margin-right: 30px;
  border-radius: 0 0 50px 0;
}

.project-control-column-backer {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-top: -40px;
}

.project-control-column-content-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.project-title {
  margin-bottom: 12px;
}

.edit-project-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration1);
  color: white;
  width: 150px;
  height: 35px;
  border: none;
  text-align: center;
  line-height: 35px;
  margin-top: 30px;
  margin-bottom: 30px;

  border-radius: 100px;
}

.edit-project-button:hover {
  background-color: var(--heritage);
  text-decoration: none;
  color: white;
}

.project-menu-button-future {
  background-color: var(--collaboration3);
  color: var(--collaboration2);
}

.project-menu-button-future:hover {
  cursor: pointer;
}

/* Wrappers */

.project-row2 {
  display: flex;
  margin-top: 10px;
  justify-content: left;
  align-items: left;
  width: 900px;
}

.big-image {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 1000px;
  height: 700px;
}

.project-cost-plan-selectors {
  display: flex;
  flex-direction: column;
}

/* Highlights */

.project-highlights-card {
  border-radius: 0px;
  background: var(--collaboration1);
  color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.project-row1 {
  display: flex;
  width: 900px;
}

.dashboard-budget-gauge-box {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: white;

  width: 400px;
  height: 300px;
}

.budget-gauge {
  padding-left: 20px;
}

.dashboard-highlights {
  display: flex;
  text-align: center;
  flex-flow: wrap;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: rgba(248, 249, 250, 1);
  width: 300px;
  margin-right: 30px;
  margin-bottom: 30px;
  height: 300px;
}

.dashboard-highlight-item {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: white;
  text-align: center;
  font-size: 13px;
  padding-top: 1px;
  width: 115px;
  margin-left: 10px;
  height: 70px;
  margin-bottom: 10px;
}

.highlight-text {
  font-weight: bold;
  width: 115px;
}

.highlight-label {
  width: 115px;
}
