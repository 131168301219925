.benchmark-top-row {
  height: 50px;
  width: 1560px;
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
}

.benchmarking-control-panel-container {
  display: flex;
  flex-direction: row;

  width: 100%;
  margin: 0 auto;
}

.benchmark-advanced-search-flex-scroll {
  height: 650px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.benchmark-advanced-search-flex {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-between;

  margin-right: 30px;
  padding-top: 20px;
  padding-left: 12px;
}

#BenchMarkingFilter::-webkit-scrollbar {
  width: 10px;
}

#BenchMarkingFilter::-webkit-scrollbar-thumb {
  background-color: var(--collaboration3);
  /* -webkit-box-shadow: inset 1px 1px 0 rgba(0,0,0,0.10),inset 0 -1px 0 rgba(0,0,0,0.07);  */
}

.benchmark-advanced-search-flex,
.benchmark-advanced-search-flex-backer {
  background-color: rgba(248, 249, 250, 1);

  width: 215px;
  height: 800px;
  margin-top: -15px;

  margin-right: 30px;
  border-radius: 0 0 50px 0;
}

.benchmark-control-title {
  margin-bottom: 23px;
}

.benchmark-advanced-search-flex-backer {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-top: -15px;
}

.benchmark-table-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.benchmarking-control-panel-container .path {
  margin-bottom: 10px;
}

.benchmark-control-table {
  margin-right: 30px;
}

.benchmark-table-flex {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.benchmark-control-delete-button {
  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration1);
  color: white;

  width: 45px;
  height: 45px;

  border-radius: 45px;

  margin-bottom: 15px;
}

.benchmark-control-export-button {
  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration1);
  color: white;

  width: 45px;
  height: 45px;

  border-radius: 45px;

  margin-bottom: 15px;
}

.benchmark-control-export-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.benchmark-selection-loading {
  width: 50px;
  height: 50px;
}

.benchmark-control-delete-button:hover {
  background-color: var(--strength1);
  cursor: pointer;
}

.b-c-search-input,
.b-c-search-input-pair,
.b-c-search-input-pair-second {
  height: 20px;
}

.b-c-search-input-pair,
.b-c-search-input-pair-second {
  width: 60px;
}

.b-c-search-input {
  width: 130px;
}

.benchmark-advanced-search-flex .input-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 130px;
}

.benchmark-advanced-search-flex .input-col {
  font-size: 12px;
  margin-bottom: 10px;
}

.benchmark-advanced-search-flex .input-icon {
  color: var(--collaboration2);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  width: 40px;
}

.benchmark-advanced-search-flex .input-icon-focus {
  color: var(--heritage);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  width: 40px;
}

.input-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 180px;
}

.no-data-available {
  text-align: center;
  width: 100%;
  color: var(--heritage);
}

.customised {
  margin-top: 0px !important;
}

.custom-export-all {
  border-radius: 45px;
}


/* Hover drop down button list css */

.dropbtn {
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdown-ellipse-content {
  margin-top: -60px;
  margin-left: -10px;
  width: 60px;
  border-radius: 60px;
  padding-top: 15px;
}

.dropdown .dropdown-content .benchmark-control-upload-button {
  margin-left: 9px;
}

.bm-vmenu-customized:hover .fas {
  color: white;
}