.Dropzone {
  height: 100px;
  width: 100px;
  background-color: var(--integrity);
  border: 2px dashed var(--collaboration2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
}

.Highlight {
  background-color: var(--heritage);
}

.Icon {
  opacity: 0.3;
  height: 32px;
  width: 32px;
}

.FileInput {
  display: none;
}
