.benchmarking-cost-summary-comparison {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 270px;
  width: 500px;
}

.benchmarking-cost-summary-comparison-legend {
  display: flex;
  flex-direction: column;
}

.benchmarking-cost-summary-comparison-chart {
  width: 300px;
}

.benchmark-legend-label {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.benchmark-legend-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  width: 170px;
}

.benchmark-legend-circle-container {
  width: 20px;
  height: 20px;
}

.benchmark-legend-circle {
  width: 16px;
  height: 16px;
  border-radius: 100px;
  margin-right: 8px;
}

.legend-svg1 {
  position: absolute;
  z-index: 1;
  width: 67px;
  height: 57px;

  margin-left: 6px;
}

.legend-svg2 {
  position: absolute;
  height: 25px;
  width: 150px;

  margin-top: 115px;
  margin-left: 85px;
  z-index: 1;
}

.legend-svg-line1 {
  stroke-width: 2;
  stroke: var(--heritage);
  fill: none;

  stroke-dasharray: 70px;
  stroke-dashoffset: 70px;
  animation: move 2s linear;
  animation-fill-mode: forwards;
}

.legend-svg-line2 {
  stroke-width: 2;
  stroke: var(--heritage);
  fill: none;

  stroke-dasharray: 155px;
  stroke-dashoffset: 155px;
  animation: move 2s linear;
  animation-fill-mode: forwards;
}

.legend-project-label {
  position: absolute;
  height: 25px;
  margin-top: 22px;
  margin-left: 13px;
  font-size: 14px;
  z-index: 50;
 }

.legend-benchmark-label {
  position: absolute;
  width: 125px;
  margin-top: 117px;
  margin-left: 105px;

  font-size: 14px;
  z-index: 50;
}

@keyframes move {
  100% {
    stroke-dashoffset: 0;
  }
}
