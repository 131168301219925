.ve-status-summary-table {
  display: flex;
  flex-direction: column;
  padding: 10px;
  font-size: small;
}

.ve-status-summary-table-row {
  display: flex;
  flex-direction: row;
  padding: 10px;
  border-bottom: 1px solid var(--collaboration4);
}

.ve-status-summary-table-row.APPROVED {
  --bullet-color: var(--natural1);
}

.ve-status-summary-table-row.FORREVIEW {
  --bullet-color: var(--heritage2);
}

.ve-status-summary-table-row.REJECTED {
  --bullet-color: var(--strength1);
}

.ve-status-summary-table-row:last-child {
  /* total row */
  font-weight: bold;
  border-bottom: none;
  border-top: 2px solid var(--collaboration3);
}

.ve-status-summary-table-row-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ve-status-summary-table-row-cell.right {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}

.ve-status-summary-table-row-cell > .bullet {
  display: flex;
  box-shadow: 0 2px 4px 0 var(--bullet-color), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  height: 5px;
  width: 5px;
  margin-right: 15px;
  margin-left: 15px;
  background-color: var(--bullet-color);
  border-radius: 50%;
}
