.pdcp-benchmark-comparison-graph {
  padding: 10px;
  height: 210px;
}

.pdcp-benchmark-comparison-graph-part {
  font-size: 12px;
  padding-left: 10px;
  padding-top: 10px;
}
