.benchmark-upload {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  width: 650px;
  padding: 15px;
}

/* FILES */

.benchmark-upload-files {
  height: 150px;
  width: 590px;

  padding: 10px;

  overflow-y: scroll;
  overflow-x: scroll;
  border: 1px solid lightgrey;

  display: flex;
  flex-direction: column;

  font-size: 13px;
}

.benchmark-upload-files-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  height: 20px;
}

.benchmark-upload-files-part {
  min-width: max-content;
  height: 20px;

  margin-right: 5px;
}

.benchmark-upload-files-name {
  min-width: max-content;
  height: 20px;

  margin-right: 5px;
}

.benchmark-upload-message {
  min-width: max-content;

  height: 20px;
  margin-right: 5px;

  color: var(--natural1);
}

.benchmark-upload-message-error {
  min-width: max-content;

  height: 20px;
  margin-right: 5px;

  color: var(--strength1);
}

/* UPLOAD CONTROLS */

.benchmark-upload-drop {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration3);
  color: white;
  padding: 5px;
  width: 100px;
  height: 100px;
  border: none;
  text-align: center;
  border-radius: 100px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.benchmark-upload-drop-highlight {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--heritage);
  color: white;
  padding: 5px;
  width: 100px;
  height: 100px;
  border: none;
  border-radius: 100px;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.benchmark-upload-drop:hover {
  background-color: var(--heritage);
  text-decoration: none;
  color: white;
  cursor: pointer;
}

.benchmark-upload-control-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  margin-bottom: 30px;
  margin-top: 15px;
}

.benchmark-upload-control {
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.benchmark-upload-control-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  width: 465px;
}

.benchmark-upload-control-label {
  width: 190px;
  min-width: 190px;
  margin-right: 5px;
  font-size: 14px;
}

.benchmark-upload-control-input {
  width: 270px;
  height: 25px;
}

.benchmark-upload-button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.benchmark-upload-error-container {
  padding-top: 5px;
}

.benchmark-upload-document-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: white;
  background-color: var(--collaboration1);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-size: 15px;

  width: 270px;
  height: 25px;

  user-select: none;
}

.benchmark-upload-document-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
  text-decoration: none;
  color: white;
}
