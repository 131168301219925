/* GUEST FOOTER */

.clarity-footer-container-guest {
  background: rgba(85, 87, 90, 0.6);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  position: fixed;
  bottom: 0;

  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;

  z-index: 10000;
}

.copyright-guest {
  color: white;
}

/* AUTHENTICATED FOOTER */

.clarity-footer-container {
  max-width: 1800px;
  min-width: 1000px;
  margin-left: 70px; /* Side Bar Spacing */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

hr.footer-hr {
  margin-top: 30px;
  margin-bottom: 30px;

  width: 1000px;
  display: block;
  border: none;
  color: white;
  height: 2px;
  background-image: linear-gradient(
    90deg,
    rgba(233, 237, 241, 0),
    var(--heritage),
    rgba(233, 237, 241, 0)
  );
}

.clarity-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  /* OPTIONAL */
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: rgba(248, 249, 250, 1);
  padding: 30px;
  border-radius: 30px 30px 0 0; */
}

.footer-privacy {
  color: var(--confident);
}

.footer-privacy:hover {
  color: var(--heritage);
}

.footer-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 200px;
}

/* ITEMS */

.ICCLogo-container {
  width: 400px;
  border-radius: 30px;
  margin-left: 100px;
  margin-right: 100px;
}

.ICCLogo {
  width: 400px;
  border-radius: 30px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 12px 40px 0 rgba(0, 0, 0, 0.19);

  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.ICCLogo:hover {
  -webkit-transform: scale(1.03);
  -ms-transform: scale(1.03);
  transform: scale(1.03);
}

.footer-arcadis-brand {
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.footer-arcadis-brand:hover {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}
