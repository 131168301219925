.timebar {
  height: 105px;
  width: 500px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.timebar-stacked-bar {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.timebar-background-bar {
  position: absolute;
  height: 15px;
  background-color: var(--collaboration2);
  /* background-color: var(--heritage); */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.timebar-fill-bar {
  position: absolute;
  height: 15px;
}

.timebar-fill {
  fill: var(--heritage);
  height: 15px;
}

.timebar-label-value {
  margin-right: 10px;
  font-size: 20px;
  color: var(--heritage);
}

.timebar-label {
  margin-top: 30px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  animation: fadein ease 3s;
}

.timebar-label-text {
  font-size: 13px;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
