.ve-table-row-detail-modal-wrapper {
  display: flex;
  flex-direction: row;
}

.ve-table-row-detail-modal-wrapper p.message {
  color: var(--heritage);
}

.ve-table-row-detail-modal-left-pane {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  flex-direction: column;
  width: 300px;
  font-size: 13px;
  overflow-y: auto;
  max-height: 700px;
}

.ve-table-row-detail-modal-left-pane-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 13px;
  overflow-y: auto;
  height: 650px;
  margin-bottom: auto;
}

.ve-table-row-detail-modal-vm-item {
  --section-color: transparent;
  min-height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--collaboration1);
  padding: 0 10px;
  border-left: 5px solid var(--section-color);
}

.ve-table-row-detail-modal-vm-item:hover,
.ve-table-row-detail-modal-vm-item:active,
.ve-table-row-detail-modal-vm-item.selected {
  cursor: pointer;
  background-color: var(--collaboration4);
  padding-left: 10px;
}

.ve-table-row-detail-modal-vm-item.vm-items-total {
  --status-color: var(--collaboration4);
  background-color: var(--status-color);
  color: black;
  cursor: auto;
}

.ve-table-row-detail-modal-vm-item.vm-items-total.approved {
  --status-color: var(--natural4);
}

.ve-table-row-detail-modal-vm-item.vm-items-total.forreview {
  --status-color: var(--built3);
}

.ve-table-row-detail-modal-vm-item.vm-items-total.rejected {
  --status-color: var(--strength4);
}

.ve-table-row-detail-modal-vm-item.flag_save_pending {
  --section-color: var(--strength1);
}

.ve-table-row-detail-modal-vm-item.flag_marked_for_deletion {
  color: var(--strength1);
  text-decoration: line-through;
}

.ve-table-row-detail-modal-vm-item > .vm-item-title,
.ve-table-row-detail-modal-vm-item > .vm-item-value {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ve-table-row-detail-modal-vm-item > .vm-item-title {
  width: 164px;
  margin-right: 5px;
}

.ve-table-row-detail-modal-vm-item > .vm-item-value {
  width: 80px;
  text-align: right;
}

.ve-table-row-detail-modal-vm-item.vm-items-total > .vm-item-title {
  width: 60px;
}

.ve-table-row-detail-modal-vm-item.vm-items-total > .vm-item-value {
  width: 184px;
}

.ve-table-row-detail-modal-right-pane {
  width: 1050px;
  overflow: hidden;
}

.ve-table-row-detail-modal-vm-item-contents {
  --size: 650px;
  min-height: var(--size);
  max-height: var(--size);
  overflow-y: auto;
  overflow-x: hidden;
  width: 1035px;
  margin-left: 15px;
  padding: 15px;
  display: flex;
  flex-direction: column;
}

.ve-item-not-selected {
  --size: 650px;
  min-height: var(--size);
  max-height: var(--size);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  color: var(--collaboration1);
  padding-left: 10px;
}

.ve-item-not-selected > div.text {
  text-align: center;
}

.ve-item-not-selected > i {
  color: var(--collaboration4);
  padding-bottom: 20px;
}

.ve-table-row-detail-modal-vm-item-contents > .field-row {
  display: flex;
  flex-direction: row;
}

.ve-table-row-detail-modal-vm-item-contents > .field-row.spacer {
  min-height: 15px;
}

.ve-table-row-detail-modal-vm-item-contents .ve-item-field {
  display: flex;
  flex-direction: column;
  padding: 15px;
  width: 100%;
}

.ve-item-field > .field-label {
  margin-bottom: 5px;
}

.ve-item-field > .field-value {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.ve-item-field-icon {
  --size: 30px;
  min-height: var(--size);
  max-height: var(--size);
  min-width: var(--size);
  max-width: var(--size);
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  color: white;
}

.ve-table-row-detail-modal-left-pane-list .ve-item-field-icon,
.ve-table-row-detail-modal-vm-item.vm-items-total .ve-item-field-icon {
  --size: 24px;
}

.ve-table-row-detail-modal-left-pane-list .ve-item-field-icon:first-of-type,
.ve-table-row-detail-modal-vm-item.vm-items-total
  .ve-item-field-icon:first-of-type {
  margin-left: 5px;
}

.ve-item-field > .field-value > .text {
  width: 100%;
  min-height: 40px;
  border: 1px solid var(--collaboration2);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  color: var(--collaboration1);
}

.ve-item-field.readonly > .field-value > .text {
  border: 1px solid var(--collaboration4);
}

.ve-item-field.ref {
  width: 150px;
}

.ve-item-field.description > .field-value > .text {
  height: 150px;
}

.ve-table-row-detail-modal-footer {
  min-height: 50px;
  max-height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid var(--collaboration4);
}

.ve-table-row-detail-modal-footer.left-pane {
  justify-content: center;
}

.ve-table-row-detail-modal-footer.right-pane {
  justify-content: flex-end;
  padding-left: 30px;
  padding-right: 40px;
}

.ve-table-row-detail-modal-footer.right-pane.message-only {
  background-color: var(--collaboration4);
}

.ve-table-row-detail-modal-footer.right-pane > .message {
  margin-left: auto;
}

.ve-table-row-detail-modal-footer.right-pane.message-only > .message {
  font-size: 14px;
  font-weight: bold;
  color: var(--strength1);
}

.ve-table-row-detail-modal-footer > .button {
  margin: 0px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ve-table-row-detail-modal-footer > .button > span.text {
  padding-left: 10px;
}
