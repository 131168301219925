.benchmarking-disclaimer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px 40px;
  min-width: 850px;
  max-width: 850px;
}

.benchmarking-disclaimer-acceptance {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.benchmarking-disclaimer-acceptance > input[type="checkbox"] {
  cursor: pointer;
  /* hide native checkbox */
  -webkit-appearance: none;
  appearance: none;
  background-color: white;
  margin: 0;
  margin-top: 5px;
  font: inherit;
  color: black;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid var(--collaboration2);
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

.benchmarking-disclaimer-acceptance > input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em white;
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

.benchmarking-disclaimer-acceptance > input[type="checkbox"]:checked {
  border: none;
  background-color: var(--heritage);
}

.benchmarking-disclaimer-acceptance > input[type="checkbox"]:checked::before {
  transform: scale(1);
}

.benchmarking-disclaimer-acceptance > label {
  margin-left: 10px;
  margin-bottom: 0;
  cursor: pointer;
  font-weight: bold;
}

.benchmarking-disclaimer-acceptance-close {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.benchmarking-disclaimer > .modal-save-button,
.benchmarking-disclaimer > .modal-close-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 15px;
  width: 150px;
  height: 30px;
  border: none;
  cursor: pointer;
}

.benchmarking-disclaimer > .modal-save-button {
  background-color: var(--natural1);
}

.benchmarking-disclaimer > .modal-close-button {
  background-color: var(--collaboration2);
}

.benchmarking-disclaimer > .modal-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  color: red;
}
