.portfolio-cashflow {
  width: 500px;
  height: 260px;
}

.portfolio-cashflow-overlay {
  position: absolute;
  width: 500px;
  height: 260px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.portfolio-cashflow-overlay .display-4 {
  font-size: 20px;
  font-weight: 500;
}

.portfolio-cashflow-text-field {
  width: 430px;
  background-color: rgba(255, 255, 255, 0.92);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  border-radius: 50px;
  padding: 30px;
}
