/* Pagination */
.pagination-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  width: 100%;

  margin-top: 10px;
  margin-bottom: 10px;
}

.pagination-input-container {
  width: 40px;
}

.pagination-input {
  width: inherit;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: 1px solid #ddd;

  -moz-box-shadow: inset 0 1px 3px var(--collaboration1);
  -webkit-box-shadow: inset 0 1px 3px var(--collaboration1);
  box-shadow: inset 0 1px 3px var(--collaboration1);
  transition: all 1s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.pct-pagination {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: lightgrey;
  width: 100%;
}

/* Page Control */

.pagination-page-control .pagination {
  margin: 0px;
}

.pagination-page-control a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--integrity);

  text-decoration: none;
  font-weight: bold;
}

.pagination-page-control .tab,
.pagination-page-control .tab-selected,
.pagination-page-control .tab-arrow-right,
.pagination-page-control .tab-arrow-left,
.pagination-page-control .tab-arrow-inactive-right,
.pagination-page-control .tab-arrow-inactive-left {
  font-size: 15px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin: 3px;
  user-select: none;
  border-radius: 100px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.pagination-page-control .tab,
.pagination-page-control .tab-selected,
.pagination-page-control .tab-arrow-right,
.pagination-page-control .tab-arrow-left {
  background-color: var(--collaboration1);
}

.pagination-page-control .tab,
.pagination-page-control .tab-selected {
  width: 40px;
  height: 40px;
}

.pagination-page-control .tab:hover,
.pagination-page-control .tab-selected:hover,
.pagination-page-control .tab-arrow-right:hover,
.pagination-page-control .tab-arrow-left:hover {
  background-color: var(--heritage);
  cursor: pointer;
  text-decoration: none;
}

.pagination-page-control .tab-selected {
  background-color: var(--heritage);
}

.pagination-page-control .tab-arrow-left,
.pagination-page-control .tab-arrow-right {
  width: 30px;
  height: 30px;
}

.pagination-page-control .tab-arrow-right {
  margin-left: 20px;
  margin-top: 8px;
}

.pagination-page-control .tab-arrow-left {
  margin-right: 20px;
  margin-top: 8px;
}

.pagination-page-control .tab-arrow-inactive {
  width: 30px;
  background-color: var(--collaboration2);
}

.pagination-page-control .tab-arrow-inactive:hover {
  cursor: default;
  background-color: var(--collaboration2);
}

.pagination-page-control .tab-arrow-inactive a:hover {
  cursor: default;
  background-color: var(--collaboration2);
  text-decoration: none;
}
