.options-analysis-cost-baseline-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 600px;
}

.options-analysis-cost-baseline-form-container.spinner {
  justify-content: center;
}

.options-analysis-cost-baseline-form-container p {
  font-size: 20px;
  margin: 15px 0;
  padding: 0 15px;
}

.options-analysis-cost-baseline-form-container .project-cost-plan-selectors {
  display: flex;
  justify-content: flex-end;
  margin: 15px auto;
}

.options-analysis-cost-baseline-total-cost {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 15px 0;
  background-color: var(--collaboration2);
  color: white;
}

.options-analysis-cost-baseline-total-cost > .label {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 15px;
}

.options-analysis-cost-baseline-total-cost > .value {
  margin-left: auto;
  padding-right: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-weight: bold;
}

.options-analysis-cost-baseline-form-container .general-button-container {
  margin-top: 45px;
}
