.value-engineering-upload-control-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 390px;
    margin-bottom: 1px;
  }

  .value-engineering-upload-control-label {
    width: 170px;
    margin-right: 20px;
    font-size: 14px;
  }

  .value-engineering-upload-control-input {
    width: 200px;
    height: 25px;
  }

  select.value-engineering-upload-control-input option {
    text-align: center;
  }