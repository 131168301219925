.post-contract-selector-options {
  margin-left: 30px;
}

.pcso-max-container {
  display: flex;
  flex-direction: row;

  margin-top: 5px;
  margin-bottom: 5px;
}

.pcso-max-input {
  width: 50px;
  height: 25px;
  margin-right: 10px;
}
