.benchmarking-save-button {
  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--natural1);
  color: white;
  width: 150px;
  height: 30px;
  border: none;
  border-radius: 0px;
}

.benchmark-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.benchmarking-save-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
  text-decoration: none;
  color: white;
}

.benchmarking-refresh-button {
  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration1);
  color: white;
  width: 150px;
  height: 30px;
  border: none;
  border-radius: 0px;
}

.benchmarking-refresh-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
  text-decoration: none;
  color: white;
}

.modalbenchmark-table-container .rt-td {
  font-size: 12px;
}

.modalbenchmark-table-container .rt-resizable-header-content {
  font-size: 12px;
}
