/* LAYOUT */

.portfolio-filter-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.portfolio-filter-input-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  margin-bottom: 15px;
}

.portfolio-filter-input-col {
  font-size: 11px;
}

.portfolio-filter-input-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 130px;
  margin-right: 10px;
}

/* ICONS */

.portfolio-filter-input-icon {
  color: var(--collaboration2);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  width: 40px;
  margin-left: 10px;
}

.portfolio-filter-input-icon-focus {
  color: var(--heritage);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  width: 40px;
  margin-left: 10px;
}

/* INPUTS */

.portfolio-filter-input,
.portfolio-filter-input-pair,
.portfolio-filter-input-pair-second {
  color: var(--collaboration1);
  height: 20px;
  border: 1px var(--collaboration1) solid;
}

.portfolio-filter-input:focus,
.portfolio-filter-input-pair:focus,
.portfolio-filter-input-pair-second:focus {
  box-shadow: none;
  outline: none;
  border: 1px var(--heritage) solid;
}

.portfolio-filter-input-pair,
.portfolio-filter-input-pair-second {
  width: 60px;
}

.portfolio-filter-input {
  width: 130px;
}

/* FILTER DRAWER */

/* The portfolio-filter-drawer container */
.portfolio-filter-drawer {
  position: relative;
  display: inline-block;
  font-size: 13px;
}

.portfolio-filter-drawer .display-4 {
  font-size: 13px;
  margin: 0px;
}

/* portfolio-filter-drawer content (hidden by default) */
.portfolio-filter-drawer-content-disabled,
.portfolio-filter-drawer-content {
  position: absolute;
  width: 215px;
  height: 525px;

  background-color: rgba(248, 249, 250, 1);

  padding: 10px;

  display: flex;
  flex-direction: column;

  animation: fadein 0.5s;

  border-radius: 25px;
}

.portfolio-filter-drawer-content-disabled {
  margin-top: -700px;
}

.portfolio-filter-drawer-content {
  margin-top: -540px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 12px 40px 0 rgba(0, 0, 0, 0.19);
}

/* Links inside the portfolio-filter-drawer */
.portfolio-filter-drawer-content a {
  float: none;
  color: var(--collaboration1);
  text-decoration: none;
  display: block;
  text-align: left;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* BUTTON */

.portfolio-filter-drawer-button,
.portfolio-filter-drawer-button-active,
.portfolio-filter-drawer-button-clicked,
.portfolio-filter-button-apply,
.portfolio-filter-button-apply-active {
  color: white;
  font-size: 13px;
  border: none;
  outline: none;

  height: 35px;
  width: 150px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border-radius: 30px;

  user-select: none;

  margin-bottom: 15px;
}

.portfolio-filter-button-apply {
  background-color: var(--collaboration1);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.portfolio-filter-drawer-button {
  background-color: var(--collaboration1);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.portfolio-filter-drawer-button-active {
  background-color: var(--heritage);
  box-shadow: 0 4px 8px 0 var(--heritage), 0 6px 20px 0 var(--heritage);
}

.portfolio-filter-button-apply-active {
  background-color: var(--natural1);
  box-shadow: 0 4px 8px 0 var(--natural1), 0 6px 20px 0 var(--natural1);
}

.portfolio-filter-drawer-button-clicked {
  background-color: var(--collaboration3);
  border: none;
  outline: none;
}

/* Add a red background color to navbar links on hover */
.portfolio-filter-drawer-button:hover,
.portfolio-filter-button-apply:hover,
.portfolio-filter-button-apply-active:hover {
  background-color: var(--heritage);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
}

.portfolio-filter-drawer-button-clicked:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.portfolio-filter-drawer-button-active:hover {
  background-color: var(--heritage);
  cursor: pointer;
}
