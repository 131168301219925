.estimate-cashflow {
  margin-top: 30px;
}

.cashflow-content {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
}

.cashflow-column {
  display: flex;
  flex-direction: column;
  margin-right: 30px;
}

.cashflow-container {
  width: 700px;
  height: 500px;
  padding: 10px;
  padding-bottom: 50px;
  background-color: var(--integrity);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.cashflow-button {
  text-align: center;
  width: 70px;
  height: 30px;
  background-color: var(--collaboration1);
  color: var(--integrity);
}

.cashflow-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.cashflow-program-input {
  width: 50px;
  height: 30px;
  text-align: center;
}

.cashflow-date-input {
  width: 100px;
  height: 30px;
  margin-right: 30px;
}

.cashflow-control-head-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.cashflow-control-head-row .display-4 {
  margin-bottom: 0px;
}

.cashflow-control-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  height: 30px;
}

.cashflow-program-label {
  margin-left: 10px;
  color: var(--collaboration1);
}

.cashflow-label {
  width: 170px;
  color: var(--collaboration1);
}

.cashflow-control-panel-client {
  pointer-events: none;
  width: 700px;
  padding: 10px;
  background-color: var(--integrity);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  user-select: none;
}

.cashflow-control-panel {
  width: 700px;
  padding: 10px;
  background-color: var(--integrity);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.cashflow-visualisations {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

/* Slider */
.cashflow-slider {
  -webkit-appearance: none;
  width: 410px;
  height: 13px;
  background: var(--collaboration1);
  outline: none;
  opacity: 1;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border-radius: 100px;
}

.cashflow-slider:hover {
  opacity: 1;
}

.cashflow-standard-deviation-label {
  margin-right: 20px;
  width: 50px;
  color: var(--heritage);
  font-size: 20px;
}

.cashflow-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: var(--heritage);
  cursor: pointer;
  border-radius: 100px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.cashflow-slider::-moz-range-thumb {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  width: 25px;
  height: 25px;
  background: var(--heritage);
  cursor: pointer;
  border-radius: 100px;
}

.cashflow-save-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: inline-block;
  background-color: var(--collaboration1);
  color: white;
  height: 25px;
  width: 25px;
  border: none;
  text-align: center;
  flex-shrink: 0;
  border-radius: 100px;
  font-size: 12px;
}

.cashflow-save-button:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.cashflow-save-button-container {
  height: 25px;
  width: 25px;
  border-radius: 100px;
}

.cashflow-save-button-container .tooltiptext {
  visibility: hidden;
  background-color: var(--heritage);
  color: #fff;
  text-align: center;
  padding: 0px 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  /* Position the tooltip */
  border-radius: 100px;
  margin-left: 15px;
  position: absolute;
  z-index: 0;
}

.cashflow-save-button-container .tooltiptext:hover {
  visibility: hidden;
}

.cashflow-save-button-container:hover .tooltiptext {
  visibility: visible;
}

.cashflow-feedback-error {
  color: var(--strength1);
}

.cashflow-feedback-success {
  color: var(--natural1);
}

.cashflow-feedback-error-container {
  padding-top: 5px;
  font-size: 13px;
}
