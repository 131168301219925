:root {
  --cbs-element-row-height: 30px;
  --cbs-element-button-size: 30px;
}

.cbs-admin {
  width: 1400px;
  display: flex;
  flex-direction: column;
}

/* CBS HEADING */

.cbs-intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cbs-intro-heading {
  font-size: 30px;
}

.cbs-panel {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  background-color: rgba(248, 249, 250, 1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin-bottom: 20px;
  padding: 10px;
}

.cbs-intro-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.cbs-intro-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 500px;
  height: 40px;
}

/* CBS PUBLISH */

.cbs-publish-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 500px;
  height: 70px;
}

.cbs-publish {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 70px;
}

.cbs-publish-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 25px;
  font-size: 12px;
}

/* CBS Content */
.cbs-content-subheading {
  font-size: 25px;
  padding: 10px;
}

.cbs-content-text {
  font-size: 13px;
  padding: 10px;
}
.cbs-content-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

.cbs-content-container {
  background-color: rgba(248, 249, 250, 1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 690px;
}

.cbs-content-element-headings {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.cbs-content-element-code {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100px;
}
.cbs-content-element-description {
  width: 400px;
}
.cbs-content-element-unit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 50px;
}

.cbs-row {
  display: flex;
  flex-direction: row;
}

.cbs-typecode {
  font-size: 30px;
  font-weight: 500;
}

.cbs-control {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 700px;
  margin: 10px;
}

.cbs-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--natural1);
  border-radius: 20px;

  height: var(--cbs-element-button-size);
  width: var(--cbs-element-button-size);

  cursor: pointer;
  color: var(--integrity);
}

.cbs-button:hover {
  background-color: var(--heritage);

  color: var(--integrity);
}

.cbs-input-field {
  width: 200px;
}

.cbs-modal {
  width: 600px;
  padding: 20px;
  font-size: 15px;
}
