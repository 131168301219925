.progress-claim-details {
  display: flex;
  flex-flow: wrap;
  justify-content: flex-start;
}

.progress-claim-details-item {
  width: 135px;
  margin: 15px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.progress-claim-details-box1 {
  text-align: center;
  background: linear-gradient(
    135deg,
    rgb(85, 87, 90) 0%,
    rgb(145, 143, 146) 100%
  );
}
.progress-claim-details-box2 {
  background: linear-gradient(
    135deg,
    rgb(228, 97, 15) 0%,
    rgb(228, 144, 92) 100%
  );
}
.progress-claim-details-box3 {
  background: linear-gradient(
    135deg,
    rgb(50, 98, 149) 0%,
    rgb(101, 137, 176) 100%
  );
}

.progress-claim-details-box1,
.progress-claim-details-box2,
.progress-claim-details-box3 {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 135px;
  height: 60px;

  font-size: 20px;
  color: var(--integrity);

  margin-bottom: 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 100px;
}

.progress-claim-details-label {
  font-size: 13px;
}
