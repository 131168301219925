.language-select-guest .dropdown .dropbtn {
  color: white;
  margin-left: -5px;
}

.language-select-guest .dropdown .dropdown-content {
  margin-left: -12px;
}

.language-select-guest-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.language-select-guest-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}

.language-select-guest-button:hover {
  color: var(--heritage);
  cursor: pointer;
}

.dropdown:hover .fas {
  color: var(--heritage);
}

.language-select-guest-heading {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  color: var(--integrity);
  background-color: var(--collaboration1);
  height: 20px;
  padding-left: 15px;
  line-height: 50px;
}

.language-select-guest-heading:hover {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Opera and Firefox */
}
