.impact-rating-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 20px;
  border-radius: 4px;
  padding: 0 10px;
}

.impact-rating-cell.impact-rating-0 {
  border: 1px solid var(--collaboration3);
  background-color: white;
  color: var(--collaboration1);
}

.impact-rating-cell.impact-rating-1 {
  background-color: var(--growth1);
  color: var(--confident);
}

.impact-rating-cell.impact-rating-2 {
  background-color: var(--growth2);
  color: var(--confident);
}

.impact-rating-cell.impact-rating-3 {
  background-color: var(--built2);
  color: var(--confident);
}

.impact-rating-cell.impact-rating-4 {
  background-color: var(--built1);
  color: var(--confident);
}

.impact-rating-cell.impact-rating-5 {
  background-color: var(--heritage2);
  color: white;
}

.impact-rating-cell.impact-rating-6 {
  background-color: var(--heritage);
  color: white;
}

.impact-rating-cell.impact-rating-7 {
  background-color: var(--strength1);
  color: white;
}
