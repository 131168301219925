.alc-selector {
  display: flex;
  flex-direction: row;
}

.alc-select-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 300px;
}

.alc-select-label {
  margin-bottom: 5px;
}
.alc-key-export {
  margin-top: 45px;
}
.alc-key-export > a:visited,
.alc-key-export > a:hover,
.alc-key-export > a:active {
  color: white;
  text-decoration: none;
}
