.pcf-element {
  display: flex;
  flex-direction: row;
  align-items: left;
}

.pcf-element-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 200px;
  height: 30px;
}
.pcf-validation-message {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 200px;
  height: 20px;
  font-size: small;
  color: red;
}

.pcf-container {
  min-width: 250px;
  margin: 10px;
}

.pcf-container .checkbox-label {
  width: 400px;
}
.pcf-date-item {
  align-items: center;
  width: 200px;
  height: 30px;
}
input[type=date]:invalid::-webkit-datetime-edit {
  color: #999;
}

input[type="date"]::-webkit-calendar-picker-indicator:hover {
  opacity: 1
}

.pcf-element-item input[type="button"] {
  width: 200px;
  border: gray;
}

