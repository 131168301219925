.admin-navigation-tab-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
  background-color: rgba(248, 249, 250, 1);
}

/* Widths */
.admin-navigation-section,
.admin-navigation-section-selected,
.admin-navigation-section-inactive {
  width: 190px;
  padding: 5px;
  height: 34px;
}

/* Project Navigation Sections */

.admin-navigation-section {
  color: var(--confident);
}

.admin-navigation-section-selected,
.admin-navigation-section-selected .icon-container {
  color: var(--heritage);
}

.admin-navigation-section-inactive {
  color: var(--confident);
  position: relative;
  display: inline-block;
}

.admin-navigation-section-inactive:hover,
.admin-navigation-section-inactive:hover .icon-container {
  color: var(--heritage);
}

.admin-navigation-section:hover,
.admin-navigation-section-selected:hover,
.admin-navigation-section:hover .icon-container {
  color: var(--heritage);
}

/* Tooltip Text */

.tab-link .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: var(--heritage);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  /* Position the tooltip */
  border-radius: 100px;
  margin-left: 10px;
  position: absolute;
  z-index: 1;
}

.tab-link:hover .tooltiptext {
  visibility: visible;
}

.tab-link {
  color: white;
  height: 34px;
}

.tab-link:hover {
  text-decoration: none;
  color: white;
}

.admin-navigation-label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.admin-navigation-label .front-icon {
  display: flex;
  flex-direction: row;
}

.icon-container {
  width: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  color: var(--collaboration2);
}
