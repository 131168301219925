.portfolio-filter-warning-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.portfolio-filter-warning-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: max-content;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: rgba(248, 249, 250, 1);
  color: var(--heritage);

  padding-left: 20px;
  padding-right: 20px;

  border-radius: 20px;
  user-select: none;
}

.portfolio-filter-warning-container:hover {
  cursor: pointer;
  background-color: var(--heritage);
  color: var(--integrity);
}

.portfolio-filter-warning {
  margin-right: 10px;
}
