/* AUTHENTICATED */

/* Nav Bar */

.navigation-bar {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: rgba(248, 249, 250, 1);

  min-width: 1300px;
  max-width: 1805px;
  border-radius: 0px 0px 30px 0px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: 20px;
  height: 55px;

  top: 0; /* required */
  left: 0;
  right: 0;

  z-index: 5000; /* Places the nav bar above the landing page */
}

.navigation-left-side {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.navigation-right-side {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* Brand */

.navigation-brand-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 215px;
  z-index: 1;
}

/* Links */

.navigation-links-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
}

.navigation-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: black;
  margin-left: 10px;
  margin-right: 10px;
}

.navigation-link-icon {
  margin-right: 6px;
  font-size: 16px;
  color: var(--collaboration2);
}

.navigation-link:hover {
  text-decoration: none;
  color: var(--heritage);
}

.navigation-link:hover .navigation-link-icon {
  color: var(--heritage);
}

/* Account */

.navigation-account-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
}

.navigation-welcome-label {
  margin-right: 30px;
}

.navigation-avatar {
  width: 30px;
  height: 30px;

  border-radius: 100px;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.navigation-avatar:hover {
  cursor: pointer;
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}

.navigation-avatar-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.navigation-avatar-button:focus {
  outline: inherit;
}

/* Drop Down */

.navigation-menu-link {
  color: black;
  margin-left: 15px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.navigation-menu-link:hover {
  text-decoration: none;
  color: var(--heritage);
}

.navigation-menu-link a:hover {
  text-decoration: none;
  color: var(--heritage);
}

/* The dropdown container */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown content (hidden by default) */
.dropdown-content {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: none;
  position: absolute;
  width: 150px;

  background-color: rgba(248, 249, 250, 1);
  margin-left: 10px;
  margin-right: 15px;
  margin-bottom: 25px;
  margin-top: 1px;
  border-radius: 0 0 20px 20px;

  z-index: 10000;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

.dropbtn-caret {
  margin-left: 5px;
}

/* Dropdown button */
.dropdown .dropbtn {
  display: flex;
  flex-direction: row;
  align-items: center;

  font-size: 16px;
  border: none;
  outline: none;
  padding: 15px;
  background-color: inherit;
  font-family: inherit;
  color: var(--collaboration1);

  margin: 0 auto;
  margin-left: 15px;
  margin-right: 15px;
}

/* Add a red background color to navbar links on hover */
.dropdown:hover .dropbtn {
  cursor: pointer;
  color: var(--heritage);
}

/* Links inside the dropdown */
.dropdown-content a {
  float: none;
  color: var(--collaboration1);
  text-decoration: none;
  display: block;
  text-align: left;
}

.account-role {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  color: var(--integrity);
  background-color: var(--collaboration1);
  height: 50px;
  padding-left: 15px;
  line-height: 50px;
}

.account-role:hover {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Opera and Firefox */
}

/* GUEST */

.navigation-bar-guest {
  background-color: var(--collaboration1);
  position: fixed;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  min-width: 1000px;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  height: 55px;

  top: 0; /* required */
  left: 0;
  right: 0;

  z-index: 5000; /* Places the nav bar above the landing page */
}

.navigation-account-wrapper-guest {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 30px;
}

.navigation-link-guest {
  color: white;
  margin-right: 10px;
  margin-left: 10px;
}

.navigation-link-guest:hover {
  text-decoration: none;
  color: var(--heritage);
}
.navigation-user-log {
  color: var(--collaboration2);
  margin: 8px;
}

.navigation-link-badge-red {
  background-color: #fa3e3e;
  color: white;
  position: absolute;
  height: 6px;
  width: 6px;
  border-radius: 50%;
}

.navigation-link-badge-article-red {
  background-color: #fa3e3e;
  color: white;
  position: absolute;
  height: 15px;
  width: 15px;
  border-radius: 50%;
}

.navigation-link-badge-bigred {
  background-color: #fa3e3e;
  color: white;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  position: absolute;
  font-weight: bolder;
  margin-top: -305px;
  text-align-last: center;
}

.navigation-link-badge-article {
  background-color: var(--heritage);
  color: white;
  position: absolute;
  height: 40px;
  width: 15px;
  border-radius: 5%;
  text-orientation: upright;
  writing-mode: vertical-lr;
  font-size: xx-small;
  font-weight: bolder;
}

.navigation-link-badge-category {
  background-color: var(--heritage);
  color: white;
  position: absolute;
  height: 40px;
  width: 15px;
  border-radius: 5%;
  text-orientation: upright;
  writing-mode: vertical-lr;
  font-size: xx-small;
  font-weight: bolder;
}
